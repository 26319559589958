.description {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 2rem;
}

.costInfo {
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1rem;
    color: rgba(42, 42, 42, .56);
    line-height: 24px;
}

.costValue {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2b303a;
    font-size: 1.25rem;
    display: inline;
}

.off {
    position: relative;
}

.off::after {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: .1rem;
    background-color: red;
    transform: rotate(-20deg);
}

.featureBox {
    font-family: Poppins-Regular;
    margin-top: 1rem;
}

.featureBoxTitle {
    font-size: 13px;
    font-family: Poppins-Medium;
}

/*ENTERPRISE*/

.tabs {
    margin-top: 1rem;
    height: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: .05rem;
}

.tab {
    padding: .3rem .5rem !important;
    flex: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #d3d3d3 !important;
    font-family: Poppins-Medium !important;
    transition: .3s all ease-in-out !important;
    cursor: pointer;
    line-height: unset !important;
}

.tab:hover, .activeTab {
    background-color: #0C4CFC;
    color: white !important;
    border-color: #0C4CFC;
}

.tab:first-child {
    border-radius: .5rem 0 0 .5rem;
}

.tab:nth-child(2) {
    border-radius: 0 .5rem .5rem 0;
}

