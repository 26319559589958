@import "variables"


@mixin fullFlex()
  display: flex
  align-items: center
  justify-content: center

@mixin font($fontSize: $fontSize ,$fontFamily: $fontFamily ,$fontWeight: $fontWeight ,$fontLineHeight: $fontLineHeight)
  font-size: $fontSize
  font-family: $fontFamily
  font-weight: $fontWeight
  line-height: $fontLineHeight

@mixin fontTitle()
  font-family: Poppins-Bold
  font-style: normal
  font-weight: 700
  font-size: 1.5rem
  line-height: 1.25rem
  color: $gunmetal

@mixin fontNormal()
  font-size: 1rem
  font-family: Poppins-Medium
  font-weight: 400
  line-height: 1.5rem
  color: $gunmetal

@mixin fontRegular()
  font-size: 0.75rem
  font-family: Poppins-Regular
  font-weight: 400
  line-height: 1.125rem
  color: $gunmetal

@mixin textOverFlow()
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  padding-left: 15px

// responsive
@mixin sm()
  @media only screen and (max-width: $breakpoint-sm)
    @content

@mixin md()
  @media only screen and (max-width: $breakpoint-md)
    @content

@mixin lg()
  @media only screen and (max-width: $breakpoint-lg)
    @content

@mixin xl()
  @media only screen and (max-width: $breakpoint-xl)
    @content


//custom size for responsive
@mixin customSize($size)
  @media only screen and (max-width: $size)
    @content

