.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.description {
    margin-bottom: 3rem;
}

.proPlanBox, .enterprisePlanBox {
    border: 1px solid lightblue;
    padding: 1rem;
    border-radius: .3rem;
}

.price {
    position: relative;
    font-size: 17px;
}

/*.off::before {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 8%;*/
/*    width: 25px;*/
/*    height: 2px;*/
/*    background-color: red;*/
/*    content: "";*/
/*    transform: rotate(-30deg);*/
/*}*/
.off {
    text-decoration: line-through;
    text-decoration-color: red;
}

.offPrice {
    font-size: 19px;
}

/*.off::after {*/
/*    position: absolute;*/
/*    top: -90%;*/
/*    left: -10%;*/
/*    content: "13.99";*/
/*}*/

.durationOptions {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.durationOption {
    width: 85px;
    background-color: #c5dcfa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .4rem;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
    cursor: pointer;
    height: 40px;
}

.durationOption:hover, .selectedOption {
    background-color: #0C4CFC;
    color: white;
}

.yearlyPlan {
    position: relative;
}

.yearlyPlan:after {
    position: absolute;
    top: -12%;
    right: 0;
    content: "30% Off";
    font-size: 12px;
    background-color: red;
    color: white;
    padding: 0 .3rem;
    border-radius: 0 .3rem 0 0;
}

.buyBtn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: .3rem !important;
    background-color: #0C4CFC !important;
    border: 1px solid #0C4CFC !important;
    border-radius: .3rem !important;
    color: white !important;
    transition: .3s all ease-in-out !important;
    cursor: pointer !important;
    margin: 1.5rem auto .5rem !important;
    width: 100% !important;
}

.buyBtn a {
    color: white !important;
    width: 100%;
}

.buyBtn:not(:disabled):hover a, .buyBtn:not(:disabled) a:hover {
    color: #0C4CFC !important;
}

.buyBtn:not(:disabled):hover {
    background-color: white !important;
    color: #0C4CFC !important;
}

.buyBtn:disabled {
    opacity: .8 !important;
    cursor: not-allowed !important;
}