.title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.list li:not(:last-child) {
    margin-bottom: 0.5rem;
}

.list li a {
    color: #666666;
    font-size: 16px;
    transition: 0.3s all;
}

.list li a:hover {
    color: #464545;
}

.tutorialLink {
    color: #0C4CFC;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: .3rem;
}

@media (max-width: 768px) {
    .title {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 16px !important;
    }

    .list li a, .tutorialLink {
        font-size: 14px !important;
    }
}
