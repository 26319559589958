.container,
.container div {
    width: 100%;
}

.container :global(.ant-skeleton-element) {
    width: 100% !important;
}

.container div span {
    width: 100% !important;
    min-height: 440px;
    border-radius: 8px;
}