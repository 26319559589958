.container {
    margin: .8rem;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    background-color: white;
    border: 1px solid rgba(211, 211, 211, 0.4);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.dataList {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0;
    gap: .5rem;
}

.infoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: .2rem;
    justify-content: center;
    font-family: 'Poppins-Medium';
    padding: .2rem .4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.85);
}

.aiMode {
    color: grey;
    margin-left: .2rem;
    font-size: 13px;
}

.exchangeIcon {
    width: 25px;
    height: 25px;
    border-radius: 100%;
}

.description {
    display: flex;
    align-items: center;
    gap: .2rem;
    flex-wrap: wrap;
    justify-content: center;
}


@media (max-width: 480px) {
    .container {
        margin: 0;
        padding: 1rem;
        grid-template-columns: 1fr;
    }
}