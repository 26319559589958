.container {
    margin: 1.5rem 0;
}

.title {
    text-align: center;
}

.invitationLink {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    width: fit-content;
    cursor: pointer;
    transition: 0.3s all;
    word-break: break-all;
    margin: 0 auto;
}

.invitationLink:hover {
    color: #0c4cfc;
}

.invitationText {
    margin-bottom: 0;
    text-align: center;
}

.btns {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
}

@media (max-width: 584px) {
    .invitationLink svg {
        display: none;
    }
}

/* @media (max-width: 480px) {
  .container,
  .invitationLink {
    width: 90% !important;
    max-width: 90% !important;
  }
  .invitationLink {
    text-align: center;
    margin: 0 auto 1rem;
  }
} */
