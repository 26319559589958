.container {
    display: flex;
    flex-direction: column;
}

.actionBtn {
    align-self: flex-end;
    margin-left: auto;
    background-color: #0c3fce !important;
    color: white !important;
    border-color: #0c3fce !important;
    border-radius: .3rem !important;
}

.actionBtn:hover, .actionBtn:focus, .actionBtn:active {
    background-color: white !important;
    color: #0c3fce !important;
}

.actionBtn:disabled:hover {
    background-color: #0c3fce !important;
    color: white !important;
}

.actionBtn:disabled {
    opacity: .8 !important;
}