.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question {
  display: flex;
  align-items: baseline;
  gap: 0.2rem;
  margin-bottom: 0;
}

.options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.option {
  width: 90px;
  background-color: #c5dcfa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.3rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  flex-direction: column;
  height: 70px;
  position: relative;
  font-weight: 500;
}

.off {
  position: absolute;
  top: -5%;
  right: 0;
  background-color: #fe0000;
  color: white;
  font-size: 12px;
  padding: 0.1rem 0.3rem;
  border-radius: 0 0.3rem 0 0;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

/*.option:not(:first-child)::after {*/
/*    position: absolute;*/
/*    top: -5%;*/
/*    right: 0;*/
/*    background-color: #FE0000;*/
/*    color: white;*/
/*    font-size: 12px;*/
/*    padding: .1rem .3rem;*/
/*    border-radius: 0 .3rem 0 0;*/
/*    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;*/
/*    content: "30%";*/
/*}*/

/*.option:last-child::after {*/
/*    content: unset;*/
/*}*/

/*.optionCost, .optionTitle {*/
/*    font-weight: 500;*/
/*}*/

.option:hover,
.selectedOption {
  background-color: #0c4cfc;
  color: white;
}

.customDurationBox {
  display: flex;
  flex-direction: column;
}

.customDurationBox h3 {
  align-self: center;
}

.submitBtnContainer {
  display: flex;
  justify-content: center;
  margin: 1.5rem auto 0;
}

.submitBtn {
  background-color: #0c4cfc !important;
  color: white !important;
  border-color: #0c4cfc !important;
  align-self: center;
  width: 120px;
}

.submitBtn:disabled {
  opacity: 0.8 !important;
}

.submitBtn:not(:disabled):hover,
.submitBtn:not(:disabled):focus,
.submitBtn:not(:disabled):active {
  background-color: white !important;
  color: #0c4cfc !important;
}
