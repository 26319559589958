.message {
    text-align: center;
}

.btns {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.btn {
    width: 150px !important;
    display: flex !important;
    align-items: center !important;
}

.checkBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
}