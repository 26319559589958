.infoBox {
    display: flex;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
}

/*.infoBox > span:nth-child(2) {*/
/*    margin-left: .5rem;*/
/*}*/

.infoBoxTitle {
    width: 70px;
}

.active {
    color: rgb(82, 196, 26);
}

.addBtn {
    background-color: #0C4CFC;
    padding: .1rem .6rem;
    font-size: 11px;
    border-radius: .7rem;
    color: white;
    cursor: pointer;
    border: 1px solid #0C4CFC;
    transition: .3s all ease-in-out;
    /*margin-left: .5rem;*/
}

.addBtn:hover {
    background-color: white;
    color: #0C4CFC;
}

