.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contentOptions {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 15px;
}

.contentOptions .btn {
    padding: .3rem !important;
    width: 110px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: .3rem !important;
    /*border: 2px solid lightgray !important;*/
    border-width: 2px !important;
    border-radius: .3rem !important;
    font-weight: 500 !important;
    /*color: #2B303A !important;*/
    transition: .3s all !important;
    cursor: pointer !important;
}

.btn span {
    display: flex !important;
    align-items: center;
    gap: .3rem;
}

.btn:hover {
    border-color: #0C4CFC !important;
}

.deleteBtn {
    border-radius: .3rem !important;
    background-color: #e74c3c !important;
    border-color: #e74c3c !important;
    color: white !important;
}

.deleteBtn:hover {
    background-color: #c0392b !important;
    border-color: #c0392b !important;
}

.deactivate {
    opacity: .8;
    cursor: not-allowed !important;
}

.activateBtn {
    background-color: #3498db !important;
    border-color: #3498db !important;
    border-radius: .3rem !important;
    color: white !important;
}

.activateBtn:hover {
    background-color: #2980b9 !important;
    border-color: #2980b9 !important;
}

.deactivateBtn {
    background-color: #bdc3c7 !important;
    border-radius: .3rem !important;
    border-color: #bdc3c7 !important;
    overflow: hidden !important;
}

.deactivateBtn:hover {
    background-color: #9da2a5 !important;
    border-color: #9da2a5 !important;
}

.detailsBtn {
    background-color: #1abc9c !important;
    border-color: #1abc9c !important;
    color: white !important;
}

.detailsBtn:hover {
    background-color: #16a085 !important;
    border-color: #16a085 !important;
}

.createBtn {
    background-color: #FFA500 !important;
    border-color: #FFA500 !important;
    color: white !important;
}

.createBtn:hover {
    background-color: #ec9a02 !important;
    border-color: #ec9a02 !important;
}

.active {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

@media (max-width: 530px) {
    .contentOptions {
        flex-wrap: wrap
    }
}