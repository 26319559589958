.container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    /*margin: 0;*/
}

.container button {
    border-radius: .3rem !important;
}

.refreshBtn {
    padding: 0.3rem 0.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: .2rem !important;
    border-radius: 0.3rem !important;
    font-weight: 500 !important;
    transition: .3s all !important;
    cursor: pointer !important;
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.refreshBtn:hover {
    background-color: white !important;
    color: #0C4CFC !important;
}

.sellFiatBtn {
    background-color: #FE0000 !important;
    color: white !important;
    border-color: #FE0000 !important;
    border-radius: .3rem !important;
}

.sellFiatBtn:hover {
    background-color: transparent !important;
    color: #FE0000 !important;
}

.sellFiatBtn:active {
    color: #FE0000 !important;
}

.sellCryptoBtn {
    background-color: #0D9276 !important;
    border-color: #0D9276 !important;
    color: white !important;
}

.sellCryptoBtn:hover, .sellCryptoBtn:active {
    background-color: white !important;
    color: #0D9276 !important;
}

.buySellBtn {
    background-color: #3c9cd6 !important;
    color: white !important;
    border: 1px solid #3c9cd6 !important;
}

.buySellBtn:hover, .buySellBtn:active {
    background-color: transparent !important;
    color: #3c9cd6 !important;
}

.moreBtn {
    background-color: #1abc9c !important;
    border-color: #1abc9c !important;
    color: white !important;
    gap: 0 !important;
}

.moreBtn:hover, .moreBtn:active {
    background-color: white !important;
    /*border-color: #16a085 !important;*/
    color: #1abc9c !important;
    gap: 0 !important;
}

.linkBtn > span:first-child {
    background-color: transparent !important;
    padding: 0 !important;
}

.linkBtn > span:first-child svg {
    display: none !important;
}

.actionMenu li span {
    font-size: 14px !important;
}

@media (min-width: 768px) {
    .moreBtn {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .desktopView {
        display: none !important;
    }

    .desktopBtn {
        display: none !important;
    }

    .container > div:first-child {
        display: none !important;
    }
}