.container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.referredList {
    flex-basis: 70%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
}

.addMoreBtn {
    width: fit-content;
    /*justify-self: center;*/
}

.summaryBox {
    border: 1px solid #1b4aef;
    padding: 1rem;
    border-radius: .3rem;
    /*margin: 0 auto;*/
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info span {
    font-family: Poppins-Medium;
}

.infoTitle {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.infoTitle svg {
    color: #1b4aef;
}

.inviteBtn {
    width: 200px;
}

.linkBtn {
    background-color: white;
    color: #0C4CFC;
    padding: .3rem .5rem;
    border-radius: .8rem;
    cursor: pointer;
    border: 1px solid #0C4CFC;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkBtn:hover {
    background-color: #0C4CFC;
    color: white;
}

@media (min-width: 1100px) {
    .referredList {
        display: flex;
        flex-wrap: wrap;
    }

    .addMoreBtn {
        width: 200px !important;
        align-self: flex-start;
        justify-self: flex-start;
    }

    .summaryBox {
        width: 320px;
    }
}

@media (max-width: 780px) {
    .container {
        flex-direction: column;
    }

    .summaryBox {
        align-self: flex-start;
        margin: 0;
        width: 320px;
    }
}

@media (max-width: 480px) {
    .referredList {
        grid-template-columns: 1fr;
    }

    .summaryBox {
        width: 100% !important;
    }

    .addMoreBtn {
        width: 100% !important;
    }

    .inviteBtn {
        width: 100%;
    }
}