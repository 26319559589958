.container {
    background-color: #c5dcfa;
    width: 100%;
    display: flex;
    border-radius: 0.5rem;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid #1b4aef;
}

.text {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /*gap: .3rem;*/
}

.text h3 {
    margin-bottom: 0;
}

.text p {
    margin-bottom: 0;
    text-align: center;
    font-size: 13px;
}

.homeIcon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C359E;
    border-radius: .3rem;
    color: white;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.homeIcon:hover {
    opacity: .8;
}