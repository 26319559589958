.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    scroll-behavior: smooth;
}

.actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    background-color: #0C4CFC;
    cursor: pointer;
    border-radius: .3rem;
    width: 95%;
    margin: 0 auto;
    font-family: Poppins-Medium;
    color: white;
    transition: .3s all ease-in-out;
    gap: .2rem;
}

.actionButton:hover {
    background-color: #052b94;
}