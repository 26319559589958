.container {
    display: flex;
    align-items: center;
    gap: .3rem;
    padding: .4rem;
    border-radius: 1.5rem;
    border: 2px solid #D2E0FB;
    width: fit-content;
    margin-bottom: 0;
    height: 45px;
    background-color: #F4F6FF;
}