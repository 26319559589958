.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.referralCodeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    /*background-color: #c5dcfa;*/
    padding: .5rem 1rem;
    border-radius: .3rem;
    border: 1px solid #1b4aef;
    width: fit-content;
    margin: 0 auto;
    font-family: Poppins-Medium;
    flex-wrap: wrap;
}

.referralCodeBox h4 {
    margin-bottom: 0;
}

.referralCodeInfo {
    display: flex;
    align-items: center;
    gap: .2rem;
    color: #0C359E;
}

.copyIcon {
    color: #0C4CFC;
    cursor: pointer;
}

.referralGifBox {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-family: Poppins-Medium;
}

.referralGifBox h3 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: .2rem;
}

.referralGifBox span {

}