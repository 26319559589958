.container {
    padding: 1rem 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid #0c359e;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.header p {
    margin-bottom: 0;
    font-size: 12px;
}

.separator {
    width: 80%;
    height: 2px;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 1),
            #0c359e,
            rgba(255, 255, 255, 1)
    );
    margin: 0 auto;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.info span:first-child {
    font-weight: 500;
}

.info p {
    margin-bottom: 0;
}

.sell {
    color: #FF0000;
}

.buy {
    color: #00B050;
}

.copyBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2f65fb;
    width: 90%;
    margin: 0 auto;
    padding: .5rem;
    color: white;
    cursor: pointer;
    font-family: Poppins-Medium;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
}

.copyBtn:hover {
    background-color: #0345fb;
}
