.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.options {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1rem auto 2rem;
}

.option {
    background-color: #c5dcfa;
    width: 100px;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .3rem;
    border-radius: .5rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: Poppins-Medium;
}

.price {
    font-size: 13px;
}

.option:hover, .selected {
    background-color: #0C4CFC;
    color: white;
}

.disabled {
    cursor: not-allowed !important;
    opacity: .8;
    box-shadow: unset !important;
}

.disabled:hover {
    background-color: #c5dcfa !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.buyBtn {
    background-color: #754a7f;
    color: white;
    width: 100%;
    border-radius: .4rem;
    border: 1px solid #754a7f;
    cursor: pointer;
    transition: .3s all ease-in-out;
    font-family: Poppins-Medium;
    padding: .3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buyBtn:hover {
    background-color: white;
    color: #754a7f;
}

.errorBox {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
    align-items: center;
}

.errorBox p {
    text-align: center;
}

@media (max-width: 400px) {
    .option {
        width: 95px;
    }

    .options {
        gap: .5rem;
    }
}

