.container {
    margin: .5rem;
    border: 1px solid #f0f0f0;
    border-radius: 1rem;
    padding: 1rem;
    background-color: white;
}

.dataList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.dataList li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*gap: .5rem;*/
}

.label {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    font-size: 16px;
}

.dataList li span:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    color: #747d8c;
}

.exchangeIcon {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.active {
    color: green !important;
}

.deactivate {
    color: red !important;
}

@media (min-width: 768px) {
    .status {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .label {
        font-size: 13px;
    }

    .dataList li span:nth-child(2) {
        font-size: 12px;
    }
}

@media (min-width: 576px) {
    .startDate {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 0;
    }
}