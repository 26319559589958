.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
    /*min-height: 400px;*/
}

.container img {
    max-width: 400px;
}

.container h3 {
    color: #414144;
    font-family: Poppins-Medium;
    text-align: center;
}

.refreshBtn {
    width: 100px;
    background-color: #0C4CFC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    padding: .2rem .5rem;
    color: white;
    border: 1px solid #0C4CFC;
    transition: .3s ease-in-out;
    cursor: pointer;
    font-family: Poppins-Medium;
}

.refreshBtn:hover {
    background-color: white;
    color: #0C4CFC;
    width: 120px;
}

@media (max-width: 1200px) {
    .container {
        height: 75vh;
    }
}

@media (min-width: 480px) {
    .container img {
        max-width: 500px;
    }
}