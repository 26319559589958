// colorPalette
$gunmetal: #2B303A
$gray: #7D7C84
$dolphin: #686870
$primary: #1B4AEF
$manatee: #878C99
$whiteLilac: #F8F7FC
$whiteSmoke: #F3F5F9
$shuttleGrey: #66666E


// breakpoints
$breakpoint-pwa: 425px
$breakpoint-sm: 576px
$breakpoint-md: 768px
$breakpoint-lg: 992px
$breakpoint-xl: 1800px
$maxWidthEl: 1240px
$maxWidth: 1440px
$maxWidthDashboardContent: 1339px

// fontConfig
$fontFamily: "Poppins-Medium"
$fontSize: 16px
$fontWeight: 400
$fontLineHeight: 21.5px

// colorPalette
$Persimmon: #dfd9d9
$BlueZodiac: #194c8e
$Lipstick: #AA0347
$Selago: #E7EEFB
$SteelGray: #29253C
$Waterloo: #888693
$FrenchGray: #eceaf1
$SantasGray: #999CAD
$Mischka: #e8e6ea
$Emperor: #6d6d6d
$AliceBlue: #F5FAFF
$Whisper: #F7F5F8
$Bunting: #16284C
$LinkWater: #F5F8FC
$Spray: #68DBF2
$green: #27AE60
$white: #ffffff
$PattensBlue: #F7FAFC
$AliceBlue: #F4F7FE
$Mortar: #585858
$Cyprus: #15355A
$CreamBrulee: #FFE096
$Tangerine: #F39200
$silver: #C4C4C4
$Bunting: #16284C
$Gallery: #EAEAEA
$MineShaft: #3A3A3A
$Lightwhite: #E6EAEF
$RegalBlue: #16284C
$Lightwhite: #E6EAEF
$Scorpion: #585858
$DustyGray: #949494
$Heather: #C0C9D9
$Nepal: #98A7BF
$BermudaGray: #7184A5
$Gray: #828282
$MineShaft: #2C2C2C
$Cherrywood: #621620
$ElSalva: #83302F
$Matrix: #A8554F
$CatSkillwhite: #ECEFF5
$Contessa: #754a7f
$PetiteOrchid: #E0958F
$Sundown: #FFBAB1
$TuftBush: #FFDCCD
$Nobel: #B4B4B4
$Malibu: #bfcbd8
$PattensBlue: #DFF3FF
$Alabaster: #F7F7F7
$secback: #fafbfd
$zircon: #F2F6FF
$lightbord: #EDEDED
$Gainsboro: #E8E8E8
$LightGrey: #D0D0D0
$RegalBlue: #16284C
$Mortar: rgba(42, 42, 42, 0.56)
$Pohutukawa: #621620
$SuvaGrey: #949494
$HawkesBlue: #E0E5F2
$WhiteSmoke: #F4F4F4
$GrannyApple: #C2E6C1
$Magnolia: #FCF4FF
$MediumOrchid: #BB6BD9
$PersianGreen: #01B388
$WhiteSmoke2: #F9F9F9
$Solitude: #F7F8FA
$snow: #FFFAFB
$Solitude2: #F3F5F7
$JungleGreen: #27AE60
$Mortar2: #535252
$ForgetMeNot: #FDF3E0
$gradient1: linear-gradient(180deg, #EE4040 0%, #AA0347 51.56%, #15355A 100%)
$gradient2: linear-gradient(225deg, #BB65FF 0%, #4C6FFF 100%)
$gradient3: linear-gradient(225deg, #FFC656 0%, #F16063 100%)
$gradient4: linear-gradient(90deg, #203E62 0%, #14447C 63.89%)
$gradient5: linear-gradient(225deg, #68DBF2 0.01%, #509CF5 100%)
$gradient6: linear-gradient(90deg, #AA0347 0%, #14447C 63.89%)
$gradient7: linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%)
$gradient8: linear-gradient(225deg, #67E9F1 0%, #24E795 100%)
