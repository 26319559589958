.card {
  width: 100%;
  max-width: 400px;
  padding: 16px;
  box-shadow:
    0 5px 5px rgba(0, 0, 0, 0.05),
    0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05),
    -5px 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
