.title {
    /*font-size: 1.2rem;*/
    /*font-family: Poppins-Medium;*/
    /*font-weight: 400;*/
    /*line-height: 1.5rem;*/
    /*color: #2B303A;*/
    /*margin-bottom: 16px;*/
    font-size: 1.2rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    margin-bottom: 16px;
}

.btns {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.buySellBtn {
    background-color: #3c9cd6 !important;
    color: white !important;
    border: 1px solid #3c9cd6 !important;
}

.buySellBtn:hover, .buySellBtn:active {
    background-color: transparent !important;
    color: #3c9cd6 !important;
}

.virtualBtn {
    background-color: #00BFA5 !important;
    border-color: #00BFA5 !important;
    color: white !important;
}

.virtualBtn:hover, .virtualBtn:active {
    background-color: white !important;
    color: #00BFA5 !important;
}

.tabs :global(.ant-tabs-tab) {
    padding: 0.3rem 1rem;
    color: #6570ed;
}

/*@media (max-width: 468px) {*/
/*    .title {*/
/*        font-size: 16px;*/
/*    }*/
/*}*/