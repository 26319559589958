.mainContainer {
  display: grid;
  grid-template-columns: 4fr 1fr;
  position: relative;
  /* padding-bottom: 60px; */
  grid-gap: 30px;
}

.quickAccess {
  position: sticky;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  height: fit-content;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
}

.quickItem:not(:last-child) {
  margin-bottom: 1rem;
}

.quickItem a {
  color: #0c4cfc;
  font-size: 1.05rem;
  display: flex;
  align-items: center;
}

.active {
  color: rgb(10, 121, 211);
}

.FaqItem:not(:last-child) {
  margin-bottom: 3rem;
}

.answer {
  line-height: 2rem;
  padding-left: 1rem;
  width: 95%;
}

.backBtn,
.firstBackBtn {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: rgb(12, 76, 252);
  cursor: pointer;
}
.firstBackBtn {
  display: none;
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .mainContainer {
    grid-template-columns: 3fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .mainContainer {
    grid-template-columns: 2fr 1fr;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
  }
  .backBtn {
    display: none;
  }
  .firstBackBtn {
    display: block;
    margin-bottom: 0;
  }
  .quickAccess {
    position: inherit;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .quickAccess h3 {
    grid-column: span 2;
  }
}

@media (max-width: 480px) {
  .quickAccess {
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .quickAccess h3 {
    grid-column: span 1 !important;
  }
}
