.container {
    border: 1px solid #1b4aef;
    padding: 1rem;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.botInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Poppins-Medium;
    flex-wrap: wrap;
}

.botInfo svg {
    color: #0c3fce;
    font-size: 16px;
}

.botInfoTitle {
    display: flex;
    align-items: center;
    gap: .3rem;
}

.botInfoValue {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: .2rem;
    align-self: flex-end;
    margin-left: auto;
}

.botInfoValue img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.addBotBtn {
    align-self: flex-end;
    font-family: Poppins-Medium;
    display: flex;
    align-items: center;
    gap: .2rem;
    background-color: #0c3fce;
    color: white;
    border-radius: .3rem;
    border: 1px solid #0c3fce;
    cursor: pointer;
    transition: .3s all ease-in-out;
    padding: .3rem 1rem;
    margin-top: .5rem;
    width: 50px;
    overflow: hidden;
    /*height: 34px;*/
}

.addBotBtn:hover {
    background-color: white;
    color: #0c3fce;
}