.exchanges {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
}

.exchange {
    font-family: Poppins-Medium;
    background-color: #c5dcfa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.exchange:hover, .selected {
    background-color: #0c3fce;
    color: white;
}

.createBotHandler {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.5rem;
}

.createBotError {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    gap: .2rem;
}

.createBotBtn {
    background-color: #0c3fce !important;
    color: white !important;
    border-color: #0c3fce !important;
    margin-left: auto;
}

.createBotBtn:hover, .createBotBtn:active, .createBotBtn:focus {
    background-color: white !important;
    color: #0c3fce !important;
}

.createBotBtn:disabled {
    opacity: .8;
}

@media (max-width: 400px) {
    .exchange {
        font-size: 13px;
    }
}