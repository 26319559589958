.button {
    background-color: #0C4CFC;
    height: 35px;
    border-radius: .5rem;
    border: 1px solid #0C4CFC;
    color: white;
    transition: .3s all ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: .5rem;
    font-family: Poppins-Medium;
    margin-top: 1rem;
}

.button a {
    color: white;
    font-family: Poppins-Medium;
    transition: .3s all ease-in-out;
    background-color: transparent;
}

.button:hover, .button:hover a, .button a:hover {
    background-color: white;
    color: #0C4CFC;
}

.changeBtn {
    background-color: #28A745 !important;
    border-color: #28A745 !important;
}

.changeBtn:hover {
    color: #28A745 !important;
    background-color: white !important;
}