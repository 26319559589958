@import "../../variables"
@import "../../mixins"

.tradeAssistantWrapper
  width: 100%
  display: flex

  .main
    //width: 80%
    width: 100%

    .tradingHeader
      width: 100%
      display: flex
      flex-direction: column
      justify-content: space-between

      > div
        &:nth-of-type(1)
          > div
            > h3
              font-size: 1.2rem
              font-family: Poppins-Medium
              font-weight: 400
              line-height: 1.5rem
              color: #2B303A
              margin-bottom: 0

          > p
            > span
              color: #0C4CFC

    .tableBtns
      display: flex
      //justify-content: flex-end
      align-items: center
      gap: 16px
      flex-wrap: wrap
      margin-top: 1rem
      width: 100%

      > h3
        @include fontNormal

      > div
        @include fullFlex
        gap: 12px

        > span
          @include fullFlex
          font-size: 20px
          cursor: pointer

          &:nth-of-type(2)
            color: $green

        > button
          background-color: blue
          color: $white
          height: 36px !important


    .portfolioInfo
      width: 100%
      display: flex
      gap: 24px
      margin-top: 24px

      > div
        width: 100%
        max-width: max-content
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
        background-color: #c5dcfa
        padding: 8px
        border-radius: 8px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

        &:nth-last-child(1)
          background-color: #6570ed

          > span
            &:nth-of-type(1)
              @include fontNormal
              font-size: 1.125rem
              margin-bottom: 8px
              color: white

            &:nth-of-type(2)
              @include fontRegular
              color: white
              text-align: center

        &:nth-last-child(2)
          background-color: #6570ed

          > span
            &:nth-of-type(1)
              @include fontNormal
              font-size: 1.125rem
              margin-bottom: 8px
              color: white

            &:nth-of-type(2)
              @include fontRegular
              color: white
              text-align: center

        > span
          &:nth-of-type(1)
            @include fontNormal
            font-size: 1.125rem
            margin-bottom: 8px

          &:nth-of-type(2)
            @include fontRegular
            color: $Mortar
            text-align: center

    .portfolioCharts
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      margin-top: 36px
      gap: 24px

      > div
        &:nth-of-type(1)
          width: 35%
        //@include fullFlex
        &:nth-of-type(2)
          width: 65%

          > div
            &:nth-of-type(1)
              display: flex
              gap: 16px
              padding-left: 16px
              margin-bottom: 16px
              position: relative
              justify-content: center

              span
                border-radius: 8px
                border: 1px solid silver
                padding: 4px 8px
                @include fontRegular
                cursor: pointer
                transition: all .2s linear

                &:hover
                  background-color: silver

    .portfolioTable
      width: 100%
      margin-top: 24px

  .sideBar
    //display: none
    width: 15%
    position: fixed
    padding-left: 16px
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.05)
    right: 0
    height: calc(100vh - 60px)
    padding-top: 24px
    top: 60px
    padding-right: 16px
    overflow-y: scroll

    .overAllAndCoins
      display: flex
      flex-direction: column
      //align-items: center
      // gap: 48px
      gap: 30px

      > div
        &:nth-of-type(1)
          display: flex
          flex-direction: column
          gap: 12px


        &:nth-of-type(2)
          display: flex
          flex-direction: column
          gap: 16px
          padding-top: 16px
          box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.05)

        > h4
          @include fontNormal
          font-size: 1.025rem

        .radioContainer
          width: 100%
          display: flex
          flex-direction: column
          gap: 36px

          .radioItem
            display: flex
            align-items: flex-start
            gap: 8px

            div
              width: 100%
              display: flex
              flex-direction: column
              gap: 12px

              > div
                display: flex
                flex-direction: row
                gap: 12px
                align-items: center

                > span
                  @include fontNormal

                > img
                  width: 30px
                  height: 30px


              > span
                @include fontNormal
                color: $Mortar
                font-size: 0.875rem

    .marketTypeRadio
      display: flex
      align-items: center
      gap: 8px

      span
        display: flex
        align-items: center
        gap: 16px
        @include fontNormal

        > p
          margin: 0 !important
          font-size: 18px
          color: #0C4CFC

  .resetHistory
    background-color: #0C4CFC !important
    color: white !important
    @include fullFlex
    gap: .3rem !important
    height: 36px !important

    path
      stroke: white

    &:hover
      color: #0C4CFC !important
      border-color: #0C4CFC !important
      background-color: white !important

      path
        stroke: #0C4CFC !important

    &:active
      color: #0C4CFC !important
      border-color: #0C4CFC !important
      background-color: white !important

      path
        stroke: #0C4CFC !important

    &:focus
      color: #0C4CFC !important
      border-color: #0C4CFC !important
      background-color: white !important

      path
        stroke: #0C4CFC !important

    > span
      display: flex
      align-items: center
      gap: 8px

.resetBtn
  background-color: #e84118 !important
  color: white !important
  display: flex !important
  align-items: center !important
  justify-content: center !important
  gap: .3rem !important
  border-color: #e84118 !important

  path
    stroke: white !important

  &:hover
    color: #e84118 !important
    background-color: white !important

    path
      stroke: #e84118 !important

  &:active
    color: #e84118 !important
    background-color: white !important

    path
      stroke: #e84118 !important

  &:focus
    color: #e84118 !important
    background-color: white !important

    path
      stroke: #e84118 !important

//path
//  stroke: white

:global(.ant-tour-description)
  margin-top: 1rem
  padding-bottom: 1rem !important

:global(.ant-tour-footer)
  display: none !important

.warningIcon
  font-size: 25px !important

  circle
    color: goldenrod

  path
    color: goldenrod
    font-size: 25px !important

.activePeriod
  background-color: silver

.loading
  &:after
    background: url("../../../asset/Images/Pulse-1s-200px.svg")
    position: absolute
    background-size: 40px 40px
    width: 40px
    height: 40px
    top: 200px
    left: 50%
    content: ""
    z-index: 1000

    @include fullFlex

  &:before
    position: absolute
    width: 100%
    height: 400px
    top: 0
    left: 0
    background-color: rgba(0, 0, 0, 0)
    content: ""
    z-index: 100

    @include fullFlex

.plusIcon
  font-size: 20px
  color: white
  margin-top: 8px
  cursor: pointer
  transition: all .2s linear

  &:hover
    transform: scale(1.05)

.categoryLink
  background-color: #c5dcfa
  padding: .3rem 1rem
  border-radius: 1rem
  font-size: 15px
  transition: .3s all
  color: rgba(0, 0, 0, .86)
  min-width: 100px

.integrateLink
  font-size: 1rem
  font-family: Poppins-Medium
  font-weight: 400
  line-height: 1.5rem
  color: #2B303A

.coinTypesRadio
  span
    @include fullFlex
    gap: 8px

    > img
      width: 24px
      height: 24px

.badge > sup
  display: flex !important
  align-items: center !important
  justify-content: center !important
  top: -9px !important
  background-color: #011e53 !important
  padding: 0.3rem 0.5rem !important
  height: auto !important
  font-size: 15px !important
  line-height: 13px !important


.buySellBtn
  background-color: #3c9cd6 !important
  color: white !important
  border: 1px solid #3c9cd6 !important

  &:hover
    background-color: transparent !important
    color: #3c9cd6 !important

  &:active
    background-color: transparent !important
    color: #3c9cd6 !important

.desktopMsg
  display: none

.tableButtons
  button
    &:nth-of-type(2)
      height: 32px !important

.actionBtns
  display: flex
  gap: 1rem
  align-items: center
  margin-top: 1rem

  .alertBtn
    background-color: #28a745 !important
    color: white !important
    display: flex !important
    align-items: center !important
    gap: .3rem !important

    &:hover
      background-color: white !important
      border-color: #28a745 !important
      color: #28a745 !important

    &:focus
      background-color: white !important
      border-color: #28a745 !important
      color: #28a745 !important

    &:active
      background-color: white !important
      border-color: #28a745 !important
      color: #28a745 !important

  .createBotBtn
    background-color: #17a2b8 !important
    color: white !important
    display: flex !important
    align-items: center !important
    gap: .3rem !important

    &:hover
      background-color: white !important
      color: #00bcd4 !important
      border-color: #00bcd4 !important

    &:focus
      background-color: white !important
      color: #00bcd4 !important
      border-color: #00bcd4 !important

    &:active
      background-color: white !important
      color: #00bcd4 !important
      border-color: #00bcd4 !important

.btns
  margin-top: 1rem
  display: flex
  align-items: center
  justify-content: space-between
  flex-wrap: wrap
  gap: 1rem

.header
  display: flex
  justify-content: space-between
  align-items: center
  margin: 1rem 0 1.5rem

.integrationBtn
  background-color: #fd7e14 !important
  border-color: #fd7e14 !important
  color: white !important

  &:hover
    background-color: white !important
    color: #fd7e14 !important

    &:active
      background-color: white !important
      color: #fd7e14 !important

.tableOptions
  display: flex
  justify-content: space-between
  align-items: flex-end
//align-self: flex-end

.riskBtn
  background-color: #f44336 !important
  color: white !important
  display: flex !important
  align-items: center !important
  gap: .3rem !important

  &:hover
    color: #f44336 !important
    background-color: white !important
    border-color: #f44336 !important

  &:focus
    color: #f44336 !important
    background-color: white !important
    border-color: #f44336 !important

  &:active
    color: #f44336 !important
    background-color: white !important
    border-color: #f44336 !important

.riskAndCategory
  display: flex
  align-items: center
  gap: .5rem

.tabs
  > div
    &:nth-child(1)
      div
        div
          div
            padding: .3rem 1rem !important
            color: #6570ed !important

.assistantTitle
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 16px

@media (min-width: 1200px) and (max-width: 1700px)
  .tradeAssistantWrapper
    .main
      width: 98%

@media (max-width: 1200px)
  .tradeAssistantWrapper
    flex-direction: column

    .main
      width: 100%

      .portfolioInfo
        display: grid
        grid-template-columns: repeat(3, 1fr)

        > div
          max-width: inherit
  .sideBar
    display: none

.tableModeBtn
  display: flex
  align-items: center
  justify-content: center
  width: 95px
  height: 32px
  border-radius: .3rem
  cursor: pointer
  transition: .3s all ease-in-out
  background-color: rgba(150, 171, 234, 0.25)
  font-size: 15px !important

.positionBtn
  background-color: #90EE90

  &:hover
    background-color: #006400
    color: white

  &.selectedMode
    background-color: #006400
    color: white

.orderBtn
  background-color: #ADD8E6
  color: rgba(0, 0, 0, 0.88) !important

  &:hover
    background-color: #00008B
    color: white !important

  &.selectedMode
    background-color: #00008B
    color: white !important

.tableModeBtns
  margin-right: auto

.moreMenu
  display: none !important

.moreBtn
  background-color: #1abc9c !important
  border-color: #1abc9c !important
  color: white !important

  &:hover
    background-color: white !important
    color: #1abc9c !important

  > span
    display: flex !important
    align-items: center !important


@media (max-width: 800px)
  .tradeAssistantWrapper
    .main
      .portfolioCharts
        flex-direction: column

        > div
          &:nth-of-type(1)
            width: 60%

          &:nth-of-type(2)
            width: 100%

@media (min-width: 768px)
  .mobileMsg
    display: none
  .desktopMsg
    display: block

@media (max-width: 768px)
  .actionBtns
    justify-content: center !important
  .desktopVersion
    display: none !important
  .riskAndCategory
    display: none

@media (max-width: 600px)
  .moreMenu
    display: flex !important
    margin-left: auto
    padding: 4px 10px !important

  .resetBtn
    display: none !important

  .exportMenu
    display: none !important

  .btns
    gap: .5rem

  .tableBtns
    gap: .5rem !important
    justify-content: space-between

  .tableModeBtns
    margin-right: 0
  .tableModeBtn
    width: 80px
    font-size: 14px !important

  .tradeAssistantWrapper
    .main
      .portfolioInfo
        display: grid
        grid-template-columns: repeat(2, 1fr)

        > div
          max-width: inherit

@media (max-width: 520px)
  .header
    flex-direction: column-reverse
    gap: 1rem
    align-items: flex-end
//.portfolioOption
//  align-self: flex-start

@media (max-width: 480px)
  .tableButtons
    button
      padding: .5rem .7rem !important
  .tableOptions
    flex-wrap: wrap
    gap: .5rem

    > div
      &:nth-child(2)
        align-self: flex-end
        margin-left: auto

@media (max-width: 400px)
  .tradeAssistantWrapper
    .main
      .tradingHeader
        > div
          &:nth-of-type(2)
            flex-wrap: wrap
            justify-content: normal
                      
