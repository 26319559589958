
.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btns {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
}

.btn {
    padding: .3rem .5rem !important;
    background-color: #1abc9c !important;
    border-color: #1abc9c !important;
    color: white !important;
    /*width: 110px !important;*/
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    /*border: 2px solid lightgray !important;*/
    border-width: 2px !important;
    border-radius: .3rem !important;
    font-weight: 500 !important;
    /*color: #2B303A !important;*/
    transition: .3s all !important;
    cursor: pointer !important;
}

.btn:hover {
    background-color: #16a085 !important;
    border-color: #16a085 !important;
}

.refreshBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.refreshBtn:hover, .refreshBtn:active {
    color: white !important;
    background-color: #0834ae !important;
    border-color: #0834ae !important;
}

.activateBtn, .deactivateBtn {
    display: flex !important;
    align-items: center;
    /*width: 130px !important;*/
}

.activateBtn {
    background-color: #3498db !important;
    border-color: #3498db !important;
    border-radius: .3rem !important;
    color: white !important;
}

.activateBtn span, .deactivateBtn span {
    display: flex !important;
    align-items: center !important;
}

.activateBtn:hover {
    background-color: #2980b9 !important;
    border-color: #2980b9 !important;
}

.deactivateBtn {
    background-color: #bdc3c7 !important;
    border-radius: .3rem !important;
    border-color: #bdc3c7 !important;
    overflow: hidden !important;
}

.deactivateBtn:hover {
    background-color: #9da2a5 !important;
    border-color: #9da2a5 !important;
    color: white !important;
}

.menu li span, .menu li a {
    display: flex !important;
    align-items: center !important;
    font-size: 13px !important;
    gap: .3rem !important;
    font-family: Poppins-Regular !important;
}

.menu li .loading {
    color: gray !important;
    display: flex;
    align-items: center;
    gap: .3rem;
}

@media (max-width: 410px) {
    .btns {
        gap: .5rem;
    }
}