.serviceInfo {
    display: flex;
    align-items: center;
}

.serviceIcon {
    width: 20px;
    height: 20px;
}

.serviceTitle {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.serviceTitle svg {
    color: #0C4CFC;
}

.warnIcon {
    margin-left: .5rem;
    color: red;
}