.dropdownMenu {
    display: flex !important;
    justify-content: center !important;
    margin: 1rem auto !important;
    padding: .5rem 2rem !important;
    background-color: #B4D4FF !important;
    border-color: #B4D4FF !important;
}

.dropdownMenu:hover {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: #86B6F6 !important;
    border-color: #86B6F6 !important;
}

.menu {
    max-height: 300px;
    overflow-y: scroll;
}

.menu li {
    padding: .5rem 1rem !important;
}

.menu li span {
    font-size: 14px !important;
}