.step {
    /*position: absolute;*/
    /*left: -100%;*/
    /*transform: translateX(-150px);*/
    /*height: 0;*/
    /*opacity: 0;*/
    /*transition: .3s all;*/
    width: 100%;
}

.needToIntegrate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    padding: 1rem 0;
}

.needToIntegrate button {
    background-color: #0C4CFC !important;
    border: none !important;
}

.integrationLink {
    color: #2b5fec;
    text-decoration: underline;
}

.active {
    /*left: 0 !important;*/
    height: fit-content !important;
    transform: translateX(0);
    opacity: 1 !important;
}

.title {
    display: flex;
    align-items: center;
    gap: .3rem;
    margin-bottom: 0;
}

.inactive {
    opacity: .5 !important;
    cursor: not-allowed !important;
}

.nextBtn {
    align-self: flex-end;
    color: white;
    background-color: #2b5fec;
    cursor: pointer;
    border-radius: .4rem;
    padding: .3rem 1rem;
}

.backBtn {
    background-color: #74b9ff;
    width: fit-content;
    padding: .2rem .7rem;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .5rem;
    color: #341f97;
    font-weight: 500;
    transition: .3s all;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.backBtn:hover {
    background-color: #54a0ff;
}

.closeBtn {
    color: white;
    background-color: #2b5fec;
    cursor: pointer;
    border-radius: .4rem;
    padding: .3rem 1.5rem;
    align-self: center;
}

.unlinkBtn {
    color: #0C4CFC;
    text-decoration: underline !important;
    margin-bottom: .5rem;
    transition: .3s all;
    cursor: pointer;
}

.unlinkBtn:hover {
    color: #093cc7;
}

.coinName {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    gap: 5px;
    background-color: #E0F4FF;
    padding: .4rem;
    border-radius: .5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
}

.coinName h3, .coinName p {
    margin-bottom: 0 !important;
    line-height: 0 !important;
}

/*step 1-b*/
.steps {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.exchangeList {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-bottom: 0;
}

.exchangeItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    border: 2px solid lightgray;
    cursor: pointer;
    transition: .3s all;
    /*gap: 5px;*/
    padding: .3rem .5rem;
}

.selected {
    border-color: #2b5fec !important;
}

.exchangeImg {
    width: 30px;
    height: 25px;
}

.exchangeName {
    font-size: 13px;
}

.tradeTypeBox {
    transition: .3s all;
}

.tradeTypes {
    margin-bottom: 0;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
}

.tradeType, .secondTradeOption {
    border-radius: .4rem;
    border: 2px solid lightgray;
    cursor: pointer;
    transition: .3s all;
    width: 80px;
    padding: .3rem .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noUsdtBox {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.noUsdtMsg {
    display: flex;
    align-items: center;
    gap: .3rem;
    margin-bottom: 0;
    color: #e74c3c;
    /*justify-content: center;*/
    width: 100%;
}

.select {
    width: calc(100% - 2rem);
    margin: 1rem !important;
}

.symbolIcon {
    width: 25px;
    margin-right: .3rem;
    border-radius: 100%;
}

/*STEP 1-1*/

/*STEP 2*/
.secondTradeOptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0 .5rem;
    gap: 10px;
}

.secondTradeOption {
    width: 100% !important;
    gap: 5px;
}

.secondTradeOption:hover {
    border-color: #2b5fec;
}

.assetInfo {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    margin-bottom: 1rem;
}

.assetInfo > div {
    display: flex;
    align-items: center;
    gap: .3rem;
    background-color: #E0F4FF;
    justify-content: center;
    padding: .3rem;
    border-radius: .5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
}

.assetInfo p, .assetInfo h4 {
    margin: 0;
}

.setOrderBtn {
    /*margin-top: 1rem;*/
    display: flex;
    justify-content: flex-end;
}

.amountInput {
    border: 1px solid lightgray;
    border-radius: .4rem;
    padding: .3rem .5rem;
    /*margin: 1rem;*/
}

.amountInput:focus {
    border: 1px solid lightgray !important;
}

.inputError, .inputError:focus {
    border-color: #e74c3c !important;
}

.errorMessage {
    margin-bottom: 0;
    color: #e74c3c;
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
}

.inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding: .5rem 1rem;
}

.inputBox {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: .5rem 1rem;
}

/*STEP 2*/

/*STEP 1-C*/
.tradeOptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin: 0;
    padding: 1rem;
}

.tradeOption {
    border-radius: .4rem;
    border: 2px solid lightgray;
    cursor: pointer;
    transition: .3s all;
    padding: .3rem .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.marketTypeOptions {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    padding: 1rem !important;
    gap: 10px !important;
    width: 100% !important;
}

.marketTypeIcon {
    width: 25px;
    height: 25px;
}

.marketType span:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*STEP 1-C*/

/*PAPER TRADING STEP 2*/
.skeleton {
    display: inline-block
}

.skeleton div {
    width: 80px !important;
}

.skeleton div span {
    width: 80px !important;
    height: 18px !important;
    border-radius: .4rem !important;
    min-width: auto !important;
    margin-top: .2rem;
}

.remainBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .3rem;
}

.remainBox h4, .remainBox p {
    margin: 0;
}

.amountBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.amountBox h3 {
    grid-column: span 2;
}

.amountBox input {
    border-radius: .4rem !important;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/*PAPER TRADING STEP 2*/

/*RESULT*/
.errorBox, .successBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.questionBox {
    border: 1px solid #dfe6e9;
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.answerBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.answerBtn {
    transition: .3s all;
    cursor: pointer;
    border-radius: .3rem;
    width: 80px;
    text-align: center;
    padding: .3rem 1rem;
    border: 2px solid transparent;
}

.yesBtn {
    background-color: #2b5fec;
    color: white;
    font-weight: 500;
}

.yesBtn:hover {
    background-color: #0c3fce;
}

.noBtn {
    border-color: lightgray;
}

.noBtn:hover {
    border-color: #0c3fce;
}

/*RESULT*/

/*ADD TO VIRTUAL PORTFOLIO*/
.options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 1rem;
}

.option {
    border-radius: .4rem;
    border: 2px solid lightgray;
    cursor: pointer;
    transition: .3s all;
    padding: .3rem .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
}

.option:hover {
    border-color: #2b5fec;
}

.disable {
    opacity: .6;
    cursor: progress
}

.disable:hover {
    border-color: lightgray !important;
}

.submitBtn {
    background-color: #2b5fec !important;
    font-size: 13px;
    border: none !important;
}

/*ADD TO VIRTUAL PORTFOLIO*/

/*LIMIT ORDER*/
.limitOptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.priceSetting {
    gap: .5rem !important;
    padding: 0 .5rem !important;
}

.virtual {
    position: relative;
}

.virtual::after {
    position: absolute;
    top: -2px;
    right: -2px;
    content: "Virtual";
    background-color: #add8e6;
    border-top-right-radius: .3rem;
    font-size: 10px;
    padding: 0 .3rem;
}

.linked::after {
    content: "Linked";
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: #0C4CFC;
    color: white;
    display: block;
    font-size: 12px;
    border-top-right-radius: .3rem;
    padding: .05rem .5rem;
}

/*LIMIT ORDER*/

@media (max-width: 468px) {
    .exchangeList {
        grid-template-columns: repeat(3, 1fr);
    }

    .exchangeName {
        font-size: 12px;
    }
}