.container {
    margin: .5rem;
    border: 1px solid #f0f0f0;
    border-radius: 1rem;
    padding: 1rem;
    background-color: white;
}

.message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
    margin-bottom: 0;
    flex-wrap: wrap;
}

.dataList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    /*margin: 1rem 0 0;*/
    padding: 1rem 0 0;
    /*border-top: 1px solid lightgray;*/
}

.dataList li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*gap: .5rem;*/
}

.label {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    font-size: 16px;
    text-align: center;
}

.dataList li span:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    color: #747d8c;
}

.limitOrderStatus {
    text-align: center;
}

@media (min-width: 1200px) {
    .xlSize {
        display: none !important;
    }
}

@media (min-width: 992px) {

    .date {
        display: none !important;
    }

    .lgSize {
        display: none !important;
    }

    /*.dataList li {*/
    /*    flex-direction: row;*/
    /*    gap: .5rem;*/
    /*}*/
}

@media (min-width: 768px) {
    .orderMode {
        display: none !important;
    }

    .mdSize {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .label {
        font-size: 13px;
    }

    .message span:nth-child(2), .dataList li span:nth-child(2) {
        font-size: 12px;
    }
}

@media (min-width: 576px) {
    .smSize {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 0;
    }

    .failedOrder {
        grid-column: span 2;
    }
}