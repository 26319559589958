.list {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
}

.emptyBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.emptyBox p {
    text-align: center;
}

:global(.ant-tooltip) {
    max-width: 320px;
}

@media (max-width: 768px) {
    .description {
        display: none;
    }
}