.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
}

.container :global(.ant-skeleton-element) span {
    width: 100%;
    border-radius: 8px;
    height: 60px;
}

@media (min-width: 768px) {
    .container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .container :global(.ant-skeleton-element):nth-child(3),
    .container :global(.ant-skeleton-element):nth-child(4) {
        display: none;
    }
}
