
.title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.botList {
    /*display: flex !important;*/
    /*align-items: center !important;*/
    /*justify-content: center !important;*/
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    max-width: 100% !important;
    /*grid-template-columns: repeat(3, 1fr) !important;*/
    gap: 1rem .5rem !important;
    margin: 1.5rem auto !important;
}

.spinner {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.5rem !important;
    margin: 1rem auto !important;
}

.submitBtn {
    background-color: #0C4CFC !important;
    border: none !important;
    color: white !important;
    width: 100px !important;
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important;
}

.inactive {
    opacity: .5 !important;
    cursor: not-allowed !important;
}

.inactive:hover {
    color: white !important;
    cursor: not-allowed !important;
}

.timeBtns {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1.5rem 1rem;
    justify-content: center;
}

.timeBtns button {
    background-color: rgba(187, 221, 244, 0.68) !important;
    border-radius: .4rem !important;
    border: none !important;
    padding: .5rem 1rem !important;
    height: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transition: .3s all;
}

.timeBtns button:hover {
    background-color: #0C4CFC !important;
    color: white !important;
}

.timeBtns button.activeBtn {
    background-color: #0C4CFC !important;
    color: white !important;
}

.badge > sup {
    top: -5px !important;
    background-color: #4b82e6;
}

.market, .marginLeverage {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.market h4, .marginLeverage h4 {
    margin-bottom: 0;
}

.marginLeverage {
    margin-bottom: 1.5rem;
    align-items: baseline;
}

.marginLeverage h4 {
    flex: 1;
}

.marginLeverage > div {
    flex: 3;
}


@media (max-width: 550px) {
    .botList {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .marginLeverage > div {
        flex: 2;
    }
}