.container {
    padding: 1rem;
    margin: .5rem;
    border: 1px solid lightgray;
    border-radius: .8rem;
    background-color: white;
}

.dataList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.label {
    font-weight: 600;
    padding: 0 1rem .3rem;
    margin-bottom: .5rem;
    border-bottom: 2px solid lightgray;
    text-align: center;
}

.dataItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subDataList {
    padding: 0;
    margin: 0;
}

.subDataList li {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.secLabel {
    font-weight: 600;
}

@media (min-width: 768px) {
    .moreData {
        display: none !important;
    }
}

@media (max-width: 420px) {
    .container {
        margin: 0;
        padding: .5rem;
    }
}