.container {
    margin-top: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .3rem;
    width: 100%;
}

.container :global(.ant-btn) {
    width: 100%;
    background-color: #0C4CFC;
    color: white;
}

.container :global(.ant-btn):hover, .container :global(.ant-btn):focus, .container :global(.ant-btn):active {
    background-color: white;
    color: #0C4CFC;
}

.container :global(.ant-btn):hover span, .container :global(.ant-btn):focus span, .container :global(.ant-btn):active span {
    color: #0C4CFC;
}

/*@media (max-width: 768px) {*/
/*    .container {*/
/*        position: absolute;*/
/*        bottom: -5%;*/
/*        right: 0;*/
/*        z-index: 10;*/
/*    }*/
/*}*/

/*@media (min-height: 570px) {*/
/*    .container {*/
/*        position: absolute;*/
/*        right: 0;*/
/*        z-index: 10;*/
/*        bottom: -43%;*/
/*    }*/
/*}*/