.features {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    font-size: 13px;
}

.feature {
    display: flex;
    align-items: center;
    gap: .2rem;
    flex-wrap: wrap;
}

.icon svg {
    font-size: 16px;
    color: #0C4CFC;
}

.disabledIcon svg {
    color: #bdc3c7;
}

.status {
    margin-left: 1rem;
}

.disabledFeature, .unlimitedFeature {
    color: white;
    padding: .1rem .8rem;
    border-radius: 1rem;
    font-size: 11px;
}

.disabledFeature {
    background-color: #bdc3c7;
}

.unlimitedFeature {
    background-color: #0C4CFC;
}
