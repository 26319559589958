.custom-tabs .ant-tabs-tab {
    border-color: #6570ed !important;
}

.custom-tabs .ant-tabs-tab:hover {
    background-color: #6570ed !important;
}

.custom-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: white !important;
}

.custom-tabs .ant-tabs-tab-active {
    background-color: #6570ed !important;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.custom-tabs .ant-tabs-tab-btn span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
}

.custom-tabs .ant-tabs-nav {
    border-bottom: 1px solid lightgray !important;
    padding-bottom: .5rem;
}