.container {
    padding: 0 1rem;
}

.title {
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5rem;
    font-size: 15px;
    margin-bottom: 1rem;
}

.btns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: max-content;
    gap: 20px;
    margin: 1.5rem 0;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 0.7rem;
    padding: 0.7rem 1.2rem;
    font-size: 16px;
    color: #0d0d0d;
    cursor: pointer;
    transition: 0.3s all;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn:hover {
    color: #0c4cfc;
}

.inactive {
    cursor: not-allowed;
    opacity: 0.6;
}

.btn.inactive:hover {
    color: #0d0d0d !important;
}

.refundModalContent {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscriptionList {
    display: flex;
    gap: 1rem;
}

@media (min-width: 480px) {
    .title {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .title {
        font-size: 18px;
    }
}

@media (max-width: 720px) {
    .btns {
        grid-template-columns: repeat(2, 1fr);
    }

    .subscriptionList {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .btns {
        /* grid-template-columns: 1fr !important; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 15px;
    }

    .btn {
        font-size: 14px;
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;
    }
}
