.operations {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.featuresBtn {
    width: fit-content;
    align-self: flex-start;
    background-color: #1E90FF !important;
    color: white !important;
    border-color: #1E90FF !important;
}

.featuresBtn:hover, .featuresBtn:active, .featuresBtn:focus {
    background-color: white !important;
    color: #1E90FF !important;
}

.featuresBoxTitle {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.refreshBtn {
    align-self: flex-end;
    margin-left: auto;
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.refreshBtn:hover, .refreshBtn:active, .refreshBtn:focus {
    background-color: white !important;
    color: #0C4CFC !important;
}

@media (max-width: 480px) {
    .operations {
        gap: .5rem;
    }

    .operations :global(.ant-btn) {
        padding: 4px 6px !important;
    }

    .operations :global(.ant-btn) > span {
        font-size: 14px;
        font-family: Poppins-Regular !important;
    }
}