.text {
    display: flex;
    flex-direction: column;
    gap: .1rem;
    margin-bottom: 1rem;
}

.title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.text h5 {
    font-size: .75rem;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
    font-weight: 400;
}

.navigationBox a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem .8rem;
    font-family: inherit;
}

.navigationBoxLeftSide {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navigationBoxIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C359E;
    border-radius: .5rem;
    color: white;
}

.navigationBoxText h3 {
    margin-bottom: 0;
    color: #0C359E;
}

.navigationBoxText p {
    margin-bottom: 0;
    font-size: 12px;
}

.navigationBoxGoIcon {
    width: 25px;
    height: 25px;
    border: 1px solid #0C359E;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0C359E;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.navigationBoxGoIcon:hover {
    background-color: #0C359E;
    color: white;
}