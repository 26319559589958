.successBox, .errorBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 2rem;
}

.errorBox h4 {
    color: #e74c3c;
}

/*{checkboxState[option.stateKey] && (*/
/*<div className={styles.input} style={{ margin: "1rem 0" }}>*/
/*<label>Trigger price:</label>*/
/*<Input*/
/* style={{ borderRadius: ".4rem" }}*/
/*key={option.id} // Use the unique identifier as the key prop*/
/*                   id={option.id}*/
/*placeholder={option.placeholder}*/
/*status={*/
/*    option.id === "stopLoss" || option.id === "discountBuy"*/
/*    ? lowerPriceError && "error"*/
/*: higherPriceError && "error"*/
/*}*/
/*value={*/
/*    option.id === "stopLoss" || option.id === "discountBuy"*/
/*    ? lowerPriceValue*/
/*: higherPriceValue*/
/*}*/
/*onChange={triggerPriceChangeHandler}*/
/*!* Add necessary props for Input component *!*/
/*/>*/
/* {(option.id === "stopLoss" || option.id === "discountBuy") &&*/
/*lowerPriceError && (*/
/*<p*/
/* style={{ fontSize: "12px", marginBottom: "0" }}*/
/*className={styles.errorMessage}*/
/*>*/
/*Trigger price should be lower than the current price*/
/*</p>*/
/*)}*/
/*{(option.id === "takeProfit" || option.id === "breakOutBuy") &&*/
/*higherPriceError && (*/
/*<p*/
/* style={{ fontSize: "12px", marginBottom: "0" }}*/
/*className={styles.errorMessage}*/
/*>*/
/*Trigger price should be higher than the current price*/
/*</p>*/
/*)}*/
/*</div>*/
/*)}*/