.title {
    background-color: #c5dcfa;
    width: 100%;
    display: flex;
    border-radius: 0.5rem;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid #1b4aef;
}

.title h3 {
    align-self: center;
    margin: 0 auto;
}

.profileBox {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileBoxLeftSide {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.profileBoxLeftSide p {
    margin-bottom: 0;
}

.profileImage {
    width: 45px;
    height: 45px;
    border-radius: 100%;
}

.profileImage img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
}

.email {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .2rem;
}

.profileBoxDetail span, .profileBoxDetail p {
    font-family: Poppins-Medium;
}

.credit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .2rem;
}

.credit span {
    color: #0C359E;
}

.withdrawBtn {
    margin-left: .3rem !important;
    background-color: #0C359E !important;
    padding: 0 .3rem !important;
    color: white !important;
    font-size: 11px !important;
    border-color: #0C359E !important;
    font-family: Poppins-Regular !important;
}

.withdrawBtn:hover {
    color: #0C359E !important;
    background-color: white !important;
}

.profileBoxRightSide {
    background-color: #0C359E;
    border: 1px solid #0C359E;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
    color: white;
}

.profileBoxRightSide:hover {
    background-color: white;
    color: #0C359E;
}

.links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.link {
    padding: 1rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .3rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.link:hover {
    background-color: #0C359E;
    color: white;
}

.link:hover span, .link:hover svg {
    color: white;
}

.link span {
    font-family: Poppins-Medium;
    color: #0C359E;
    font-size: 13px;
    transition: .3s all ease-in-out;
}

.link svg {
    color: #0C359E;
    transition: .3s all ease-in-out;
}

.separator {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), #0C359E, rgba(255, 255, 255, 1));
}

.serviceActivationStatus {
    font-size: 11px !important;
    text-align: center;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    gap: .1rem;
    flex-wrap: wrap;
}

.notActive {
    color: #e84118 !important;
}

.active {
    color: #009432 !important;
}

.loading {
    color: #0C359E !important;
}

@media (max-width: 480px) {
    .profileBox {
        flex-direction: column;
        gap: .5rem;
    }

    .profileBoxLeftSide {
        flex-direction: column;
        width: 100%;
    }

    .profileBoxDetail {
        align-self: flex-start;
        margin-right: auto;
    }

    .profileBoxRightSide {
        align-self: flex-end;
    }
}