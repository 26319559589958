.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .2rem;
}

.title h3 {
    margin-bottom: 0;
}

.description {
    text-align: center;
    margin-bottom: 0;
}

.ipBox {
    border: 1px solid lightgray;
    border-radius: .4rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    width: 250px;
    align-self: center;
    margin: 0 auto;
}

.ipBox .ip {
    cursor: pointer;
    transition: .3s all ease-in-out;
    margin-bottom: 0;
    /*display: flex;*/
    /*align-items: center;*/
    /*gap: .2rem;*/
}

.copyIcon {
    cursor: pointer;
    align-self: flex-end;
    margin-left: auto;
}

.ipBox .ip:hover {
    color: #1a1919;
}

.btns {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: .5rem;
}

.updateBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.updateBtn:hover, .updateBtn:focus, .updateBtn:active {
    background-color: white !important;
    color: #0C4CFC !important;
}

.errorMsg {
    text-align: center;
}

.getIpButton {
    color: #0C4CFC;
    text-decoration: underline;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.getIpButton:hover {
    color: #072f9b;
}

.settingsMsg {
    text-align: center;
}

.settingsMsg span {
    color: #0C4CFC;
    cursor: pointer;
    transition: .3s all ease-in-out;
    text-decoration: underline;
    font-weight: bold;
}

.settingsMsg span:hover {
    color: #072f9b;
}


.successMsg, .failedMsg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    flex-direction: column;
    margin: 0 auto;
}

.successMsg p, .failedMsg p, .failedMsg h3 {
    text-align: center;
}

.modifyApiBtn {
    background-color: #FFA500 !important;
    border-color: #FFA500 !important;
    color: white !important;
}

.modifyApiBtn:hover, .modifyApiBtn:active, .modifyApiBtn:focus {
    background-color: white !important;
    color: #FFA500 !important;
}

