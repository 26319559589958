.menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: .7rem;
}

.menuItem {
    cursor: pointer;
    transition: .3s all;
    position: relative;
}

.menuItem a {
    color: #2B303A;
    display: flex;
    align-items: center;
    gap: .2rem;
    font-family: 'Poppins-Medium';
}

.menuItem a:hover::after {
    position: absolute;
    bottom: -.5rem;
    left: 0;
    content: "";
    height: 2px;
    width: 100%;
    display: block;
    background-color: #0C4CFC;
}


.menuItem a:hover {
    color: #0C4CFC;
}

.active a {
    color: #0C4CFC;
}

.active a::after {
    position: absolute;
    bottom: -.5rem;
    left: 0;
    content: "";
    height: 2px;
    width: 100%;
    display: block;
    background-color: #0C4CFC;
}

.menuItem:nth-child(even) {
    padding: 0 .5rem;
}

@media (min-width: 768px) {
    .menu a {
        font-size: 14px;
    }

    .menu {
        font-size: 1.2rem;
        font-family: "Poppins-Medium";
    }

    .menuItem:nth-child(even) {
        padding: 0 1.5rem;
    }
}

@media (max-width: 570px) {
    .menuItem a svg {
        display: none;
    }

    .menu {
        gap: .2rem;
    }
}