.container {
    margin: .8rem;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    background-color: white;
    border: 1px solid rgba(211, 211, 211, 0.4);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.infoBox {
    display: flex;
    align-items: center;
    gap: .2rem;
    flex-wrap: wrap;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    justify-content: center;
}

.infoBox:last-child {
    border-bottom: none;
}

.infoBox > span:nth-child(2) {
    text-align: center;
}

.label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: .2rem;
    justify-content: center;
    font-family: 'Poppins-Medium';
}

.aiMode {
    color: grey;
    margin-left: .2rem;
    font-size: 13px;
}

@media (min-width: 768px) {
    .showInMobile {
        display: none;
    }

    .infoBox:nth-last-child(3) {
        border-bottom: none;
    }
}

@media (max-width: 768px) {
    /*.container {*/
    /*    grid-template-columns: 1fr;*/
    /*}*/
    .infoBox {
        flex-direction: column;
    }
}

@media (max-width: 680px) {
    .label {
        flex-direction: column;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 0;
        padding: 1rem;
    }
}