.addMoreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    background-color: #c5dcfa;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #1b4aef;
    cursor: pointer;
    transition: .3s all ease-in-out;
    margin-bottom: 1rem;
}

.addMoreBtn:hover {
    background-color: #95c1fb;
}

.addMoreBtnIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 100%;
    background-color: #1b4aef;
}

.addMoreBtnText {
    font-family: Poppins-Medium;
    font-size: 15px;
}