.button {
    cursor: pointer;
    color: white;
    background-color: #0c4cfc;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 100%;
    transition: 0.3s all ease-in-out;
    border: 1px solid #0c4cfc;
    /*background-color: #fafafa;*/
}

.button:hover {
    color: #0c4cfc;
    background-color: white;
}

.option :global(.ant-select-item-option-content) {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.box {
    margin-bottom: 1rem;
}

.symbolOption {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.symbolIcon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.emptyOption > :global(.ant-select-item-option-content) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorMsg {
    margin-top: 0.5rem;
    font-size: 12px;
}

.errorMsg a {
    font-size: 12px;
    color: #0c4cfc;
    text-decoration: underline;
}

.submitButton {
    background-color: #0c4cfc !important;
    color: white !important;
    border-color: #0c4cfc !important;
    display: flex !important;
    justify-content: end !important;
    margin-left: auto !important;
    padding: 0.3rem 1.5rem !important;
}

.submitButton:disabled {
    opacity: 0.8 !important;
}

.submitButton:not(:disabled):focus,
.submitButton:not(:disabled):hover,
.submitButton:not(:disabled):active {
    background-color: white !important;
    color: #0c4cfc !important;
}
