.container {
    position: relative;
}

.poster {
    cursor: pointer;
    width: 80%;
    height: 240px;
    background-color: #1e3799;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: white;
    flex-direction: column;
    gap: 10px;
    border-radius: 1rem;
}

.loader {
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 240px;
    background-color: #e3e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: white;
    flex-direction: column;
    gap: 10px;
    border-radius: 1rem;
    z-index: 10;
}

.playIcon {
    border: 1px solid white;
    border-radius: 50%;
    padding: .3rem;
}


.player {
    margin: 0 auto !important;
    width: 80% !important;
}

@media (max-width: 1000px) {
    .player {
        width: 100% !important;
    }
}