.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.title {
    margin-bottom: 1rem;
}

.predictContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    margin-bottom: 2rem;
    text-align: center;
}

.predictTitle {
    font-size: 1rem;
    font-weight: bold;
    margin: 1rem 0 0.7rem;
}

.predictIcon {
    width: 35px;
    margin-left: 0.3rem;
}

.coin {
    border: 1px solid lightgray;
    padding: 0.6rem 1rem;
    width: 90%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.coin:not(:last-child) {
    margin-bottom: 0.7rem;
}

.coinImage {
    width: 22px;
    height: 22px;
    border-radius: 100%;
}

.coinName {
    font-size: 1rem;
    margin: 0 0.5rem;
    color: #0c4cfc;
    text-decoration: underline;
    cursor: pointer;
}

.linkBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: auto;
}

.link {
    /* align-self: flex-start; */
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: inline-block;
}

.link:hover {
    color: rgba(13, 100, 161, 0.85);
}

@media (min-width: 700px) and (max-width: 1000px) {
    .container {
        grid-column: span 2;
    }
}

@media (max-width: 700px) {
    /* .predictTitle {
      font-size: 0.85rem;
    } */
    .predictIcon {
        width: 20px;
    }

    .coin {
        padding: 0.6rem;
    }
}

@media (max-width: 400px) {
    .predictContainer {
        grid-template-columns: 1fr;
    }

    .linkBtns {
        flex-direction: column;
        align-items: flex-start;
    }

    .link:first-child {
        margin-bottom: 1rem;
    }

    /* .predictIcon {
      width: 15px;
    } */
}
