.title {
    font-size: 1.2rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
}

.chargeInput {
    margin: 1rem;
}

.payOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.btn {
    width: 100px !important;
    border-color: #0c359e !important;
    padding: 0.5rem !important;
    height: auto !important;
    color: #0c359e !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: .1rem !important;
    font-size: 11px !important;
}

.btn:hover,
.btn:active {
    background-color: #0c359e !important;
    color: white !important;
    border-color: #0c359e !important;
}

.selected {
    background-color: #0c359e !important;
    color: white !important;
    border-color: #0c359e !important;
}

.paymentBox {
    display: flex;
    justify-content: center;
}

.paymentLink {
    text-decoration: underline;
    color: #0C4CFC;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentLink:hover {
    color: #0C4CFC;
}

.checkoutBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.checkoutBox p {
    margin-bottom: 0;
}

.paymentBtn {
    background-color: #0c3fce;
    padding: .3rem 1rem;
    border-radius: .3rem;
    border: 1px solid #0c3fce;
    color: white;
    transition: .3s all ease-in-out;
    font-family: Poppins-Medium;
}

.paymentBtn:hover, .paymentBtn:active, .paymentBtn:focus {
    background-color: white;
    color: #0c3fce;
}

.redeemGiftBox {
    margin-top: 1.5rem;
}

.redeemInput {
    margin-bottom: 0 !important;
    margin-top: 2rem !important;
}

.redeemInput > div:nth-child(2) {
    justify-content: center !important;
}

.redeemInput p {
    font-size: 14px !important;
}

.confirmBtn {
    border-radius: .3rem;
    padding: .2rem 1rem;
    background-color: #0532ac;
    color: white;
    border: 1px solid #0532ac;
    font-family: Poppins-Medium;
    font-weight: 400 !important;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.confirmBtn:hover {
    background-color: white;
    color: #0532ac;
}

.redeemInput button {
    background-color: white !important;
    color: #0c359e !important;
    border: 1px solid #0c359e !important;
    font-family: Poppins-Medium;
    font-weight: 400 !important;
    transition: .3s all ease-in-out;
}

.redeemInput button:hover {
    background-color: #0c359e !important;
    color: white !important;
}

.confirmBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*@media (max-width: 400px) {*/
/*    .redeemInput input {*/
/*        width: 180px !important;*/
/*        min-width: 180px !important;*/
/*    }*/
/*}*/
