.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.botInfoCard {
    border: 1px solid #1b4aef;
    padding: 1rem;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins-Medium;
}

.infoTitle {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.infoTitle svg {
    color: #0C359E;
    font-size: 16px;
}

.info p {
    margin-bottom: 0;
}

.activeBot {
    color: #009432;
}

.notActiveBot {
    color: #e84118;
}

.actionBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    align-self: center;
    width: fit-content;
    background-color: #0C359E;
    color: white;
    border: 1px solid #0C359E;
    border-radius: .3rem;
    font-family: Poppins-Medium;
    padding: .5rem 1rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.actionBtn:hover {
    background-color: white;
    color: #0C359E;
}

.exchange {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.exchange img {
    width: 25px;
    height: 20px;
    border-radius: 100%;
}

.loading {
    color: #0c3fce;
}

.otherBotsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    width: fit-content;
    color: white;
    border: 1px solid #0C4CFC;
    border-radius: .3rem;
    font-family: Poppins-Medium;
    padding: .3rem .8rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
    background-color: #0C4CFC;
    /*margin-left: auto;*/
    /*align-self: flex-end;*/
    align-self: center;
}

.otherBotsBtn:hover {
    background-color: white;
    color: #0C4CFC;
}