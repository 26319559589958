.container {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.videoBtn {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-left: auto;
    color: gray;
    cursor: pointer;
    transition: .3s all ease-in-out;
    text-decoration: underline;
}

.videoBtn svg {
    font-size: 20px;
}

.videoBtn:hover {
    color: #0c3fce;
}

.ipBox {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
    border: 1px solid lightgray;
    border-radius: .4rem;
}

.copyOption {
    align-self: flex-end;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.copyOption:hover {
    color: #0c3fce;
}

.ipContainer {
    margin-bottom: .5rem;
}

.ipContainer p {
    margin-bottom: .5rem;
    font-family: inherit;
}