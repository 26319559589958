.logo {
    width: 25px;
    height: 25px;
    border-radius: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    /*width: 300px;*/
    justify-content: center;
    align-items: center;
    border: 1px solid #1b4aef;
    padding: .5rem 1rem;
    border-radius: .3rem;
    margin-bottom: 0;
}


.balanceInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.balanceInfo span:first-child {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.balanceInfo p {
    margin-bottom: 0;
}