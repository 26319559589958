.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
}

.exchangesList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    position: relative;
    max-height: 380px;
    overflow-y: scroll;
    padding: 16px;
}

.exchangesList::-webkit-scrollbar-track {
    background-color: transparent;
}

.exchangesList::-webkit-scrollbar-thumb {
    background-color: #b7d5fb;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: rgba(255, 255, 255, 0.57);
    z-index: 1000;
}

.loaderBox {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    padding: 1.5rem 1rem;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.loadingOverlay p {
    text-align: center;
    width: 90%;
    color: #2b303a;
    font-weight: 500;
}

.exchangeItem {
    border: 2px solid lightgray;
    padding: 0.5rem 2rem;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
    gap: 5px;
    height: 72px;
    position: relative;
}

.exchangeItem:hover {
    border-color: #2b5fec;
}

.connected {
    border-color: lightgreen !important;
}

.expired {
    border-color: #e74c3c !important;
}

.ipRequired {
    border-color: #f1c40f !important;
}

.expired::before {
    content: "Expired";
    position: absolute;
    left: 1%;
    font-size: 14px;
    color: #c0392b;
    top: -3%;
}

.connected::before {
    /*/2713*/
    content: "\2705";
    position: absolute;
    left: -6px;
    font-size: 25px;
    color: green;
    top: -15%;
}

.ipRequired::before {
    content: "IP Reassign Required";
    font-size: 11px;
    color: #f1c40f;
    top: -2%;
    left: 2%;
    font-weight: 600;
}

.virtualExchange:after {
    position: absolute;
    top: -3%;
    right: -1%;
    content: "Virtual";
    background-color: #add8e6;
    /*color: white;*/
    padding: 0.2rem 0.9rem;
    border-radius: 0 0.3rem 0 0;
    font-size: 10px;
}

.auExchange::after {
    content: "AU";
    position: absolute;
    top: -3%;
    /*left: 74%;*/
    right: -1%;
    background-color: #2b5fec;
    color: white;
    padding: 0.2rem 0.9rem;
    border-radius: 0 0.3rem 0 0;
    font-size: 10px;
}

.certificatedBadge {
    position: absolute;
    top: 3%;
    right: 0;
    z-index: 1;
    font-size: 13px;
}

.firstExchange::after,
.secondExchange::after,
.thirdExchange::after {
    position: absolute;
    top: -3%;
    /*left: 76%;*/
    right: -1%;
    color: black;
    padding: 0.2rem 0.9rem;
    border-radius: 0 0.3rem 0 0;
    font-size: 10px;
}

.auExchange .certificatedBadge {
    color: white !important;
}

.firstExchange .certificatedBadge,
.secondExchange .certificatedBadge,
.thirdExchange .certificatedBadge,
.virtualExchange .certificatedBadge {
    color: black !important;
}

.firstExchange .certificatedBadge,
.secondExchange .certificatedBadge,
.thirdExchange .certificatedBadge,
.virtualExchange .certificatedBadge,
.auExchange .certificatedBadge {
    background-color: transparent !important;
    padding: 0 !important;
    font-size: 13px !important;
    top: 3% !important;
    right: 0 !important;
    width: unset !important;
    height: unset !important;
    z-index: 1;
}

.exchangeItem .certificatedBadge {
    /*color: #FFB000;*/
    color: white;
    background-color: #ffb000;
    font-size: 18px;
    width: 20px;
    height: 30px;
    top: -2px;
    right: -2px;
    border-top-right-radius: 0.3rem;
    z-index: 1;
}

.firstExchange::after {
    content: "#1";
    background-color: #ffd700;
}

.secondExchange::after {
    content: "#2";
    background-color: #808080;
}

.thirdExchange::after {
    content: "#3";
    background-color: #cd7f32;
}

.exchangeImg {
    width: 25px;
    height: 25px;
    /* object-fit: cover; */
}

.exchangeName {
    font-family: Poppins-Medium;
}

@media (min-width: 768px) {
    .exchangesList {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 570px) and (max-width: 767px) {
    .exchangesList {
        grid-template-columns: repeat(3, 1fr);
    }
}
