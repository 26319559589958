.title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.list ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 15px;
}

.list ul li:last-child {
    border-bottom: 1px solid #f0f0f0 !important;
}

.actionBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-top: 2rem;
}

.actionBtns button {
    width: 130px !important;
}

.activateBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    transition: .3s all;
}

.activateBtn:hover {
    color: #0C4CFC !important;
    background-color: white !important;
    border-color: #0C4CFC !important;
}


.deleteBtn {
    /*margin: 1rem auto 0 !important;*/
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: .2rem !important;
    background-color: #e74c3c !important;
    color: white !important;
    border-color: #e74c3c !important;
    /*padding: 4px 20px !important;*/
    transition: .3s all;
}

.deleteBtn:hover {
    color: #e74c3c !important;
    background-color: white !important;
}