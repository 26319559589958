.helpTitle {
    font-size: 22px;
}

.helpImage, .imageContainer img {
    width: 100%;
    max-width: 900px;
    margin: 1rem auto;
    display: flex;
}

.imageContainer {
    min-height: 100px;
}

.helpContainer {
    width: 100%;
    font-size: 16px;
}

.helpContainer p {
    font-size: 16px;
    width: 80%;
}

.stepContainer:not(:last-child) {
    margin-bottom: 1.5rem;
}

.stepNumber {
    color: #0c4cfc;
    margin-right: 0.5rem;
}

.subStepWrapper {
    margin-left: 1.5rem;
    padding: 1.5rem 0;
}

.subStepContainer:not(:last-child) {
    margin-bottom: 1.5rem;
}

.helpList {
    color: #666666;
    font-family: Poppins-Regular;
}

.helpList li {
    list-style-type: disc;
    margin-left: 1.5rem;
    padding: 0.5rem 0;
}

@media (max-width: 700px) {
    .helpContainer p {
        width: 100%;
    }
}
