.container {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
}

.logoImg {
  width: 55px;
}

.logoTxt {
  width: 220px;
}

@media (max-width: 500px) {
  .logoImg {
    width: 35px;
  }
  .logoTxt {
    width: 190px;
  }
  .container {
    margin-bottom: 2rem;
  }
}
