.portfolioTable {
    margin-top: .5rem;
}

.portfolioTable th {
    color: blue !important;
    font-size: 1rem !important;
    background-color: rgba(150, 171, 234, 0.25) !important;
}

.title {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.coinIcon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: .5rem;
}

.addBtn {
    padding: .5rem;
    background-color: #547ef1;
    cursor: pointer;
    border-radius: 0.4rem;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}

.buyBtn {
    /*background-color: #0C4CFC;*/
    border: 1px solid #70AF85;
    background-color: #70AF85;
    color: white;
    padding: 4px 8px;
    /*border-radius: 16px;*/
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    margin: 0 auto;
    transition: .3s all;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px
}

.buyBtn:hover {
    color: #70AF85;
    background-color: white;
}

.sellBtn {
    border: 1px solid #EF4B4B;
    background-color: #EF4B4B;
    color: white;
    padding: 4px 8px;
    /*border-radius: 16px;*/
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    margin: 0 auto;
    transition: .3s all;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px
}

.sellBtn:hover {
    color: #EF4B4B;
    background-color: white;
}

.description {
    padding: .5rem;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*margin-bottom: 1.5rem;*/
    flex-wrap: wrap;
    gap: 1rem;
}

.paperTradingBtn {
    background-color: #FFC107 !important;
    border-radius: .3rem !important;
    border-color: #FFC107 !important;
    color: white !important;
}

.paperTradingBtn:hover, .paperTradingBtn:active {
    background-color: white !important;
    color: #FFC107 !important;
}

.options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 0;
    justify-content: flex-end;
    gap: .5rem;
}

.options button {
    border-radius: .3rem !important;
}

.refreshBtn {
    padding: 0.3rem 0.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: .2rem !important;
    border-radius: 0.3rem !important;
    font-weight: 500 !important;
    transition: .3s all !important;
    cursor: pointer !important;
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.refreshBtn:hover {
    background-color: white !important;
    color: #0C4CFC !important;
}

.sellFiatBtn {
    background-color: #FE0000 !important;
    color: white !important;
    border-color: #FE0000 !important;
    border-radius: .3rem !important;
}

.sellFiatBtn:hover {
    background-color: transparent !important;
    color: #FE0000 !important;
}

.sellFiatBtn:active {
    color: #FE0000 !important;
}

.sellCryptoBtn {
    background-color: #0D9276 !important;
    border-color: #0D9276 !important;
    color: white !important;
}

.sellCryptoBtn:hover, .sellCryptoBtn:active {
    background-color: white !important;
    color: #0D9276 !important;
}

.exchangeName {
    margin-bottom: 1rem;
    display: none;
}

.expandIcon {
    color: #0C4CFC;
    cursor: pointer;
    transition: .3s all;
    border: 1px solid transparent;
}

.expandIcon:hover {
    color: #082a87;
    transform: scale(1.05);
}

.status span {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.showMoreBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.showMoreBtn :global(.ant-btn-default) {
    background-color: #0C4CFC;
    color: white;
    border-color: #0C4CFC;
    padding: 4px 1.5rem;
}

.showMoreBtn :global(.ant-btn-default):hover, .showMoreBtn :global(.ant-btn-default):focus, .showMoreBtn :global(.ant-btn-default):active {
    background-color: white;
    color: #0C4CFC;
    border-color: #0C4CFC;
}

.positionBtn {
    background-color: green;
    padding: .2rem .5rem;
    color: white;
    border-radius: .3rem;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
}

@media (max-width: 768px) {
    .portfolioTable th {
        font-size: 14px !important;
    }

    .description {
        font-size: 13px;
    }

    .buyBtn, .sellBtn {
        padding: .2rem .5rem;
        width: 50px;
    }

    .exchangeName {
        display: block;
    }

    .coinIcon {
        width: 20px;
        height: 20px;
    }

    .desktopView {
        display: none !important;
    }
}

@media (max-width: 468px) {
    .portfolioTable th, .portfolioTable td {
        font-size: 12px !important;
    }

    .coinIcon {
        width: 15px;
        height: 15px;
    }
}