.infoBox {
    display: flex;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
}

.withdrawBtn {
    /*background-color: #0C4CFC;*/
    /*color: white;*/
    padding: .1rem .5rem !important;
    font-size: 11px;
    /*border-radius: .8rem;*/
    /*cursor: pointer;*/
    /*border: 1px solid #0C4CFC;*/
    /*transition: .3s all ease-in-out;*/
}

.addIcon {
    cursor: pointer;
    color: #0C4CFC;
    transition: .3s all ease-in-out;
}

.addIcon:hover {
    color: #06298b;
}

/*.withdrawBtn:hover {*/
/*    background-color: white;*/
/*    color: #0C4CFC;*/
/*}*/

/*.disabled {*/
/*    opacity: .8 !important;*/
/*    cursor: not-allowed !important;*/
/*}*/

/*.disabled:hover {*/
/*    background-color: #0C4CFC !important;*/
/*    color: white !important;*/
/*}*/