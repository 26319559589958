.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    /*border: 1px solid #1b4aef;*/
    padding: 1rem;
    border-radius: .3rem;
    margin-top: 1.5rem;
}

.startBtn {
    font-family: Poppins-Medium;
    cursor: pointer;
    background-color: #0c3fce;
    color: white;
    border: 1px solid #0c3fce;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
    padding: .3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
}

.startBtn:hover {
    background-color: white;
    color: #0c3fce;
}

.container p {
    text-align: center;
}

.integrateBtn {
    background-color: #0c3fce !important;
    border-color: #0c3fce !important;
    color: white !important;
}

.integrateBtn:focus, .integrateBtn:active, .integrateBtn:hover {
    background-color: white !important;
    color: #0c3fce !important;
}