.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    position: relative;
    min-height: 460px;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.arrow {
    position: absolute;
    border: 1px solid lightgray;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 100;
    background-color: white;
    transition: 0.4s all;
    top: 50%;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 2px 2px 5px 0px rgba(156, 152, 152, 0.75);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(156, 152, 152, 0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(156, 152, 152, 0.75);
}

.arrow:hover {
    color: black;
}

.left {
    left: 2%;
}

.right {
    right: 2%;
}

.title {
    margin-bottom: 1rem;
}

.slide {
    /*width: 100%;*/
    /*height: 100%;*/
    width: calc(100% - 30px);
    height: calc(100% - 60px);
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.topCoins .slideContainer {
    display: block;
}

.topCoins .firstColumn {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.slide h3 {
    margin-bottom: 1rem;
}

.show {
    opacity: 1;
}

.slideContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.item {
    width: 80%;
    padding: 0.7rem 1rem;
    border: 1px solid lightgray;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-evenly;
}

/* .item:not(:last-child) {
  margin-bottom: 1rem;
} */

.itemValue {
    margin-left: 0.8rem;
}

.link {
    /* align-self: flex-start; */
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: inline-block;
    margin-top: 1rem;
    position: absolute;
    bottom: 1.5rem;
    /*bottom: .5rem;*/
}

.link:hover {
    color: rgba(13, 100, 161, 0.85);
}

@media (min-width: 700px) and (max-width: 1000px) {
    .container {
        grid-column: span 2;
    }

    .slide {
        left: 50%;
        transform: translateX(-45%);
    }
}

@media (max-width: 700px) {
    .slide {
        top: 18px;
        left: 10px;
    }

    .item {
        width: 90%;
        padding: 0.7rem 0.5rem;
    }
}
