.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 460px;
}

.header {
    /*font-size: 17px;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.marketName {
    font-size: 17px;
    color: rgba(7, 124, 209, 0.85);
}

.btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
}

.link {
    /* align-self: flex-start; */
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: inline-block;
}

.link:hover {
    color: rgba(13, 100, 161, 0.85);
}

.showLink {
    /* align-self: flex-end; */
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    font-size: 15px;
}

.showLink:hover {
    color: rgba(13, 100, 161, 0.85);
}

.itemcontainer {
    margin-bottom: 1rem;
}

.itemtitle a {
    color: black;
    transition: .4s all;
}

.itemtitle a:hover {
    color: #1890ff;
}

.itemsubtitle {
    color: #8a8989;
}

.itemsubtitle span:first-child {
    margin-right: 1rem;
}