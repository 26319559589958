@import "../../variables"
@import "../../mixins"
.wrapper
  width: 100%
  display: flex

  .main
    width: 100%
  //width: 80%


  .sideBar
    width: 15%
    position: fixed
    padding-left: 16px
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.05)
    right: 0
    height: calc(100vh - 60px)
    padding-top: 24px
    top: 60px

    .overAllAndCoins
      display: flex
      flex-direction: column
      //align-items: center
      gap: 48px

      > div
        &:nth-of-type(1)
          display: flex
          flex-direction: column
          gap: 12px


        &:nth-of-type(2)
          display: flex
          flex-direction: column
          gap: 16px
          padding-top: 16px
          box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.05)

        > h4
          @include fontNormal
          font-size: 1.025rem

        .radioContainer
          width: 100%
          display: flex
          flex-direction: column
          gap: 36px

          .radioItem
            display: flex
            align-items: flex-start
            gap: 8px

            div
              width: 100%
              display: flex
              flex-direction: column
              gap: 12px

              > div
                display: flex
                flex-direction: row
                gap: 12px
                align-items: center

                > span
                  @include fontNormal

                > svg
                  font-size: 24px
                  color: #0C4CFC


              > span
                @include fontNormal
                color: $Mortar
                font-size: 0.875rem

    .marketTypeRadio
      display: flex
      align-items: center
      gap: 8px

      span
        display: flex
        align-items: center
        gap: 16px
        @include fontNormal

      svg
        font-size: 24px
        color: #0C4CFC

@media (max-width: 1200px)
  .wrapper
    .main
      width: 100%
      position: relative

    .sideBar
      display: none