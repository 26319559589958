.container {
    display: flex;
    flex-direction: column;
    /* display: grid; */
    /*grid-template-columns: 1fr 1fr;*/
    grid-gap: 15px;
    gap: 10px;
    padding: 0 0.5rem;
    width: 100%;
}

.inputBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.inactive {
    opacity: .5 !important;
    cursor: not-allowed !important;
}

.input {
    width: 100% !important;
    border-radius: .4rem !important;
}

.errorMessage {
    color: #e74c3c;
    font-size: 12px;
    grid-column: span 2;
    margin-bottom: 0;
}

.sliderBox {
    display: flex;
    gap: 10px;
    grid-column: span 2;
}

.slider {
    width: 70% !important;
}