.main {
    /*width: 80%*/
    width: 100%;
}

@media (min-width: 1200px) and (max-width: 1700px) {
    .main {
        width: calc(100% - 32px);
    }
}

@media (max-width: 1200px) {
    .main {
        width: 100%;
    }
}