.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    margin: 1rem auto;
    font-family: Poppins-Regular;
    border: 1px solid #0c3fce;
    padding: 2rem 1.5rem;
    width: 90%;
    border-radius: .5rem;
    box-shadow: rgba(12, 63, 206, 0.22) 0px 4px 12px;
}

.container :global(.ant-form-horizontal) {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
}

.container :global(.ant-form-item) {
    margin-bottom: 0;
}

.container :global(.ant-form-item-explain-error) {
    font-size: 13px;
}

:global(.ant-form-horizontal) input {
    border-radius: .5rem;
    padding: .5rem 1rem;
}

.loginOptions {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: Poppins-Medium;
    font-weight: 600;
}

.loginOption {
    cursor: pointer;
    transition: .3s all ease-in-out;
    border-bottom: .2rem solid transparent;
    padding: .2rem 0;
}

.editEmailBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0C4CFC;
    transition: .3s all ease-in-out;
    cursor: pointer;
    text-decoration: underline;
    gap: .2rem;
}

.editEmailBtn:hover {
    text-decoration: none;
}

.active {
    border-bottom-color: #0C4CFC !important;
}

:global(.ant-input-group-addon):last-child {
    border-radius: 0 .5rem .5rem 0;
}

.backBtn {
    margin-bottom: 1rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.backBtn:hover {
    opacity: .8;
}

.submitBtn {
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #0c3fce !important;
    border-color: #0c3fce !important;
    height: 40px !important;
}

.submitBtn:hover, .submitBtn:active, .submitBtn:focus {
    background-color: white !important;
    color: #0c3fce !important;
}

.googleBtn > div:first-child {
    width: 80%;
}

.createAccountBtn {
    color: #0c3fce;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0;
}

.switchToLoginBtn {
    text-align: center;
    margin-bottom: 0;
}

.switchToLoginBtn span, .resendCodeBtn {
    color: #0c3fce;
    cursor: pointer;
}

.resendCodeBtn {
    text-decoration: underline;
    transition: .3s all ease-in-out;
}

.resendCodeBtn:hover {
    text-decoration: transparent;
    opacity: .8;
}

.googleBtn {
    align-self: center;
    padding: .5rem .8rem;
    border-radius: .3rem;
    display: flex;
    align-items: center;
    border: 2px solid lightgrey;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.googleBtn:hover {
    border-color: grey;
}
