@import "../variables"
@import "../mixins"
.multiLevelSideBarContainer
  width: 260px
  transform: translateY(60px)
  height: calc(100vh - 60px)
  img
    width: 30px !important
    margin-right: 16px
    //border-radius: 100%
.modalItems
  width: 100%
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr
  gap: 16px

  .modalItem
    width: 100%
    cursor: pointer
    @include fullFlex
    background-color: $Malibu
    padding: 8px 16px
    border-radius: 8px
    color: $white
    gap: 16px
    box-shadow: 0 5px 5px rgba(0,0,0,0.1),-5px 0 5px rgba(0,0,0,0.1)
    transition: all .2s linear
    &:hover
      transform: scale(1.09)


    >span
      &:nth-of-type(1)
        @include fontNormal
      &:nth-of-type(2)
        width: 30px
        height: 30px
        >img
          width: 100%
          border-radius: 100%
  .activeSymbol
    background-color: $primary
    >span
      &:nth-of-type(1)
        @include fontNormal
        color: $white

@media (min-width: 571px)
  .modalItems
    grid-template-columns: 1fr 1fr 1fr

@media (max-width: 570px)
  .modalItems
    .modalItem
      gap: 0
      padding: 8px
      justify-content: space-evenly
      >span
        &:nth-of-type(1)
          font-size: 14px
        &:nth-of-type(2)
          width: 25px
          height: 25px

@media (max-width: 500px)
  .modalItems
    grid-template-columns: 1fr 1fr 1fr  

@media (max-width: 390px)
  .modalItems
    grid-template-columns: 1fr 1fr !important  