.options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.option {
    width: 80px;
    background-color: #c5dcfa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .2rem;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.option:hover, .selectedOption {
    background-color: #0C4CFC;
    color: white;
}

.question {
    display: flex;
    align-items: baseline;
    gap: .2rem;
    margin-bottom: 1rem;
}

.submitBtnContainer {
    display: flex;
    justify-content: center;
    margin: 1.5rem auto 0;
}

.submitBtn {
    background-color: #0C4CFC !important;
    color: white !important;
    border-color: #0C4CFC !important;
    align-self: center;
    width: 120px;
}

.submitBtn:disabled {
    opacity: .8 !important;
}