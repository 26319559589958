.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.noPaymentsBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

/*.table {*/
/*    margin-top: 5rem;*/
/*}*/

.table {
    margin: auto 0;
}

.table,
.table td,
.table th {
    /* border: 1px solid lightgray; */
    padding: 1rem;
}

.table th {
    text-align: start;
}

.table th:nth-child(2) {
    padding: 1rem 1.5rem;
}

.table td {
    font-size: 14px;
}

.table td:nth-child(2) {
    text-align: start;
}

.table tr:not(:last-child) {
    border-bottom: 1.5px solid lightgray;
}

.paymentValue {
    font-size: 10px;
    color: rgba(42, 42, 42, 0.56);
    font-weight: 600;
}

.content {
    display: flex;
    flex-direction: column;
}

.btns {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-right: 1rem;
}

.link {
    /* align-self: flex-start; */
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: inline-block;
    /* width: 30%; */
}

.link:hover {
    color: rgba(13, 100, 161, 0.85);
}

.lineChartSkeleton {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 24px;
}

.lineChartSkeleton div {
    width: 100%;
}

.lineChartSkeleton div span {
    width: 100% !important;
    min-height: 350px;
    border-radius: 8px;
}

@media (max-width: 1000px) {
    .content {
        margin-bottom: 2rem;
    }

    .noPaymentsBox {
        margin-bottom: 1rem;
    }
}
