.button {
    padding: .4rem .7rem;
    font-size: 14px;
    background-color: #D2E0FB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    color: #2f3542;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.button:hover {
    background-color: #0C4CFC;
    color: white;
}

.active {
    background-color: #0C4CFC;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 480px) {
    .label {
        display: none;
    }
}