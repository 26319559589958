.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actions {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.actions li {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* font-size: 1rem; */
    font-family: Poppins-Regular;
}

.actions .info {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    width: 100%;
}

.actions .info a {
    color: #0c4cfc;
}

/* .actions li a {
    font-size: 1rem;
} */

.actions li:not(:last-child) {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1.5px solid lightgray;
}

.actions li a img {
    transform: rotate(0);
    transition: transform 0.2s ease-in-out;
}

.actions li a:hover img {
    animation: shake 0.2s;
    animation-iteration-count: 1;
}

.time {
    font-size: 0.85rem;
    text-align: center;
    margin-right: 1rem;
    border-radius: 0.5rem;
    background-color: #c5dcfa;
    padding: 0.3rem 0.5rem;
    width: 100px;
    display: inline-block;
}

.section {
    font-size: 0.95rem;
}

.moreBtn {
    margin-top: 0.3rem;
    display: block;
    text-align: end;
    margin-left: auto;
    /* background-color: lightgray;
      padding: .2rem .8rem;
      border-radius: .2rem; */
    width: fit-content;
    cursor: pointer;
    transition: 0.3s all;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    text-decoration: underline;
}

.moreBtn:hover {
    color: #0c4cfc;
}

.modalList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.modalList li {
    margin-bottom: 1rem;
}

.modalList li a img,
.features li img {
    transform: rotate(0);
    transition: transform 0.2s ease-in-out;
}

.modalList li a:hover img,
.features li:hover img {
    animation: shake 0.2s;
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: rotate(0);
    }

    30% {
        transform: rotate(-20deg);
    }

    60% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(20deg);
    }
}

.features li {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: none !important;
}

.showLink {
    align-self: flex-start;
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    font-size: 15px;
    margin-top: auto;
}

.showLink:hover {
    color: rgba(13, 100, 161, 0.85);
}

.chartContainer {
    width: 100%;
}

.NegativeAreaChartSkeleton div {
    width: 100%;
}

.NegativeAreaChartSkeleton span {
    width: 100% !important;
    min-height: 380px;
    border-radius: 8px;
}

@media (max-width: 700px) {
    .modalList {
        grid-template-columns: 1fr;
    }
}
