.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo {
    color: #414144 !important;
    font-weight: 600;
}

.backBtn {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s all;
}

.backBtn:hover {
    transform: scale(1.1);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.title {
    align-self: center;
    text-align: center;
}

.title h3 {
    font-size: 26px;
    font-weight: 800;
    color: #414144;
}

.loader {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: .5rem !important;
    min-height: 400px !important;
}

.body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.optional h4 {
    font-size: 18px;
    font-weight: 600;
}

.optional p {
    width: 80%;
}

.restrictionBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 85%;
}

.restrictionBox h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.ipBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: .5rem;
    border: 1px solid lightgray;
    border-radius: .4rem;
}

.copyOption {
    align-self: flex-end;
    cursor: pointer;
}

.restrictionOption {
    display: flex;
    /*align-items: center;*/
    gap: .3rem;
}

.checkbox span span {
    border: 1px solid black !important;
    border-radius: .1rem !important;
    width: 20px !important;
    height: 20px !important;
}

.hideBorder span span {
    border-color: transparent !important;
}

.restrictionOption p {
    margin-bottom: 0 !important;
}

.restrictionOption p span {
    color: #0C4CFC;
    text-decoration: underline;
    cursor: pointer;
}

.submitBtn {
    border-radius: 20px;
    background-color: #007bff;
    color: white;
    text-align: center;
    width: 85%;
    padding: 0.6rem;
    cursor: pointer;
    transition: 0.3s all;
    margin-top: 1.5rem;
    font-weight: 600;
}

.submitBtn:hover {
    background-color: #0169d8;
}

.guideLoader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 2;
}

.guideContent {
    display: flex;
    flex-direction: column;
}

.stepsList {
    /*padding: 1.5rem;*/
    /*align-self: center;*/
    /*background-color: rgba(174, 210, 255, 0.29);*/
    position: relative;
    padding: 1.5rem;
}

.stepsList::before {
    display: block;
    content: "";
    position: absolute;
    top: -30%;
    width: 110%;
    left: -5%;
    height: 130%;
    background-color: rgba(174, 210, 255, 0.13);
    z-index: -1;
    border-radius: 1rem;
}

.stepsList li {
    padding: 0 4rem;
}


.stepsItem:not(:last-child) {
    margin-bottom: 0.5rem;
}

@media (max-width: 1000px) {
    .stepsList li {
        padding: 0 1.5rem;
    }
}

@media (max-width: 900px) {
    .body {
        gap: 20px;
    }

}

@media (max-width: 756px) {
    .body {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .submitBtn {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .restrictionBox {
        width: 100%;
    }
}
