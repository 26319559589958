.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    /*padding-right: 200px;*/
    /*background: url("../../../asset/Images/login\ page\(1\).png");*/
    /*background-image: url("../../../asset/Images/bg-login.png");*/
    background-image: url("../../../asset/Images/bg-login.jpg");
    /*background-image: url("../../../asset/Images/bg-login.jpg");*/
    /*background-image: url("../../../asset/Images/bg.svg");*/
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: 50% 50%;
    background-size: 100% 100%;
}

.container-login100::after {
    content: "";
    width: 100%;
    height: 100vh;
    position: absolute;
    /* background-color: rgba(13, 16, 54, 0.71); */
    top: 0;
    left: 0;
    z-index: 1;
}

.content {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.texts,
.texts p {
    color: white !important;
}

.texts h3,
.texts h1 {
    font-weight: 600 !important;
}

.texts h1 {
    color: white !important;
    font-weight: 500 !important;
    position: relative !important;
    background-color: transparent !important;
    padding: 0 !important;
    left: 0 !important;
    font-size: 2.1rem;
    line-height: 2;
}

.texts div:not(:last-child) {
    margin-bottom: 1.7rem;
}

.texts div h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: white !important;
}

.texts div p {
    font-size: 1.2rem;
    width: 80%;
}

.title {
    color: white !important;
    margin-bottom: 1.2rem;
    font-size: 2.2rem;
    position: unset !important;
    background-color: transparent !important;
    padding: 0 !important;
}

.wrap-login100 {
    width: 450px;
    /* background: #13133d; */
    /*background: #2f3336;*/
    /*background-color: #151A2B;*/
    /*background-color: #05042f;*/
    background-color: #080725;
    border-radius: 10px;
    overflow: hidden;
    padding: 30px 55px 30px 55px;
    box-shadow: rgb(185 191 213 / 18%) 0px 2px 12px 0px;
    /*box-shadow: rgb(45 69 153 / 53%) 0px 2px 12px 0px;*/

    /*box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);*/
    /*-moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);*/
    /*-webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);*/
    /*-o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);*/
    /*-ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);*/

    z-index: 100;
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
    width: 100%;
    z-index: 100;
}

.login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
}

.login100-form-title i {
    font-size: 60px;
    color: white;
    background-color: #333333;
    font-size: 40px;
    padding: 5px 10px;
    border-radius: 5px;
}

.form-title {
    font-size: 16px;
    color: white;
    font-family: Poppins-Regular;
}

/*-----------------------------TAB-------------------------------*/
.login-tab {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    font-family: Poppins-Regular;
}

.login-option {
    cursor: pointer;
    transition: .3s all ease-in-out;
    border-bottom: 2px solid transparent;
}

.login-option:hover {
    opacity: .8;
}

.selected-option {
    border-color: #3fa9f5;
}

/*-----------------------------OTP--------------------------------*/
.edit-email-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    margin-top: 1rem;
    color: #3fa9f5;
    font-family: Poppins-Regular;
    cursor: pointer;
}


/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-top: 37px;
}

.checkbox-wrap {
    width: auto !important;
    border-bottom: none !important;
    display: flex;
}

.input100 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #faf8f8;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
}

/*---------------------------------------------*/
.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    /* background: #6a7dfe; */
    /*background: #33a1bf;*/
    background: #3fa9f5;
    background: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    background: -o-linear-gradient(left, #21d4fd, #b721ff);
    background: -moz-linear-gradient(left, #21d4fd, #b721ff);
    background: linear-gradient(left, #21d4fd, #b721ff);
}

.focus-input100::after {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #ecdede;
    line-height: 1.2;

    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;
    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
    top: -15px;
}

.input100:focus + .focus-input100::before {
    width: 100%;
}

.input100.has-value + .focus-input100::after {
    top: -15px;
}

.has-val.input100 + .focus-input100::after {
    top: -15px;
}

.has-val.input100 + .focus-input100::before {
    width: 100%;
}

/*---------------------------------------------*/
.btn-show-pass, .reload-btn {
    font-size: 15px;
    color: #999999;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.reload-btn {
    cursor: pointer;
}

.refreshBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    color: white;
    cursor: pointer;
    margin: 0 auto;
}

.btn-show-pass i {
    cursor: pointer;
}

.border-error {
    border-color: red !important;
}

/*.btn-show-pass:hover {*/
/*    color: #6a7dfe;*/
/*    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);*/
/*    color: -o-linear-gradient(left, #21d4fd, #b721ff);*/
/*    color: -moz-linear-gradient(left, #21d4fd, #b721ff);*/
/*    color: linear-gradient(left, #21d4fd, #b721ff);*/
/*}*/

/*.btn-show-pass.active {*/
/*    color: #6a7dfe;*/
/*    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);*/
/*    color: -o-linear-gradient(left, #21d4fd, #b721ff);*/
/*    color: -moz-linear-gradient(left, #21d4fd, #b721ff);*/
/*    color: linear-gradient(left, #21d4fd, #b721ff);*/
/*}*/

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 37px 15px 33px 15px;
    }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
    position: relative;
}

.error-text {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}


.CheckBox-text {
    color: #777777;
    font-size: 14px;
    font-family: Poppins-semiBold;
    height: 45px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.input100[type="checkbox"] {
    width: 20px;
    display: flex;
    height: auto;
    margin-top: 10px;
    height: 15px;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;

    font-family: Poppins-Regular;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;

    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}

.signup-form {
    padding-top: 20px !important;
}

.login.container-login100-form-btn-google {
    margin-top: 24px;
}

.captcha-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.signup.container-login100-form-btn {
    margin-top: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /*-webkit-transition: "red 9999s ease-out, red 9999s ease-out";*/
    -webkit-transition-delay: 9999s;
}

/*google sign in button*/

.container-login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    /*z-index: 1;*/
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
}

.login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    /*background: #a64bf4;*/
    /*background: -webkit-linear-gradient(*/
    /*        to left,*/
    /*        #36494c,*/
    /*        #33a1bf,*/
    /*        #36494c,*/
    /*        #33a1bf*/
    /*);*/
    /*background: -o-linear-gradient(to left, #36494c, #33a1bf, #36494c, #33a1bf);*/
    /*background: -moz-linear-gradient(to left, #36494c, #33a1bf, #36494c, #33a1bf);*/
    /*background: linear-gradient(to left, #36494c, #33a1bf, #36494c, #33a1bf);*/
    background: #3fa9f5;
    background: -webkit-linear-gradient(
            to left,
            #4f5784,
            #3fa9f5,
            #4f5784,
            #3fa9f5
    );
    background: -o-linear-gradient(to left, #4f5784, #3fa9f5, #4f5784, #3fa9f5);
    background: -moz-linear-gradient(to left, #4f5784, #3fa9f5, #4f5784, #3fa9f5);
    background: linear-gradient(to left, #4f5784, #3fa9f5, #4f5784, #3fa9f5);

    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn-google {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    /*text-transform: uppercase;*/
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    gap: 8px;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
}

.test {
    width: 100%;
}

.googleImg {
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
}

.signUpSelectBox {
    font-size: 0.875rem;
    margin-top: 8px;
}

.signUpSelectBox div:nth-of-type(2) div {
    max-height: 150px;
    overflow-y: auto;
}

.signUpSelectBox div:nth-of-type(1) {
    border-radius: 8px;
}

.signUpSelectBoxContainer {
    margin-top: 24px;
}

.signUpSelectBoxText {
    font-family: Poppins-Medium;
    color: #faf8f8;
    font-size: 0.75rem;
}

.acceptTermsWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.acceptTermsWrapper > div {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.acceptTermsWrapper input {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.acceptTermsText {
    font-family: Poppins-Medium;
    color: #faf8f8;
    font-size: 0.85rem;
}

.acceptTermsText span {
    color: #81c7fb;
    cursor: pointer;
    text-decoration: underline;
}

.aws-logo {
    width: 100%;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
}

.aws-logo img {
    width: 100%;
    max-width: 120px;
    height: 100%;
}

.resend-button {
    /*background-color: #528ab2 !important;*/
    background-color: transparent !important;
    /*color: white !important;*/
    margin: .5rem auto 0 !important;
    display: flex !important;
    border-radius: 25px !important;
    padding: .3rem 1.5rem !important;
    width: 177px !important;
    font-family: Poppins-Regular !important;
    font-size: 13px !important;
    /* color: #aed0d3; */
    color: #40a9ff !important;
    /*margin-left: 5px;*/
}

.resend-button:disabled {
    opacity: .5;
    cursor: not-allowed;
}

@media (min-width: 900px) and (max-width: 1100px) {
    .content {
        width: 95%;
    }
}

@media (max-width: 900px) {
    .texts {
        display: none;
    }
}

@media (max-width: 550px) {
    .content {
        width: 95%;
    }
}
