.container {
    /*max-width: 1200px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*margin: 0 auto;*/
    /*padding: 1rem 0;*/
    /*gap: 10px;*/
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    color: #414144 !important;
    font-weight: 600;
    margin-bottom: 1rem;
    display: block;
}

.backBtn {
    /*width: 30px;*/
    /*height: 30px;*/
    /*border-radius: 100%;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*border: 1px solid lightgray;*/
    /*color: #636e72;*/
    /*box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
    /*cursor: pointer;*/
    /*transition: .3s all;*/
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s all;
}

.backBtn:hover {
    /*color: #2d3436;*/
    transform: scale(1.1);
}

.title {
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 800;
    color: #414144;
}

.loader {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 300px !important;
    gap: 15px !important;
}

.header {
    margin: 3rem auto 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.plotImg {
    width: 450px;
}

.panel {
    background-color: white !important;
    margin-bottom: .5rem;
}

.panelHeader {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.panelInfo {
    display: flex;
    gap: .3rem;
    flex-wrap: wrap;
}


.panelInfo li {
    display: flex;
    align-items: center;
    gap: .3rem;
}

.coinIcon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.coinName {
    color: #0C4CFC;
    font-weight: 500;
}

.content {
    display: flex;
    gap: 15px;
}

.moreBtn {
    border: 1px solid #0C4CFC;
    background-color: #0C4CFC;
    color: white;
    border-radius: .3rem;
    padding: .3rem 1rem;
    cursor: pointer;
    display: block;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    transition: .3s all;
}

.moreBtn:hover {
    background-color: white;
    color: #0C4CFC;
}

.emptyDataMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 4rem auto;
}

.emptyDataMessage p {
    width: 70%;
    text-align: center;
}

