.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    justify-content: space-around;
    padding: 1rem;
}

.sliderBox {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    /*margin-bottom: 1rem;*/
}

.suggestions {
    /*display: grid;*/
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    margin: 1rem 0;
    /*grid-template-columns: repeat(3, 1fr);*/
}

.active {
    background-color: #0984e3 !important;
    color: white !important;
}

.suggestionBtn {
    display: flex !important;
    flex-direction: column !important;
    /*gap: 5px;*/
    justify-content: center !important;
    align-items: center !important;
    padding: .3rem 1rem !important;
    height: auto !important;
    flex: 1 !important;
}

.submitBtn {
    border: none !important;
    background-color: #2b5fec !important;
    color: white !important;
    align-self: center !important;
    margin: 0 auto !important;
    padding: .5rem 1.5rem !important;
    height: auto !important;
}