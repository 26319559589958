.list {
    /*margin-bottom: 0;*/
    border: 1px solid #ecf0f1;
    border-radius: .3rem;
    /*padding: 1rem .5rem;*/
    height: fit-content;
}

.list li:first-child {
    background-color: #E0F4FF;
    padding: .4rem 1rem !important;
    font-size: 15px;
    font-weight: 600;
}

.list li:nth-child(2) {
    margin-top: .5rem;
}

.list li {
    padding: .2rem 1rem;
    display: flex;
    align-items: center;
    gap: .3rem;
    flex-wrap: wrap;
}

.list li:last-child {
    margin-bottom: .5rem;
}

.title {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: .2rem;
    /*font-size: 15px;*/
}
