.tabs {
    margin-top: 1rem;
    height: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: .05rem;
}

.tab {
    padding: .3rem .5rem !important;
    flex: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #d3d3d3 !important;
    font-family: Poppins-Medium !important;
    transition: .3s all ease-in-out !important;
    cursor: pointer;
    line-height: unset !important;
}

.tab:hover, .activeTab {
    background-color: #0C4CFC;
    color: white !important;
    border-color: #0C4CFC;
}

.tab:first-child {
    border-radius: .5rem 0 0 .5rem;
}

.tab:nth-child(2) {
    border-radius: 0 .5rem .5rem 0;
}

.badge sup {
    background-color: red;
    border-radius: 0;
    font-size: 11px;
    height: 18px;
    line-height: 18px;
}