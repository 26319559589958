.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    /*background-color: #c5dcfa;*/
    padding: .3rem 1.5rem;
    border-radius: .3rem;
    /*border-bottom: 2px solid #1b4aef;*/
    width: fit-content;
    margin: 0 auto;
}

.title {
    display: flex;
    align-items: center;
    gap: .2rem;
    font-family: Poppins-Medium;
}

.container span:nth-child(2) {
    font-weight: 500;
}