.container {
    /*transform: translateY(-5px);*/
    transition: .3s all;
    overflow-y: scroll;
    /*box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 15px, rgba(0, 0, 0, 0.05) 0px 5px 10px;*/
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /*background-color: #f6fbfd;*/
    font-size: 14px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    bottom: 12%;
    left: 100%;
    z-index: 148;
    width: 220px;
    height: 220px;
}

/*.container li:first-child {*/
/*    align-self: flex-end;*/
/*    !*padding: 8px 0;*!*/
/*    !*width: 20px;*!*/
/*    !*height: 25px;*!*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
/*    padding: .3rem;*/
/*    height: 25px;*/
/*    margin-top: .3rem;*/
/*    margin-right: 1rem;*/
/*    cursor: pointer;*/
/*    border-bottom: none !important;*/
/*    background-color: white;*/
/*    border-radius: 50%;*/
/*    color: #414144;*/
/*}*/

.container li {
    padding: 15px 35px 15px 20px;
    color: #414144;
    /*font-weight: 600;*/
    position: relative;
    cursor: pointer;
    transition: .3s all;
}

.container li a {
    color: #414144;
    font-family: Poppins-Medium;
}

.container li:hover {
    color: black;
}

.container li:hover .itemIcon {
    animation: shake 0.2s;
    animation-iteration-count: 1;
}

.container li:hover a {
    color: black;
}


.container li:not(:last-child) {
    border-bottom: 1px solid #eaeded;
}

.active::before {
    content: "";
    position: absolute;
    top: 0px !important;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: #2781d8;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 1000;
}

.active a {
    color: #0C4CFC !important;
}

.itemIcon {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
}

.beta::after {
    position: absolute;
    top: 10%;
    right: 10%;
    content: "Beta";
    color: white;
    font-size: 10px;
    padding: 0 .5rem;
    border-radius: 1rem;
    background-color: #4b82e6;
}

/*@keyframes shake {*/
/*    0% {*/
/*        transform: rotate(0);*/
/*    }*/

/*    30% {*/
/*        transform: rotate(-20deg);*/
/*    }*/

/*    60% {*/
/*        transform: rotate(0);*/
/*    }*/

/*    100% {*/
/*        transform: rotate(20deg);*/
/*    }*/
/*}*/

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(0);
    }
    75% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@media (max-height: 832px) {
    .container {
        bottom: 0;
    }
}