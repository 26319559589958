@import "../../variables"
@import "../../mixins"
.faqWrapper
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px

.collapse
  width: 100%
  max-width: 900px
  @include fontNormal
  font-size: 1.35rem !important
  background-color: transparent !important

  > div
    border-bottom: 1px solid #0C4CFC !important

    svg
      color: #0C4CFC !important

    p
      font-size: 16px

      a
        font-size: 16px

      span
        @include fontNormal
        font-size: 14px
        color: #0C4CFC
        margin-right: 4px

      > p
        &:nth-of-type(1)

          padding-top: 24px
        padding-left: 36px

.list
  list-style-type: disc
  margin-left: 1.5rem

  > li
    list-style-type: disc

.coverImg
  min-height: 100px

@media (max-width: 650px)
  .coverImg img
    width: 85%

@media (max-width: 480px)
  .coverImg:global(.lazy-load-image-background)
    display: none !important