@import "../variables"
@import "../mixins"
.apiContainer
  width: 100%
  margin: 36px 0
  padding: 16px
  border-radius: 8px
  box-shadow: 0 5px 5px rgba(0,0,0,0.05),0 -5px 5px rgba(0,0,0,0.05),5px 0 5px rgba(0,0,0,0.05),-5px 0 5px rgba(0,0,0,0.05)
  display: flex
  flex-direction: column
  >p
    &:nth-of-type(1)
      @include fontNormal
    &:nth-of-type(2)
      @include fontNormal
      font-size: 0.875rem
  >div
    &:nth-of-type(1)
      width: 100%
      display: flex
      flex-direction: column
      padding: 16px
      border-radius: 8px
      border: 1px solid #d8d8d8
      gap: 24px
      margin-top: 16px
      box-shadow: 0 5px 5px rgba(0,0,0,0.05),0 -5px 5px rgba(0,0,0,0.05),5px 0 5px rgba(0,0,0,0.05),-5px 0 5px rgba(0,0,0,0.05)

      >div
        display: flex
        gap: 16px
        position: relative
        width: 100%
        >span
          &:first-child
            @include fontNormal
          &:nth-of-type(2)
            @include fontNormal
            font-size: 0.875rem
            color: $Mortar
    &:nth-of-type(2)
      width: 100%
      max-width: 256px
      height: 48px
      margin-top: 24px
      button
        width: 100%
        max-width: 256px
        height: 100%
        background-color: $BermudaGray !important
        @include fontNormal
        color: $white

        @include fullFlex

.copyBtn
  position: absolute !important
  right: 0
  top: 0
