.container {
    display: flex;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
}

.container h4 {
    margin-bottom: 0;
}

.tag, .label {
    font-size: 14px;
    color: #666666;
    padding: .1rem .5rem;
    border-radius: 1rem;
}

.label {
    background-color: #aad4ff;
}

.tag {
    background-color: #ffeaa7;
}

.tooltip {
    display: none;
    background-color: #aad4ff;
    width: 30px;
    border-radius: .5rem;
    color: black;
    cursor: pointer;
}

@media (max-width: 540px) {
    .container h4 {
        font-size: 15px;
    }

    .tag, .label {
        display: none;
    }

    .tooltip {
        display: inline-block;
    }

    .name {
        font-size: 14px;
    }
}
