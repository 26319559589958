.wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.videoController {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 20px;
    font-weight: 700;
    color: white;
}

.videoController svg {
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.videoController svg:hover {
    opacity: .8;
}
