.welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.logoContainer {
    position: relative;
    transform: translateY(-7rem);
}

.logo {
    width: 103px;
    height: 46px;
    /*border-radius: 50%;*/
    overflow: hidden;
}

.logo img {
    width: 100%;
    height: 100%;
}

.loader {
    position: absolute;
    top: -61px;
    left: -26px;
    width: 160px;
    height: 160px;
    border: 3px solid #3e9dd6;
    border-radius: 50%;
    animation: spin 10s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        border-bottom: solid 3px transparent;
        border-top: solid 3px transparent;
    }
    50% {
        transform: rotate(1800deg);
        border: 3px solid white;
        border-left: solid 3px transparent;
        border-right: solid 3px transparent;
    }
    100% {
        transform: rotate(0deg);
        border-bottom: solid 3px transparent;
        border-top: solid 3px transparent;
    }
}

.welcomeText {
    margin-top: 5rem;
    text-align: center
}

.welcomeText p {
    font-size: 24px; /* Adjust the font size of the text */
    font-weight: 600;
}