.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
}

.input {
    width: 100% !important;
    border-radius: .4rem !important;
}

.sliderBox {
    display: flex;
    gap: 15px;
}

.errorMessage {
    color: #e74c3c;
    font-size: 12px;
    grid-column: span 2;
    margin-bottom: 0;
}