.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
    gap: 24px;
}

.container > div:first-child {
    width: 35%;
}

.container > div:nth-child(2) {
    width: 65%;
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
    }

    .container > div:first-child {
        width: 60%;
    }

    .container > div:nth-child(2) {
        width: 100%;
    }
}