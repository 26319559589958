.container {
    display: flex;
    margin: 1rem 0 0;
    padding: 0;
    align-items: center;
    border-bottom: 2px solid #0C4CFC;
    gap: .5rem;
}

.tabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 1rem;
    background-color: #e3f7fd;
    width: 300px;
    cursor: pointer;
    transition: .3s all;
    font-size: 16px;
    border-radius: .3rem .3rem 0 0;
    gap: .2rem;
}

.tabItem:hover {
    background-color: #0C4CFC;
    color: white;
}

.activeTab {
    background-color: #0C4CFC;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 480px) {
    .tabItem {
        font-size: 15px;
        height: 60px;
        text-align: center;
    }

    .tabItem svg {
        display: none;
    }

    .showOnDesktop {
        display: none;
    }

    /*.tabItem svg {*/
    /*    display: none;*/
    /*}*/
}

