.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backBtn {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.3s all;
}

.backBtn:hover {
    transform: scale(1.1);
}

.createBtn {
    margin-left: auto;
    align-self: flex-end;
    background-color: #0C4CFC !important;
    color: white !important;
    border-radius: .4rem !important;
    border: 1px solid #0C4CFC !important;
}

.createBtn:hover {
    background-color: transparent !important;
    color: #0C4CFC !important;
}

@media (max-width: 570px) {
    .container {
        display: flex;
        /*gap: 15px;*/
    }
}