.btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: .2rem !important;
}

.cancelBtn {
    background-color: #FF4D4F !important;
    border-color: #FF4D4F !important;
    color: white !important;
}

.cancelBtn.disabled:hover {
    background-color: #FF4D4F !important;
    border-color: #FF4D4F !important;
    color: white !important;
}

.cancelBtn:hover, .cancelBtn:focus, .cancelBtn:active {
    background-color: white !important;
    color: #FF4D4F !important;
}

.renewBtn {
    background-color: #52C41A !important;
    border-color: #52C41A !important;
    color: white !important;
}

.renewBtn.disabled:hover {
    background-color: #52C41A !important;
    border-color: #52C41A !important;
    color: white !important;
}

.renewBtn:hover, .renewBtn:focus, .renewBtn:active {
    background-color: white !important;
    color: #52C41A !important;
}

.btn.disabled {
    opacity: .5 !important;
    cursor: not-allowed !important;
}