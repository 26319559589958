.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: .2rem;
    transition: .3s all ease-in-out;
    text-decoration: underline;
    color: #0C4CFC;
    margin-bottom: 1rem;
    font-family: Poppins-Regular, serif;
    width: fit-content;
}

.container:hover {
    opacity: .8;
    text-decoration: none;
}

.attentionIcon {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.text {
    width: 200px;
    margin-bottom: .5rem !important;
}

.loader {
    display: flex;
    height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.generateBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0C4CFC;
    padding: .4rem 1rem;
    width: fit-content;
    margin: 1.5rem auto 1rem;
    border-radius: .5rem;
    color: #fff;
    transition: .3s all ease-in-out;
    border: 1px solid #0C4CFC;
    cursor: pointer;
    font-family: Poppins-Regular, serif;
}

.generateBtn:hover {
    background-color: white;
    color: #0C4CFC;
}


/*----------MODAL-----------*/
.alertContent {
    padding: 1rem;
}

.alertTitle {
    display: flex;
    align-items: flex-start;
    gap: .2rem;
    margin-bottom: 2rem;
}

.alertContent :global(.ant-qrcode) {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}