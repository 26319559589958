@import "../../variables"
@import "../../mixins"
.documents
  width: 100%
  padding: 16px

.buyPlanBtn
  background-color: #0C4CFC !important
  color: white !important
  border-color: #0C4CFC !important
  width: 150px !important

  &:hover
    background-color: white !important
    color: #0C4CFC !important

  &:focus
    background-color: white !important
    color: #0C4CFC !important

  &:active
    background-color: white !important
    color: #0C4CFC !important
