.container {
    margin: 1.5rem 0 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 2px solid #0c4cfc;
    border-top: none;
    border-radius: 0 0 0.4rem 0.4rem;
}

.title {
    font-weight: 600;
}

.portfolioList,
.exchanges {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0;
}

.portfolioItem,
.exchange {
    width: 120px;
    background-color: rgba(187, 221, 244, 0.68);
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    gap: 0.2rem;
    position: relative;
    border: 2px solid transparent;
}

.portfolioItem:hover,
.exchange:hover,
.active {
    /*background-color: #0C4CFC;*/
    /*color: white;*/
    border-color: #0c4cfc;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.exchange {
    position: relative;
    cursor: unset !important;
    width: 180px !important;
    border-color: #0c4cfc;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.exchange::after {
    position: absolute;
    top: -2px;
    right: -2px;
    /*top: 0;*/
    /*right: 0;*/
    content: "Virtual";
    background-color: #add8e6;
    border-top-right-radius: 0.3rem;
    font-size: 10px;
    padding: 0 0.3rem;
}

.exchangeImg {
    width: 25px;
    height: 25px;
}

.disable {
    opacity: 0.7;
    cursor: not-allowed;
}

.disable:hover {
    /*background-color: rgba(187, 221, 244, 0.68) !important;*/
    border-color: transparent !important;
    box-shadow: unset !important;
    color: unset !important;
}

.badge {
    position: absolute;
    top: -2px;
    right: -2px;
    /*background-color: #011e53;*/
    background-color: #add8e6;
    padding: 0 0.2rem;
    font-size: 10px;
    border-radius: 0 0.3rem 0 0;
}

.waiting {
    opacity: 0.7;
    cursor: progress;
}

.waiting:hover {
    border-color: transparent !important;
}
