.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: .5rem 0;
}

.alertMessage {
    text-align: center;
    color: #e74c3c;
    margin-bottom: 0;
}

.alertMessage svg {
    margin-right: .3rem;
}

.currentBudget {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.currentBudget p, .currentBudget h4 {
    margin-bottom: 0;
}

.submitBtn {
    display: flex;
    justify-content: center;
}

.submitBtn button {
    background-color: #2b5fec !important;
    font-size: 13px;
    border: none !important;
}