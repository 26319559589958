.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container p {
    margin-top: 1rem;
}

.btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.btn {
    width: 90px !important;
}

.submitBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
}