.container {
    /*flex: 1 0 100%;*/
    position: relative;
}

.resetIcon {
    position: absolute;
    right: 2%;
    top: 26%;
    cursor: pointer;
    color: #8d8c8c;
    transition: .3s all ease-in-out;
}

.resetIcon:hover {
    color: #535252;
}