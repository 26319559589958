.container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icon {
  width: 100px;
}

.title {
  text-align: center;
}

.btns {
  display: flex;
  gap: 20px;
  margin-top: 1.5rem;
}

.btns span {
  width: 130px;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: 0.3s all;
  padding: 0.4rem 1rem;
  text-align: center;
}

.yesBtn {
  color: white;
  background-color: #2b5fec;
  font-weight: 600;
  border: 2px solid transparent;
}

.yesBtn:hover {
  background-color: #0940d6;
}

.noBtn {
  border: 2px solid lightgray;
}

.noBtn:hover {
  border-color: #2b5fec;
}
