.header {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}


.backBtn {
    color: #636e72;
    line-height: unset !important;
}

.backBtn:hover, .backBtn:active, .backBtn:focus {
    color: #2d3436;
}


.title {
    margin-bottom: .5rem !important;
    text-align: center;
}

.description {
    font-size: 13px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 0;
}

.referredUsersList {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.addMoreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    background-color: #c5dcfa;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #1b4aef;
    cursor: pointer;
    transition: .3s all ease-in-out;
    margin-bottom: 1rem;
}

.addMoreBtn:hover {
    background-color: #95c1fb;
}

.addMoreBtnIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 100%;
    background-color: #1b4aef;
}

.addMoreBtnText {
    font-family: Poppins-Medium;
    font-size: 15px;
}

.summaryBox {
    border: 1px solid #1b4aef;
    padding: 1rem;
    border-radius: .3rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info span {
    font-family: Poppins-Medium;
}

.infoTitle {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.infoTitle svg {
    color: #1b4aef;
}

.referralCodes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.referBox {
    display: flex;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
}

.referBox p svg {
    color: #1b4aef;
    font-size: 16px;
}

.referBox span {
    font-family: Poppins-Medium;
    display: flex;
    align-items: center;
    gap: .2rem;
}

.referBox p {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .2rem;
    margin-bottom: 0;
}