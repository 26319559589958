/*.selectedFeatures {*/
/*    margin: 1rem 0;*/
/*}*/

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.title h3 {
    font-size: 1.2rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    margin-bottom: 16px;
}

