.container {
    display: flex;
    /* width: 420px; */
    gap: 24px;
    grid-column: span 2;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05),
    0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05),
    -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
    background-color: #b7d5fb;
}

.info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 15px;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.performanceSkeleton {
    /* min-width: 420px */
    width: 100%;
}

.performanceSkeleton div {
    width: 100%;
}

.performanceSkeleton div span {
    /* min-width: 420px; */
    width: 100% !important;
    min-height: 440px;
    border-radius: 8px;
}

.moreBtn {
    align-self: flex-end;
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: flex;
    align-items: center;
}

.detailsContainer {
    margin: auto 0;
}

.link {
    align-self: flex-start;
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: inline-block;
    margin-top: auto;
}

.link:hover {
    color: rgba(13, 100, 161, 0.85);
}

.btns, .linkBtns {
    margin-top: auto;
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkBtns {
    justify-content: flex-start;
    gap: 1rem;
}

@media (max-width: 1200px) {
    .container {
        grid-column: span 1;
    }

    .info {
        display: flex;
        flex-direction: column;
    }

    .detailsContainer {
        display: none;
    }
}

/*700*/

@media (min-width: 1200px) {
    .moreBtn {
        display: none;
    }
}
