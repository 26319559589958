.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.links {
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
}

.homeBtn {
  color: #0c4cfc;
  border-bottom: 1px solid #0c4cfc;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
}

.planBtn {
  background-color: #0c4cfc;
  color: white;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  transition: 0.3s all;
}

.planBtn:hover {
  color: white;
  background-color: #0c3fce;
}

@media (max-width: 530px) {
  .container img {
    width: 90% !important;
  }

  .container h2 {
    font-size: 18px !important;
  }
  .container p {
    text-align: center;
    margin: 1rem;
  }

  .links {
    width: 90%;
  }
}
