.container {
    padding: 1rem;
    position: relative;
}

.content {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: progress;
    background-color: rgba(255, 255, 255, 0.8);
    flex-direction: column;
    gap: .5rem;
}

.loaderText {
    color: #0C4CFC;
    font-family: Poppins-Regular, serif;
    text-align: center;
}

.durationBox {
    border-left: .1rem solid grey;
    padding-left: 1rem;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.disabled :global(.ant-radio-wrapper ), .disabled :global(.ant-radio), .disabled :global(.ant-radio-input) {
    cursor: not-allowed;
}

.options, .radioGroup {
    display: block !important;
    margin-top: 1rem !important;
}

.option, .radio {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
    font-family: Poppins-Regular, serif;
}

.option span {
    font-family: Poppins-Regular, serif;
}

.infoIcon {
    color: green;
    margin-left: 8px;
}

.radio {
    position: relative;
}

.discount {
    position: absolute;
    top: 0;
    left: 90px;
    font-size: 11px;
    background-color: red;
    color: white;
    padding: 0 .5rem;
    border-radius: 1rem;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.totalPrice {
    margin-top: 1.5rem;
    border-top: .1rem solid gray;
    padding-top: 1rem;
}

.submitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.submitBtn :global(.ant-btn) {
    border-color: #0C4CFC;
    background-color: #0C4CFC;
    color: #fff;
    padding: 1rem 1.5rem;
}

.submitBtn :global(.ant-btn):not(:disabled):hover, .submitBtn :global(.ant-btn):not(:disabled):focus, .submitBtn :global(.ant-btn):not(:disabled):active {
    background-color: white;
    color: #0C4CFC;
}

.submitBtn :global(.ant-btn):disabled {
    opacity: .8;
}

@media (max-width: 610px) {
    .content {
        flex-direction: column;
    }

    .durationBox {
        border-left: none;
        border-top: .1rem solid gray;
        padding-left: 0;
        padding-top: 1rem;
        width: 100%;
    }
}

@media (max-width: 480px) {

    .container {
        padding: 0 1rem;
    }

    .content {
        gap: .5rem;
    }

    .options, .radioGroup {
        display: flex !important;
        flex-wrap: wrap;
        margin-top: .5rem !important;
    }

    /*.radioGroup {*/
    /*    margin-top: .5rem !important;*/
    /*}*/
    .option, .radio {
        margin-bottom: .3rem;
    }

    /*.radioGroup {*/
    /*    display: grid !important;*/
    /*    grid-template-columns: 1fr 1fr 1fr;*/
    /*}*/
    .discount {
        top: -45%;
        left: 65px;
        font-size: 10px;
    }

    .infoIcon {
        margin-left: 2px;
    }

    .durationBox {
        padding-top: .5rem;
    }

    .totalPrice {
        margin-top: .5rem;
        padding-top: .5rem;
    }

    .loader {
        justify-content: flex-start;
        padding-top: 1rem;
    }
}