.title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
    margin-bottom: 1rem;
}

.title h3 {
    margin-bottom: 0;
}

.activationMethods {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 1.5rem auto;
}

.methodBtn {
    /*width: 160px;*/
    background-color: #c5dcfa;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: .2rem;
    padding: .5rem 1rem;
    border-radius: .3rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
    font-family: Poppins-Medium;
}

.methodBtn:hover, .selected {
    color: white;
    background-color: #0C4CFC;
}

.activationSuggestion > h4 {
    display: flex;
    align-items: baseline;
    gap: .2rem;
}

.nextBtn {
    cursor: pointer;
    background-color: #0C4CFC;
    border: 1px solid #0C4CFC;
    color: white;
    border-radius: .3rem;
    padding: .3rem 1rem;
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    transition: .3s all ease-in-out;
}

.nextBtn:hover {
    background-color: white;
    color: #0C4CFC;
}

.backBtn {
    cursor: pointer;
    transition: .3s all ease-in-out;
    color: #636e72;
    font-size: 25px;
    align-self: flex-start;
}

.backBtn:hover {
    color: black;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
}

.referralOption {
    margin-bottom: 0;
    /*display: flex;*/
    /*align-items: baseline;*/
    /*gap: .2rem;*/
}

.referLink {
    color: #0C4CFC;
    text-decoration: underline;
    cursor: pointer;
    transition: .3s all;
}

.referLink:hover {
    text-decoration: unset;
    opacity: .8;
}