.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    margin-right: 1.5rem;
}

.assetDisplay {
    justify-content: flex-start;
}

.assetDisplay > div:first-child img:first-child {
    width: 25px !important;
    height: 25px !important;
}

.assetDisplay > div:first-child img:nth-child(2) {
    width: 20px !important;
    height: 20px !important;
}

.refreshBtn {
    cursor: pointer;
    margin-left: auto;
    display: flex;
    /*background-color: #0C4CFC;*/
    background-color: transparent;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    /*color: #fff;*/
    border-radius: .3rem;
    /*border: 1px solid #0C4CFC;*/
    color: #4575fc;
    transition: .3s all ease-in-out;
}

.refreshBtn:hover {
    /*background-color: white;*/
    /*color: #0C4CFC;*/
    background-color: rgba(223, 230, 253, 0.4);
    color: #0C4CFC;
}

.box {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    margin-bottom: .5rem;
}

.box h4 {
    margin-bottom: 0;
}

.selectBox {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.text {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    margin-bottom: 0;
    font-size: 12px;
}

.backtestOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /*margin-top: 1rem;*/
}

.backtestBtn {
    display: flex;
    background-color: #d2e0fb;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.backtestBtn:hover,
.active {
    background-color: #0c4cfc;
    color: white;
}

.handlers {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 1rem;
    gap: 0.5rem;
}

.handlersOption {
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.defaultBtn {
    border-color: #d9d9d9;
}

.defaultBtn:hover {
    color: #4096ff;
    border-color: #4096ff;
}

.submitBtn {
    background-color: #1677ff;
    color: white;
}

.submitBtn:hover {
    opacity: 0.8;
}

.result {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    cursor: pointer;
    background-color: #ecf0f1;
    border-radius: 1rem;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    font-size: 12px;
    transition: 0.3s all ease-in-out;
}

.backBtn:hover {
    opacity: 0.8;
}

.tabs {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    border-bottom: 2px solid #0c4cfc;
    margin-bottom: 0;
}

.tabs li {
    padding: 0.2rem 1rem;
    background-color: #d2e0fb;
    border-radius: 0.4rem 0.4rem 0 0;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    width: 80px;
    display: flex;
    justify-content: center;
}

.tabs li:hover,
.activeTab {
    background-color: #0c4cfc !important;
    color: white;
}

.content {
    min-height: 150px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.infoList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.infoItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: Poppins-Medium, sans-serif;
}

.infoTitle {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}


.infoItem > span:nth-child(2) {
    color: #535c68;
}