.methods {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1rem !important;
  flex-wrap: wrap !important;
  margin: 2rem auto 0;
}

.method > span:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  transition: 0.3s all ease-in-out;
  font-family: Poppins-Medium;
}

.method:hover > span:nth-child(2) {
  color: #0c4cfc;
}

.submitBtn {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.submitBtn :global(.ant-btn) {
  background-color: #0c4cfc;
  color: white;
  border-color: #0c4cfc;
  padding: 0.5rem 1.5rem;
}

.submitBtn :global(.ant-btn):not(:disabled):hover,
.submitBtn :global(.ant-btn):not(:disabled):focus,
.submitBtn :global(.ant-btn):not(:disabled):active {
  background-color: white;
  color: #0c4cfc;
}
