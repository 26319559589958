.inputContainer {
    display: flex;
    align-items: center;
    /* width: 300px; */
    width: 85%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    background-color: white !important;
}

.icon {
    margin-right: 10px;
    transform: rotate(-180deg);
    margin-top: -5px;
}

.title {
    transition: 0.3s;
    position: absolute;
    left: 10%;
    padding: 0.2rem;
    background-color: white;
}

/* .input:focus + .title {
  transform: translateY(-20px);
  font-size: 12px;
  color: #007bff;
} */
.focused .title {
    transform: translateY(-20px);
    font-size: 12px;
    color: #007bff;
}

.up .title {
    transform: translateY(-20px);
    font-size: 12px;
    color: #007bff;
}

.clipboardIcon {
    cursor: pointer;
    align-self: flex-end;
    margin-left: auto;
}

.input {
    width: 90%
}

@media (max-width: 756px) {
    .inputContainer {
        width: 100%;
    }
}
