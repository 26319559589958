.container {
    /*margin: 1rem 0;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.title {
    font-size: 1.2rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    align-self: flex-start;
}

.referralCode {
    display: flex;
    align-items: center;
    margin: 1rem auto;
    justify-content: center;
    font-size: 1.2rem;
}

.referralCode p {
    margin-bottom: 0;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.code {
    cursor: pointer;
}

.container h4:not(:first-child) {
    font-family: Poppins-Medium;
    font-weight: 400;
    color: #2b303a;
    font-size: 1.25rem;
    margin: 1.5rem 0 1.5rem;
}

.table th {
    background-color: rgba(150, 171, 234, 0.25) !important;
    font-size: 1rem !important;
    color: #4a69bd !important;
}

.rewardsTable {
    font-family: Poppins-Medium;
}

.unclaimed {
    /* background-color: rgba(173, 216, 230, 0.705); */
    /*font-weight: bold;*/
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent h4 {
    font-size: 1.3rem;
    margin: 2rem auto;
}

.modalBtn {
    background-color: #114ff8;
    padding: 0.3rem 1.2rem;
    border-radius: 0.5rem;
    outline: none;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 1rem;
}

.referredUsersList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.addMoreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    background-color: #c5dcfa;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #1b4aef;
    cursor: pointer;
    transition: .3s all ease-in-out;
    margin-top: 1rem;
    width: 300px;
}

.addMoreBtn:hover {
    background-color: #95c1fb;
}

.summaryBox {
    border: 1px solid #1b4aef;
    padding: 1rem;
    border-radius: .3rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

@media (max-width: 800px) {
    .referredUsersList {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .container div:first-child p {
        width: 95% !important;
        text-align: center;
    }
}
