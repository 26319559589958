@import "../../variables"
@import "../../mixins"
.titles
  p
    @include fontRegular
    font-size: .875rem
    color: $Mortar
  h2
    @include fontRegular
    font-size: 1.5rem
    margin-bottom: 18px
    margin-top: 20px
  hr
    border: 1px solid #edecec
    margin-bottom: 16px
  >span
    @include fontRegular
    font-size: .875rem
    padding-left: 36px

.codes
  margin-bottom: 16px !important
  border-radius: 8px !important
