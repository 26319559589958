.buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1.5rem;
}

.buttons span:not(:last-child) {
    margin-right: 1rem;
}

.button {
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1.4rem;
    width: 90px;
    height: 40px;
    /* border: 2px solid lightgray; */
    transition: 0.4s all;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 15px;
}

.button:hover {
    background-color: #2b5fec;
    color: white;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepContainer {
    display: flex;
    flex-direction: column;
}

.btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backBtn {
    cursor: pointer;
    border: 1px solid gray;
    padding: .3rem 1.2rem;
    border-radius: 0.4rem;
    transition: .3s all;
    /*width: 25px;*/
    /*height: 25px;*/
    /*border-radius: 100%;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.backBtn:hover {
    background-color: gray;
    color: white;
}

.submitButton {
    align-self: flex-end;
    background-color: #0c4cfc;
    color: white;
    padding: 0.3rem 1rem;
    cursor: pointer;
    border-radius: 0.4rem;
    font-weight: bold;
    transition: .3s all;
    border: 1px solid #0C4CFC;
}

.submitButton:hover {
    background-color: transparent;
    color: #0C4CFC;
}