.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.title {
    font-size: 16px;
}

.content ul {
    margin-top: 2rem;
}

.feature {
    font-size: 16px;
}

.feature:not(:last-child) {
    margin-bottom: 1rem;
}

.feature a {
    color: #0c4cfc;
    transition: .4s all;
}

.feature a:hover img {
    animation: shake 0.2s;
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: rotate(0);
    }

    30% {
        transform: rotate(-20deg);
    }

    60% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(20deg);
    }


}


.featureName {
    margin-left: .5rem;
    color: #0c4cfc;
    transition: .4s all;
}