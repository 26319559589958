.container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.title {
    margin-bottom: .2rem;
    font-size: .9em;
}

.icon {
    width: 28px;
    height: 28px;
}

.description {
    margin-bottom: 0;
    font-size: 13px;
}

.insights {
    position: relative;
}

.insights::after {
    position: absolute;
    top: -10%;
    right: -15%;
    content: "Beta";
    color: white;
    font-size: 10px;
    padding: 0 .5rem;
    border-radius: 1rem;
    background-color: #4b82e6;
    line-height: 18px !important;
}