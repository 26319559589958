.container {
    padding: 1rem 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid #0c359e;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.infoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoBox > span > svg {
    color: #0C359E;
}

.infoBox span {
    font-family: Poppins-Medium;
}

.title {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.information {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.increaseBtn, .inviteFriends {
    background-color: #c5dcfa;
    color: #0C4CFC !important;
    border: 1px solid #0C4CFC;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s all ease-in-out;
    border-radius: .3rem;
}

.increaseBtn:hover, .inviteFriends :hover {
    background-color: white;
    color: #0C4CFC !important;
}

.active {
    color: #00B050;
}

.notActive {
    color: #FF0000;
}

span.inviteFriends:hover {
    background-color: white;
}

.referralBtn {
    background-color: #0C359E;
    padding: .4rem 1rem;
    width: 120px;
    color: white;
    border-radius: .3rem;
    margin: 0 auto;
    border: 1px solid #0C359E;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
}

.referralBtn:hover {
    background-color: white;
    color: #0C359E;
}

