.container {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    min-height: 100vh;
    position: relative;
}

.riskIcon {
    font-size: 17px;
}

.riskBtn:first-child {
    color: #ff0000 !important;
    /* border-color: #ff0000 !important; */
}

.riskBtn:nth-child(2) {
    color: #ffa500 !important;
}

.riskBtn:nth-child(3) {
    color: #02a82b !important;
}

.riskBtn:nth-child(4) {
    color: #00bfff !important;
}

.riskBtn:nth-child(5) {
    color: #022da1 !important;
}

.head,
.result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepImg {
    width: 300px !important;
}

.result {
    margin: 1rem 0;
}

.features {
    font-size: 18px !important;
}

.features li:not(:last-child) {
    margin-bottom: 0.5rem;
}

.submitButton {
    width: 300px;
    text-align: center;
    background-color: #0c4cfc;
    border-radius: 1.5rem;
    font-size: 17px;
    font-weight: 600;
    border: none;
    color: white;
    transition: 0.5s all;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.submitButton:hover {
    background-color: #0940d6;
}

.head img {
    width: 400px;
}

.questionBox {
    /* width: 70%; */
    margin: 1rem auto;
    /* margin-bottom: 2rem; */
}

.content p {
    color: black;
    font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
}

.forceIcon {
    color: red;
    transform: translateY(-5px);
    display: inline-block;
    margin-left: 0.1rem;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    flex-wrap: wrap;
    gap: 20px;
    margin: 2rem 0;
}

.bigBtns {
    flex-direction: column;
}

.bigBtns .button {
    width: 100% !important;
}

.button {
    padding: 1rem;
    width: 200px;
    height: 80px;
    border-radius: 0.5rem;
    border: 2px solid lightgray;
    transition: 0.4s all;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button:hover {
    border-color: #2b5fec;
}

.button.active {
    border-color: #2b5fec;
}

.steps {
    /* align-self: flex-end; */
    width: 70%;
    text-align: center;
    margin-top: 1rem;
}

.step {
    padding: 0.5rem;
    font-size: 16px;
    font-weight: 600;
    color: rgb(133, 132, 132);
    transition: 0.4s all;
}

.step.active {
    color: #2b5fec;
    font-size: 20px;
}

.stepContainer {
    display: flex;
    flex-direction: column;
}

.nextBtns {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 10px;
}

.nextBtn {
    align-self: flex-end;
    font-weight: 600;
    cursor: pointer;
    background-color: #0940d6;
    padding: 0.3rem 1rem;
    border-radius: 0.4rem;
    color: white;
    transition: 0.4s all;
}

.nextBtn:hover {
    background-color: #0530a8;
}

.handlers {
    display: flex;
    justify-content: space-between;
}

.backBtn {
    color: #0940d6;
    font-weight: 600;
    cursor: pointer;
    align-self: flex-start;
}

.label {
    color: #666;
    font-size: 15px;
    margin-left: 0.5rem;
}

.textContainer p {
    font-size: 16px !important;
}

.logo img {
    width: 150px;
    position: absolute;
    top: 5%;
    left: 5%;
}

.skipAll {
    color: #666;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid gray;
    padding-bottom: 0.1rem;
    transition: 0.3s all;
}

.skipAll:hover {
    color: #313131;
}

.skipModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.skipModal p {
    text-align: center;
}

.skipModalBtns {
    display: flex;
    gap: 15px;
}

.skipModalBtns span {
    border-radius: 0.3rem;
    padding: 0.5rem;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all;
    width: 135px;
}

.skipModalBtns span:first-child {
    background-color: #2d4df8;
    color: white;
    font-weight: bold;
}

.skipModalBtns span:first-child:hover {
    background-color: #1032f7;
}

.skipModalBtns span:last-child {
    border: 2px solid lightgray;
}

.skipModalBtns span:last-child:hover {
    border-color: rgb(180, 179, 179);
}

:global(.lazy-load-image-background) {
    display: flex !important;
    justify-content: center !important;
}

.icon {
    display: inline-block !important;
}


@media (min-width: 630px) and (max-width: 1200px) {
    .logo img {
        width: 100px;
    }
}

@media (max-width: 630px) {
    .logo img {
        position: inherit !important;
        margin: 1rem 0;
        top: 0;
        left: 0;
        width: 110px;
    }

    /* .skipAll {
      top: 1%;
      right: 0;
    } */
    .content p {
        text-align: center;
        font-size: 16px !important;
        margin: 0 1rem;
    }

    /*.head img {*/
    /*    width: 75%;*/
    /*}*/
    .head p {
        font-size: 14px !important;
    }

    .buttons {
        width: 80%;
        gap: 10px;
        margin: 2rem auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .bigBtns {
        display: flex !important;
        flex-direction: column !important;
    }

    .button {
        font-size: 14px;
        width: auto;
        height: auto;
    }

    .features {
        font-size: 16px !important;
    }

    .result p {
        font-size: 16px !important;
        margin-bottom: 0.5rem !important;
    }
}

@media (max-width: 700px) {
    .nextBtn {
        margin-right: 2rem;
    }

    .backBtn {
        margin-left: 2rem;
    }
}

@media (min-width: 630px) {
    .logo {
        position: absolute;
        top: 5%;
        left: 5%;
    }

    .container {
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .stepImg {
        display: none;
    }
}

@media (max-width: 400px) {
    .buttons {
        width: 90% !important;
    }

    .button {
        padding: 0.8rem;
    }
}
