.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.container :global(.ant-input-number-group-wrapper) {
    max-width: 350px;
    align-self: center;
}

.input > div > div:nth-child(2) {
    padding: .5rem;
    font-size: 15px;
    border-color: #0C359E;
}

.input > div > div:nth-child(1),
.input > div > div:nth-child(3) {
    background-color: transparent;
    color: #0C359E;
    border-color: #0C359E;
}


.input > div > div:nth-child(1) {
    border-radius: .5rem 0 0 .5rem;
}

.input > div > div:nth-child(3) {
    border-radius: 0 .5rem .5rem 0;
}

.minimumText {
    font-size: 12px;
    margin-bottom: 0;
    align-self: center;
}

.minimumText span {
    color: black;
    font-family: Poppins-Medium;
    font-weight: 500;
}
