.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.title {
  text-align: center;
}

.confirmBtn {
  border: none !important;
  text-align: center !important;
  transition: 0.3s all !important;
  background-color: #2d4df8 !important;
  color: white !important;
  font-weight: bold !important;
  margin-top: 1rem;
}

.confirmBtn:hover {
  background-color: #1032f7 !important;
}

.codeInput {
  border: 2px solid lightgray !important;
  border-radius: 0.3rem;
  padding: 0.5rem 0.8rem;
  text-align: center;
}

.codeInput:focus {
  border: 2px solid rgb(155, 153, 153) !important;
}
