.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.text h3 {
    text-align: center;
    margin-bottom: 0;
}

.submitBtn {
    background-color: #0C4CFC !important;
    color: white !important;
    border-color: #0C4CFC !important;
    align-self: center;
    width: 120px;
}

.submitBtn:hover, .submitBtn:active, .submitBtn:focus {
    background-color: white !important;
    color: #0C4CFC !important;
}

.btn {
    display: flex;
    justify-content: center;
    margin: 1.5rem auto;
}