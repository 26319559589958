.userBox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    background-color: #c5dcfa;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #1b4aef;
}

.profileImage {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.profileImage img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.userInfo {
    width: 80%;
}

.userInfo h3 {
    margin-bottom: 0;
}

.userInfo p {
    font-size: 13px;
    margin-bottom: 0;
}

.earnedBox {
    font-family: Poppins-Medium;
    display: flex;
    gap: .5rem;
    align-items: center;
    /*flex-wrap: wrap;*/
}

.title {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.title svg {
    color: #1b4aef;
}

.earnedValue {
    color: rgba(0, 0, 0, 0.85);
    font-family: Poppins-Medium;
}

@media (max-width: 480px) {
    .earnedBox {
        flex-wrap: nowrap;
    }

    .userBox {
        flex-direction: column;
    }

    .profileImage {
        align-self: center;
    }

    .userInfo {
        width: 100%;
    }
}