/*.options {*/
/*    display: flex !important;*/
/*    flex-direction: column !important;*/
/*    gap: 1rem !important;*/
/*    padding: 1rem !important;*/
/*}*/

.options {
    display: flex !important;
    gap: .5rem !important;
    /*padding: 1rem !important;*/
    margin: 1rem 0;
    flex-wrap: wrap;
}

.option {
    font-family: Poppins-Medium;
    background-color: #c5dcfa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
    cursor: pointer;
    font-size: 13px;
    width: fit-content;
}

.option:hover, .selected {
    background-color: #0c3fce;
    color: white;
}

.loader {
    display: flex !important;
    align-items: center !important;
    gap: .2rem !important;
}

.actionBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: .5rem;
}

.actionBtn {
    align-self: flex-end;
    margin-left: auto;
    background-color: #0c3fce !important;
    color: white !important;
    border-color: #0c3fce !important;
    border-radius: .3rem !important;
}

.actionBtn:hover, .actionBtn:focus, .actionBtn:active {
    background-color: white !important;
    color: #0c3fce !important;
}

.actionBtn:disabled:hover {
    background-color: #0c3fce !important;
    color: white !important;
}

.actionBtn:disabled {
    opacity: .8 !important;
}

.errorMessage {
    display: flex;
    align-items: center;
    gap: .2rem;
    color: #e84118;
}

.errorMessage svg {
    font-size: 18px;
}