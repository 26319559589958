.select {
    width: 250px;
}

.select :global(.ant-select-selector) {
    border-radius: .3rem !important;
}

.exchange {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 0;
    padding: .2rem;
}

.symbolIcon {
    width: 25px;
    height: 25px;
    border-radius: 100%;
}