.title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title h3 {
    font-size: 20px;
}

.title p {
    text-align: center;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 2rem;
}

.exchangeList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin: 2rem auto;
}

.exchangeItem {
    border: 2px lightgray solid;
    border-radius: 0.4rem;
    padding: 0.8rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: 0.3s all;
    position: relative;
}

.connected {
    border-color: lightgreen !important;
    /*cursor: not-allowed !important;*/
}

.connected::before {
    content: "\2705";
    position: absolute;
    left: -4%;
    font-size: 25px;
    color: green;
    top: -15%;
}

.expired {
    border-color: #e74c3c !important;
}

.ipRequired {
    border-color: #f1c40f !important;
}

.certificatedBadge {
    position: absolute;
    color: white;
    background-color: #ffb000;
    font-size: 18px;
    width: 20px;
    height: 30px;
    top: -2px;
    right: -2px;
    border-top-right-radius: 0.3rem;
    z-index: 1;
}

.expired::before {
    content: "Expired";
    position: absolute;
    left: 1%;
    font-size: 14px;
    color: #c0392b;
    top: -3%;
}

.ipRequired::before {
    content: "IP Reassign Required";
    font-size: 11px;
    color: #f1c40f;
    top: -2%;
    left: 2%;
    font-weight: 600;
}

.exchangeItem:hover {
    border-color: #2b5fec;
}

.exchangeImg {
    object-fit: none;
    object-position: 25%;
    height: 20px;
    width: 40px;
}

.exchangeName {
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center;
}

.pagination .ant-pagination-item {
    border: none;
}

.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBox {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: .4rem;
    width: 250px;
    padding: .3rem .5rem;
    box-sizing: border-box;
    margin: auto;
}

.searchBox input {
    width: 95%;
}

.emptyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 2;
}

@media (min-width: 510px) {
    .exchangeList {
        grid-template-columns: repeat(3, 1fr);
    }

    .emptyBox {
        grid-column: span 3;
    }
}
