@import "../../variables"
@import "../../mixins"

.mainHeader
  display: flex
  justify-content: space-between
  align-items: center

.title
  @include fontNormal
  font-size: 1.25rem

.referralDetails
  display: flex
  align-items: center

  p
    margin-bottom: 0 !important
    font-size: 1rem
    color: #2B303A
    display: flex
    align-items: center
    gap: .2rem

  div
    margin-left: .5rem
    color: #666666
    cursor: pointer

.desc
  @include fontNormal
  margin-top: 36px
  color: $Mortar

  > span
    color: #0C4CFC

.plans
  width: 100%
  display: flex
  justify-content: center
  gap: 36px
  padding: 16px
  flex-wrap: wrap

  .planWrapper
    width: 100%
    max-width: 400px
    padding: 16px
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
    border-radius: 8px

    .header
      width: 100%
      height: 150px

      > h2
        @include fontNormal
        font-size: 1.25rem
        display: flex
        gap: 12px

        > svg
          font-size: 1.5rem
          color: $Mortar

      > p
        @include fontNormal
        color: $Mortar
        font-size: 12px

      > div
        @include fontRegular
        font-size: 1rem
        color: $Mortar
        line-height: 24px
        margin-top: 36px

        > span
          @include fontNormal
          font-size: 1.25rem
          position: relative

        .off
          position: relative
          transform: translateY(-100px)

          &::before
            content: ""
            position: absolute
            top: 50%
            left: 0
            width: 55%
            height: 2px
            background-color: red
            transform: rotate(-10deg)

          &::after
            content: "13.99"
            @include fontNormal
            font-size: 1.25rem
            top: 80%
            left: 0
            position: absolute


    button
      width: 100%
      height: 40px
      @include fullFlex
      background-color: #0C4CFC
      @include fontRegular
      font-size: 1.025rem
      color: white

      a
        @include fontRegular
        color: white
        font-size: 1.025rem


    > p
      @include fontNormal
      font-size: .75rem
      margin-top: 16px
      margin-bottom: 0

    > ul
      padding: 8px 8px 8px 24px

      > li
        margin-top: 16px
        display: flex
        gap: 8px
        align-items: center
        @include fontRegular
        //font-size: 0.875rem
        > span
          &:nth-of-type(1)
            color: #0C4CFC

            > svg
              font-size: 1.125rem

          &:nth-of-type(2)
            > var
              color: #0C4CFC
              font-style: normal
              font-weight: 600

          &:nth-of-type(3)
            @include fontRegular
            font-size: 10px
            background-color: #1b4aef
            color: white
            border-radius: 8px
            line-height: 10px
            padding: 4px 8px
            //transform: translateY(-5px)
            @include fullFlex

.gold
  color: goldenrod !important

.redLine
  > span
    > svg
      color: red

    &:nth-of-type(2)
      text-decoration: line-through
  color: red !important

.ripple
  width: 100%
  height: 40px
  display: flex
  align-items: flex-end
  border-radius: 8px
  margin-top: 16px

.grayBtn
  background-color: $Mortar !important

.label
  display: flex
  align-items: center
  justify-content: center

.labelTitle
  margin-left: .5rem
  font-size: 18px

.tabLink
  color: rgba(0, 0, 0, 0.85)

.activeTab
  color: #2781d8

.planTimePicker
  border: 1px solid lightgray
  flex: 1
  text-align: center
  padding: .5rem 1rem
  cursor: pointer
  font-weight: 600
  transition: .3s all

  &:nth-of-type(1)
    border-radius: .4rem 0 0 .4rem

  &:nth-of-type(2)
    border-radius: 0 .4rem .4rem 0
    position: relative

    &::after
      content: "30% off"
      position: absolute
      top: -10px
      right: -5px
      width: 60px
      height: 20px
      background-color: red
      color: white
      font-size: 12px
      line-height: 20px
      text-align: center
      z-index: 1

.rowTitle
  margin-top: 4.5rem !important

@media (max-width: 680px)
  .rowTitle
    margin-top: 16px !important
  .plans
    gap: 24px
    flex-direction: column
    padding: 0
    align-items: center

    .planWrapper
      max-width: 100%

@media (min-width: 680px) and (max-width: 820px)
  .ripple
    margin-top: 2rem
  .plans
    gap: 24px
    padding: 8px
    flex-wrap: wrap

    .planWrapper
      max-width: 300px

      .header
        height: 120px

        > h2
          font-size: 1rem

          > svg
            font-size: 1.25rem

        > p
          font-size: 10px

        > div
          font-size: 0.875rem

          > span
            font-size: 1rem

      button
        height: 36px
        font-size: 0.875rem

      > p
        font-size: 0.625rem

      > ul
        padding: 4px 4px 4px 16px

        > li
          margin-top: 12px
          font-size: 0.75rem

          > span:nth-of-type(1)
            > svg
              font-size: 1rem

@media (min-width: 821px) and (max-width: 1024px)
  .plans
    gap: 24px
    padding: 12px

    .planWrapper
      max-width: 300px

      .header
        height: 140px

        > h2
          font-size: 1.125rem

          > svg
            font-size: 1.375rem

        > p
          font-size: 11px

        > div
          font-size: 0.875rem

          > span
            font-size: 1.125rem

      button
        height: 36px

        font-size: 0.875rem

      > p
        font-size: 0.625rem

      > ul
        padding: 6px 6px 6px 20px

        > li
          margin-top: 14px

          font-size: 0.8125rem

          > span:nth-of-type(1)
            > svg
              font-size: 1.125rem

@media (min-width: 481px) and (max-width: 768px)
  .labelTitle
    font-size: 16px

@media (max-width: 480px)
  .labelTitle
    font-size: 14px

@media (max-width: 420px)
  .label
    svg
      font-size: 15px !important

@media (max-width: 390px)
  .label
    svg
      display: none        