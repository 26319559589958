.container {
    margin-bottom: 2rem;
}

.inputBox {
    display: flex;
    align-items: baseline;
}

.codeInput {
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid lightgray;
    min-width: 300px;
    transition: 0.3s all;
    margin-right: 1rem;
}

.codeInput:focus {
    border: 1px solid rgb(155, 153, 153) !important;
}

.invalidMessage {
    color: red;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
}

.invalidInput {
    border: 1px solid red !important;
}

.inputBox button {
    background-color: #0c4cfc;
    color: white;
    font-weight: bold;
    padding: 0.3rem 1.2rem;
    border-radius: 0.3rem;
}

.messageBox {
    height: 14px;
    margin: .5rem auto;
}

.resultMessage {
    /*margin: 1rem auto;*/
    text-align: center;
    font-size: 1rem;
}

.loadingMessage {
    color: #0c4cfc;
}

.successMessage {
    color: green;
}

.errorMessage {
    color: red;
}

@media (min-width: 360px) and (max-width: 550px) {
    .codeInput {
        min-width: 220px;
        width: 220px;
    }
}

@media (max-width: 360px) {
    .codeInput {
        min-width: 170px;
        width: 170px;
    }
}
