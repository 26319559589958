.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05),
    0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05),
    -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
    min-height: 400px;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
    background-color: #b7d5fb;
}