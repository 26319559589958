/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
    font-family: Poppins-Regular;
    src: url("../asset/Fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: Poppins-Medium;
    src: url("../asset/Fonts/Poppins-Medium.ttf");
}

@font-face {
    font-family: Poppins-Bold;
    src: url("../asset/Fonts/Poppins-Bold.ttf");
}

.ant-rate-star:not(.ant-rate-star-full) svg {
    color: rgba(0, 0, 0, 0.3); /* Adjust the opacity or color as needed */
}

.ant-table-column-title {
    z-index: 0 !important;
}

.apexcharts-toolbar {
    z-index: unset !important;
}

.expanded-table .ant-table-expand-icon-col {
    width: 30px !important;
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    text-decoration: none;
}

a:focus {
    outline: none !important;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0;
}

ul,
li {
    margin: 0;
    list-style-type: none;
}

/*---------------------------------------------*/
input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #adadad;
}

input:-moz-placeholder {
    color: #adadad;
}

input::-moz-placeholder {
    color: #adadad;
}

input:-ms-input-placeholder {
    color: #adadad;
}

textarea::-webkit-input-placeholder {
    color: #adadad;
}

textarea:-moz-placeholder {
    color: #adadad;
}

textarea::-moz-placeholder {
    color: #adadad;
}

textarea:-ms-input-placeholder {
    color: #adadad;
}

/*---------------------------------------------*/
button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

/*-----------ANTD----------*/
/*.ant-tabs-nav {*/
/*    width: 100% !important;*/
/*    white-space: nowrap !important;*/
/*}*/

/*.ant-tabs-ink-bar {*/
/*    transition: none !important; !* Disable transition to prevent shaking *!*/
/*}*/

/*-----------ANTD----------*/

/*//////////////////////////////////////////////////////////////////
[ utility ]*/
.txt1 {
    font-family: Poppins-Regular;
    font-size: 13px;
    color: #c2bdbd;
    line-height: 1.5;
}

.txt2 {
    font-family: Poppins-Regular;
    font-size: 13px;
    /* color: #aed0d3; */
    color: #40a9ff;
    line-height: 1.5;
    text-decoration: none;
    margin-left: 5px;
    transition: 0.3s all;
}

.txt2:hover {
    color: #256fac;
}

:root {
    --toastify-text-color-light: #3a3939 !important;
}

.rct-icon-uncheck,
.rct-icon-check {
    font-size: 20px;
    color: #0c4cfc;
}

.rct-title {
    font-family: Poppins-Regular;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b4b5ba;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #767373;
}

#react-sidebar .sidebar-backdrop.show {
    opacity: 0 !important;
    visibility: hidden !important;
}

.flex-align-center > span > span {
    font-family: Poppins-Regular !important;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 120px;
    justify-content: space-between;
    gap: 8px;
}

.flex-align-center > span > span svg {
    font-size: 1rem;
}

.flex-align-center > span > span > span:first-child {
    max-width: 80px;
}

.flex-align-center > span > span > span:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-align-center span:hover {
    color: white !important;
}

.sidebar-main-content ul li {
    padding: 16px !important;
    /*transition: background-color .2s linear !important;*/
    border-radius: 8px !important;
}

.sidebar-main-content ul li:hover {
    background-color: #748cd8 !important;
    color: white !important;
    fill: white !important;
}

.pro-item-content {
    color: #414144 !important;
}

.pro-item-content:hover {
    color: black !important;
}

.pro-sidebar {
    z-index: 1000 !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0 !important;
}

.pro-inner-item {
    box-shadow: 0 5px 5px rgb(0 0 0 / 5%) !important;
}

.sidebar-main-content ul li:hover svg g {
    fill: white !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
    position: relative !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding-bottom: 16px !important;
}

.pro-sidebar.collapsed
.pro-menu
> ul
> .pro-menu-item.pro-sub-menu
> .pro-inner-list-item
> .popper-inner {
    background-color: #ecf5fb !important;
    padding-left: 0 !important;
}

.pro-sidebar
.pro-menu
.pro-menu-item.pro-sub-menu
.pro-inner-list-item
.pro-inner-item {
    padding-left: 36px !important;
    padding-top: 16px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    overflow: visible !important;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
    display: inline-block !important;
}

.ant-modal-header {
    border-radius: 16px !important;
}

.ant-modal-content {
    border-radius: 16px !important;
}

.ant-btn {
    font-family: Poppins-Medium !important;
    border-radius: 8px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    height: 48px !important;
    display: flex !important;
    align-items: center !important;
}

.ant-select-selection-item p {
    margin-bottom: 0 !important;
}

.ant-modal-footer {
    border-top: 0 !important;
}

.ant-table-cell {
    font-size: 0.875rem !important;
}

.ant-modal-mask {
    z-index: 1000 !important;
}

.ant-modal-wrap {
    z-index: 1000 !important;
}

iframe {
    /* display: none !important; */
}

.section-seprator {
    display: none;
    margin: 0;
}

g:has(> g[stroke="#3cabff"]) {
    display: none !important;
    visibility: hidden !important;
}

.ant-dropdown-trigger {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 8px !important;
}

.ant-picker-separator {
    color: rgb(43, 48, 58) !important;
    font-size: 18px !important;
}

/*.ant-dropdown-menu {*/
/*    max-height: 300px;*/
/*    overflow-y: scroll;*/
/*}*/

.ant-tabs-card .ant-tabs-tab-active {
    border-color: #1890ff !important;
    border-bottom-color: white !important;
}

.ant-table-cell {
    /*width: 100% !important;*/
    /*max-width: 150px !important;*/
}

/*exchangeIpModal*/

.ipBox {
    border: 1px solid lightgray;
    border-radius: 0.4rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}

.ipBox .ip {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    /*display: flex;*/
    /*align-items: center;*/
    /*gap: .2rem;*/
}

.copyIcon {
    cursor: pointer;
    align-self: flex-end;
    margin-left: auto;
}

.ipBox .ip:hover {
    color: #1a1919;
}

/*.bot-table .ant-table-body table colgroup col:first-child, .bot-table .ant-table-body table colgroup col:nth-child(2) {*/
/*    width: 7px !important;*/
/*}*/

/*.ant-table-body table colgroup col.ant-table-expand-icon-col,*/

.bot-table .ant-table-expand-icon-col,
.ant-table-body table colgroup col.ant-table-selection-col {
    width: 7px !important;
}

/*@media only screen and (min-width: 768px) {*/
/*    .bot-table .ant-table-body table colgroup .ant-table-expand-icon-col {*/
/*        display: none;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .bot-table .ant-table-body table colgroup .ant-table-expand-icon-col {*/
/*        display: none;*/
/*    }*/
/*}*/

/*.ant-table-expand-icon-col, .ant-table-selection-col {*/
/*    width: 7px !important;*/
/*}*/

/*@media (min-width: 768px) {*/
/*    .bot-table .ant-table-body table colgroup col:first-child {*/
/*        width: 7px !important;*/
/*    }*/
/*}*/

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    /*height: 48px !important;*/
    /*border-radius: 8px !important;*/
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
.ant-select-selector
input {
    /*height: 48px !important;*/
}

.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: #2d2323;
}

.cVioWK {
    color: rgba(13, 15, 16, 0.18) !important;
}

.ant-empty-normal {
    margin: 0 !important;
}

.ant-radio-group .ant-badge-count {
    z-index: 2 !important;
}

.ant-progress-steps-item {
    background: #ffffff !important;
}

.ant-progress-steps-item-active {
    background-color: #27ae60 !important;
}

.ant-radio-checked .ant-radio-inner {
    /*border-color: #194c8e !important;*/
    border-color: #0c4cfc !important;
    border-width: 2px;
    width: 16px;
    height: 16px;
}

.ant-radio-checked .ant-radio-inner:after {
    /*background-color: #327f7f;*/
    background-color: #0c4cfc;
    transform: scale(0.5) !important;
}

.ant-radio:hover .ant-radio-inner {
    /*border-color: #194c8e !important;*/
    border-color: #0c4cfc !important;
    /*width: 20px;*/
    /*height: 20px;*/
}

.ant-tabs-tab {
    width: 100% !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
}

.ant-tabs-tab-btn {
    width: 100%;
    text-align: center;
}

#react-sidebar .sidebar-main {
    width: 230px !important;
    height: calc(100vh - 60px) !important;
}

.ant-badge {
    display: block !important;
}

.show-more-less-clickable {
    font-size: 0.875rem;
    color: #7b7a7a;
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
    /* padding: 24px 16px !important; */
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.ant-collapse-item:last-child > .ant-collapse-content {
    border: 0 !important;
}

.ant-empty-description {
    color: white !important;
}

.am5exporting-menu.am5exporting-valign-bottom,
.am5exporting-icon.am5exporting-valign-bottom,
.am5exporting-list.am5exporting-align-bottom {
    bottom: -14px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 40px !important;
}

.am5exporting .am5exporting-icon {
    background-color: #8cb9e6 !important;
}

.am5exporting-list.am5exporting-align-left {
    margin-left: 140px !important;
}

.am5exporting-menu.am5exporting-align-left,
.am5exporting-icon.am5exporting-align-left,
.am5exporting-list.am5exporting-align-left {
    left: 20px !important;
}

.swal-text {
    font-family: Poppins-Medium;
    font-size: 1.125rem;
}

/*.ant-slider-rail {*/
/*    height: 20px !important;*/
/*    border-radius: 16px !important;*/
/*}*/

.ant-slider-track {
    background-color: #0c4cfc !important;
}

/*.ant-slider-track {*/
/*    background-color: #0c4cfc !important;*/
/*    height: 20px !important;*/
/*    border-radius: 16px !important;*/
/*}*/

/*.ant-slider-handle {*/
/*    width: 36px !important;*/
/*    height: 36px !important;*/
/*    margin-top: -8px !important;*/
/*}*/

.ant-radio-inner {
    border-color: #0c4cfc !important;
}

.pro-sidebar
.pro-menu
.pro-menu-item
> .pro-inner-item
> .pro-icon-wrapper
.pro-icon {
    width: 36px !important;
    line-height: 36px !important;
    display: flex;
    justify-content: center;
}

.img-crop-modal .img-crop-control-zoom button {
    margin-top: 16px;
    margin-right: 16px;
}

.img-crop-modal .img-crop-control-rotate button {
    margin-top: 16px;
    margin-right: 16px;
}

.ant-upload-list-item-info {
    display: none;
}

.ant-input::placeholder {
    color: #787b82 !important;
}

.ant-input-group-addon:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

/* .ant-dropdown {
  min-width: 250px !important;
  left: unset !important;
  right: 0 !important;
} */

.ant-dropdown-menu-item-icon {
    font-size: 18px !important;
    color: #0c4cfc !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05) !important;
    padding: 8px !important;
    padding-top: 16px !important;
}

.ant-radio-checked .ant-radio-inner {
    background-color: transparent !important;
}

.ant-dropdown-menu-title-content {
    font-size: 16px !important;
}

.pro-sidebar-layout {
    overflow-y: unset !important;
    overflow-x: unset !important;
}
