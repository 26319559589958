.step {
    transition: .3s all;
    position: absolute;
    top: 20%;
    left: 10%;
}

.visible {
    transform: translateX(0);
    opacity: 1;
}

.hide {
    transform: translateX(-100%);
    opacity: 0;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    gap: 1.5rem;
}

.btn {
    padding: .5rem 1.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.btn:nth-child(2) {
    padding: .5rem 1.7rem !important;
    border-color: #0C4CFC !important;
    background-color: #0C4CFC !important;
}

.portfolioItem {
    width: 80px;
    border: 2px solid lightgray;
    border-radius: .3rem;
    cursor: pointer;
    transition: .3s all;
    padding: .3rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolioItem:hover {
    border-color: #0C4CFC;
}

.badge > sup {
    top: -5px !important;
    background-color: #4b82e6;
}

.disable {
    cursor: not-allowed !important;
    opacity: .5;
}

.disable:hover {
    border-color: unset !important;
}

.selectedPortfolio {
    border-color: #0C4CFC;
}

.submitBtn {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 2rem auto 0 !important;
    padding: .3rem 1.5rem !important;
    background-color: #0C4CFC !important;
    color: white !important;
    border: none !important;
}