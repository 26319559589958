.title {
    font-size: 1.25rem;
    display: flex;
    gap: 12px;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2b303a;
    margin: 0;
}

.title svg {

    font-size: 1.5rem
}

.description {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2b303a;
    color: rgba(42, 42, 42, .56);
    font-size: 12px;
}