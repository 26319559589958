@import "../variables"
@import "../mixins"
.headerContainer
  width: 100%
  height: 60px
  background-color: #ffffff
  border-bottom: 1px solid rgba(192, 192, 192, 0.19)
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05)
  padding: 16px 24px
  display: flex
  align-items: center
  justify-content: space-between
  position: sticky
  left: 0
  top: 0
  z-index: 1000

  .logo
    width: 100%
    max-width: 120px
    cursor: pointer

  .profileContainer
    @include fullFlex
    gap: 16px
    padding: 4px 8px
    border-radius: 8px
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05)
    margin-left: auto

    > span
      background: $manatee
      width: 40px
      height: 40px
      border-radius: 100%
      @include fullFlex

      > svg
        font-size: 25px
        color: $white

    a
      @include fullFlex
      gap: 8px
      @include fontNormal
      color: #2781d8

//font-size: 20px


.MenuClassName
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.07), 5px 0 5px rgba(0, 0, 0, 0.07), -5px 0 5px rgba(0, 0, 0, 0.07), 0 -5px 5px rgba(0, 0, 0, 0.07)
  border-radius: 8px !important
  width: 100% !important
  margin-top: 10px !important
  padding: 8px !important
  left: -20px
  max-height: calc(100vh - 90px)
  overflow: scroll

.help
  @include fontNormal
  font-size: 14px
  @include fullFlex
  padding: 8px
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05)
  border-radius: 8px
  background-color: #0C4CFC
  color: white
  font-weight: 700
  cursor: pointer
  margin-right: 1.5rem

  gap: 8px

  > svg
    font-size: 25px

.profileImg
  width: 40px
  height: 40px
  border-radius: 100%

@media (max-width: 768px)
  .userName
    display: none !important
  .profileContainer
    gap: .2rem !important

@media (max-width: 480px)
  .help
    span
      &:nth-of-type(1)
        display: none
  .headerContainer
    padding: 14px
  .profileContainer
    gap: 12px

    > span
      //display: none !important
      width: 30px !important
      height: 30px !important

    > a
      > svg
        width: 14px

  .profileImg
    width: 30px !important
    height: 30px !important


@media (max-width: 380px)
  .help
    span
      &:nth-of-type(2)
        display: none
            