.container {
    display: flex;
    gap: .3rem .5rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.subcategory {
    background-color: #c5dcfa;
    padding: 0 .3rem;
    border-radius: 1rem;
    font-size: 13px;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.subcategory:hover, .selected {
    background-color: #0C4CFC;
    color: white;
}

.menu {
    display: none !important;
}

@media (max-width: 768px) {
    .container {
        display: none;
    }

    .menu {
        display: flex !important;
        align-items: center !important;
        /*gap: .5rem !important;*/
        /*justify-content: space-between !important;*/
        margin-bottom: .5rem !important;
    }

    .menu > li:not(:last-child) {
        margin-right: .5rem;
    }

    :global(.ant-menu-item-selected), :global(.ant-menu-item-active) {
        background-color: #0C4CFC !important;
        color: white !important;
    }

    :global(.ant-menu-item-selected)::after, :global(.ant-menu-item-active)::after {
        border-bottom-color: #0C4CFC !important;
    }

    :global(.ant-menu-sub) {
        max-height: 265px !important;
    }
}