.mainBtn {
    background-color: #d2e0fb;
    padding: 0.3rem 1rem;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    font-size: 14px;
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
}

.mainBtn:hover {
    background-color: #0c4cfc;
    color: white;
}

.box {
    margin-bottom: 1rem;
}

.box :global(.ant-select-selector) input {
    height: 100% !important;
}

.symbolOption {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.symbolIcon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.errorMessage {
    margin-top: .5rem;
    margin-bottom: 0;
    font-size: 12px;
}

.errorMessage a {
    font-size: 12px;
    color: #0C4CFC;
}

.submitButton {
    background-color: #0c4cfc !important;
    color: white !important;
    border-color: #0c4cfc !important;
    display: flex !important;
    justify-content: end !important;
    margin-left: auto !important;
    padding: .3rem 1.5rem !important;
}

.submitButton:focus,
.submitButton:hover,
.submitButton:active {
    background-color: white !important;
    color: #0c4cfc !important;
}
