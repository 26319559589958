.login.container-login100-form-btn {
    margin-top: 24px;
}

.signup.container-login100-form-btn {
    margin-top: 30px;
}
.container-login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
}

.wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    /*z-index: 1;*/
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
}

.login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #a64bf4;
    background: -webkit-linear-gradient(to left,  #030d4f, #5386e3, #030d4f,#5386e3 );
    background: -o-linear-gradient(to left,  #030d4f, #5386e3, #030d4f,#5386e3);
    background: -moz-linear-gradient(to left, #030d4f, #5386e3, #030d4f,#5386e3);
    background: linear-gradient(to left, #030d4f, #5386e3, #030d4f,#5386e3);
    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
}
.test{
    width: 100%;
}