.container {
    padding: 1rem;
    border: 2px solid #0C4CFC;
    border-top: none;
    border-radius: 0 0 .4rem .4rem;
    margin-bottom: 1rem;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 1rem;*/
}

.exchangeListDesktopVersion, .exchangeListMobileVersion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    /*padding: 1rem;*/
    margin-bottom: 0;
}

.exchangeListMobileVersion {
    display: none;
}

.exchangeListMobileVersion li {
    height: 41px;
}

.exchangeItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    border: 2px solid transparent;
    background-color: rgba(187, 221, 244, 0.68);
    border-radius: .4rem;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.moreBtn {
    width: 100% !important;
    padding: .6rem 1rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgba(187, 221, 244, 0.68) !important;
    border-radius: .4rem !important;
    height: 100% !important;
    border: 2px solid transparent !important;
}

.moreBtn:hover, .moreBtn:active {
    /*background-color: #0C4CFC !important;*/
    /*color: white !important;*/
    border-color: #0C4CFC !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.dropdownMenuItem {
    display: flex;
    gap: .2rem;
    font-size: 14px;
}

.exchangeItem:hover {
    /*border-color: #2b5fec;*/
    /*background-color: #0C4CFC;*/
    /*color: white;*/
    border-color: #0C4CFC;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.selectedExchange {
    /*border-color: #2b5fec;*/
    /*background-color: #0C4CFC;*/
    border-color: #0C4CFC;
    /*color: white;*/
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.exchangeImg {
    width: 25px;
    height: 25px;
}

.virtual {
    position: relative;
}

.virtual::after {
    position: absolute;
    top: -2px;
    right: -2px;
    /*top: 0;*/
    /*right: 0;*/
    content: "Virtual";
    background-color: #add8e6;
    border-top-right-radius: .3rem;
    font-size: 10px;
    padding: 0 .3rem;
}

.emptyLink {
    color: #2b5fec;
    text-decoration: underline;
}

.disable {
    opacity: .6;
    cursor: progress;
}

.disable:hover {
    border-color: transparent !important;
    box-shadow: unset !important;
}

.title {
    font-size: 18px;
    font-weight: 500;
}


@media (max-width: 768px) {
    .exchangeListDesktopVersion {
        display: none;
    }

    .exchangeListMobileVersion {
        display: grid;
    }
}

@media (min-width: 650px) {
    .exchangeListDesktopVersion {
        grid-template-columns: repeat(5, 1fr) !important;
    }
}

@media (min-width: 530px) {
    .exchangeListDesktopVersion {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 468px) {
    .title {
        font-size: 16px;
    }

    .exchangeListDesktopVersion {
        padding: .5rem;
    }

    .exchangeItem {
        padding: .3rem .5rem;
        /*font-size: 12px;*/
    }

    .exchangeListMobileVersion {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 350px) {
    .exchangeListDesktopVersion {
        grid-template-columns: repeat(2, 1fr);
    }
}