.steps {
    position: relative;
    padding: 1rem 0;
}

.loader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
}

.btn {
    font-family: Poppins-Regular;
    cursor: pointer;
    transition: .3s all ease-in-out;
    border-radius: .5rem;
    padding: .2rem .8rem;
    height: 33px;
}

a.btn {
    background-color: #0C4CFC;
    color: white;
    border: 1px solid #0C4CFC;
}

a.btn:hover, a.btn:focus, a.btn:active {
    background-color: transparent;
    color: #0C4CFC;
}

span.btn {
    border: 1px solid #34495e;
    color: #34495e;
}

span.btn:hover {
    background-color: #34495e;
    color: white;
}