.container {
    background-color: #c5dcfa;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05),
    0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05),
    -5px 0 5px rgba(0, 0, 0, 0.05);
    padding: .5rem;
    border-radius: .4rem;
    font-family: Poppins-Medium;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.header {
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.headerTitle {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.icon {
    background-color: #E1E6FF;
    color: #0C4CFC;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon svg {
    width: 20px;
    height: 20px;
}

.title {
    font-weight: 600;
}

.info {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: .1rem;
    margin: auto;
}

@media (max-width: 480px) {
    .container {
        padding: .5rem 1rem;
    }
}