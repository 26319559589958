.table :global(.ant-table-body) :global(.ant-table-cell) {
    padding: 0 !important;
}

.col {
    padding: 14px;
    margin: 2px;
    border-radius: .3rem;
    transition: .3s all ease-in-out;
}

.col:hover {
    background-color: #ededed;
}

.sell {
    border: 2px solid #EF4B4B
}

.holdBuy {
    border: 2px solid #82A0D8
}

.buy {
    border: 2px solid #70AF85
}

.signalDate {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: .2rem;
    justify-content: center;
}

.coin {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.usdtLabel {
    color: #7f7f7f;
}

.coinImage {
    position: relative;
}

.symbolImage {
    width: 35px !important; /* Adjust size as needed */
    height: 35px !important;
    position: relative;
    z-index: 1;
}

.tetherImage {
    width: 30px !important; /* Adjust size as needed */
    height: 30px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 0.8; /* Optional: Adjust transparency if needed */
}

.categoryIcon {
    width: 20px;
    height: 20px;
    margin-right: .2rem;
    position: relative;
}

.categoryIcon::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: blue;
    mix-blend-mode: color;
    pointer-events: none;
}

.hideContent {
    border: 1px solid #bdc3c7;
    color: white;
    background-color: #bdc3c7;
    border-radius: .5rem;
}

.notActive {
    border: 2px solid #bdc3c7;
}

.suggestionLabel {
    position: absolute;
    right: -14px;
    top: -14px;
    padding: .1rem .7rem;
    font-size: 12px;
    color: white;
    font-family: Poppins-Regular;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 0 0 0 .3rem;
}


.SellLabel {
    background-color: #EF4B4B;
}

.BuyLabel {
    background-color: #70AF85;
}

.HoldLabel {
    background-color: #82A0D8;
}

.bar {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.bullets {
    display: flex;
    flex-direction: column;
    /*gap: .1rem;*/
    font-size: 12px;
}


.verticalBar {
    width: 14px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid #ccc;
    border-radius: 1rem;
    overflow: hidden;
}

.bullets span {
    display: flex;
    align-items: center;
}

.barSegment {
    width: 100%;
}

@media (max-width: 600px) {
    .verticalBar {
        height: 50px;
        width: 8px;
    }

    .bullets {
        font-size: 10px;
    }

    .bullets span {
        line-height: 1.1;
    }
}