.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    margin-bottom: 1.5rem;
}

.title p {
    color: #e74c3c;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.btn {
    width: 80px !important;
}

.submitBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
}