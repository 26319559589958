.portfolioTable {
    margin-top: .5rem;
}

.portfolioTable th {
    color: blue !important;
    font-size: 1rem !important;
    background-color: rgba(150, 171, 234, 0.25) !important;
}

.tableContent {
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-evenly;
    align-items: flex-start;
    gap: .2rem;
}

/*.coin {*/
/*    align-self: flex-start;*/
/*}*/

.coinIcon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: .5rem;
}

.balances {
    padding-left: .2rem;
    font-family: Poppins-Regular;
}

.balances span {
    font-weight: 600;
}

.addBtn {
    padding: .5rem;
    background-color: #547ef1;
    cursor: pointer;
    border-radius: 0.4rem;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}

.pnlInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .1rem;
}

.positive {
    color: green;
}

.negative {
    color: grey;
}

.expandIcon {
    color: #0C4CFC;
    cursor: pointer;
    transition: .3s all;
    border: 1px solid transparent;
}

.expandIcon:hover {
    color: #082a87;
    transform: scale(1.05);
}

.portfolioTable :global(.ant-table-row-expand-icon-cell), .portfolioTable :global(.ant-table-expand-icon-col) {
    width: 20px !important;
    padding: 0;
}

@media (max-width: 650px) {
    .tableContent {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}


@media (max-width: 468px) {
    .portfolioTable th, .portfolioTable td {
        font-size: 12px !important;
    }
}