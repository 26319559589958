.container {
    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    min-height: 100vh;
}

.container h3,
h4,
p {
    margin-bottom: 0;
}
