.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.btn {
    background-color: #0C4CFC;
    border: 1px solid #0C4CFC;
    color: white;
    cursor: pointer;
    transition: .3s all ease-in-out;
    border-radius: .4rem;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .4rem 1.5rem;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btn:hover {
    background-color: white;
    color: #0C4CFC;
}
