.container {
    /*margin-bottom: 1rem;*/
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.container button {
    gap: .2rem !important;
}

.navList {
    display: flex;
    margin: 0;
    align-items: center;
}

.navItem {
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    align-items: center;
}

.navItem:hover {
    color: #0c4cfc;
}

.active {
    color: #0c4cfc;
    border-bottom: 1px solid #0c4cfc;
    display: flex;
    align-items: center;
}

.button {
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
}

.separtor {
    height: 20px;
    background-color: #9da0a8;
    /* margin: 0 0.8rem; */
    padding: 0.05rem;
}

/*@media (min-width: 1200px) {*/
/*    .container {*/
/*        display: none;*/
/*    }*/
/*}*/

/*@media (max-width: 520px) {*/
/*  .container {*/
/*    flex-direction: column-reverse;*/
/*  }*/
/*  .navList {*/
/*    margin-bottom: 1rem;*/
/*  }*/
/*}*/
