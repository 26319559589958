.activationBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    margin-top: 1rem;
}

.activationBox h3 {
    text-align: center;
}

.activationLink {
    color: #0C4CFC;
    text-decoration: underline;
    transition: .3s all ease-in-out;
}

.activationLink:hover {
    text-decoration: unset;
    color: #0C4CFC;
}

.activationButton {
    color: white;
    background-color: #0C4CFC;
    padding: .3rem;
    width: 120px;
    border-radius: .4rem;
    border: 1px solid #0C4CFC;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activationButton:hover, .activationButton:focus, .activationButton:active {
    color: #0C4CFC;
    background-color: white;
}

.backBtn {
    cursor: pointer;
    transition: .3s all ease-in-out;
    color: #636e72;
    font-size: 25px;
    align-self: flex-start;
}

.backBtn:hover {
    color: black;
}