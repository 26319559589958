.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contentOptions {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 1rem;
}

/*.contentOptions li {*/
/*    padding: .3rem 1.5rem;*/
/*    width: 100px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border: 2px solid lightgray;*/
/*    border-radius: .3rem;*/
/*    font-weight: 500;*/
/*    color: #2B303A;*/
/*    transition: .3s all;*/
/*    cursor: pointer;*/
/*}*/

.contentOptions .btn, .moreBtn {
    padding: .3rem .5rem !important;
    /*width: 110px !important;*/
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
    /*border: 2px solid lightgray !important;*/
    border-width: 2px !important;
    border-radius: .3rem !important;
    font-weight: 500 !important;
    /*color: #2B303A !important;*/
    transition: .3s all !important;
    cursor: pointer !important;
}

.moreBtn {
    background-color: #1abc9c !important;
    border-color: #1abc9c !important;
    color: white !important;
}

.moreBtn:hover {
    background-color: #16a085 !important;
    border-color: #16a085 !important;
}

.refreshBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.activateBtn, .deactivateBtn {
    display: flex !important;
    align-items: center;
    /*width: 130px !important;*/
}

.activateBtn {
    background-color: #3498db !important;
    border-color: #3498db !important;
    border-radius: .3rem !important;
    color: white !important;
}

.activateBtn span, .deactivateBtn span {
    display: flex !important;
    align-items: center !important;
}

.activateBtn:hover {
    background-color: #2980b9 !important;
    border-color: #2980b9 !important;
}

.deactivateBtn {
    background-color: #bdc3c7 !important;
    border-radius: .3rem !important;
    border-color: #bdc3c7 !important;
    overflow: hidden !important;
}

.deactivateBtn:hover {
    background-color: #9da2a5 !important;
    border-color: #9da2a5 !important;
}

.requestBtn {
    color: white !important;
    background-color: #FFA500 !important;
    border-color: #FFA500 !important;
}

.requestBtn:hover {
    background-color: #ec9a02 !important;
    border-color: #ec9a02 !important;
}

.deactivate {
    opacity: .8;
    cursor: not-allowed !important;
}

.active {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.inactive {
    opacity: .8 !important;
    cursor: not-allowed !important;
}

.deleteBtn {
    background-color: #e74c3c !important;
    border-color: #e74c3c !important;
    color: white !important;
    border-radius: .3rem !important;
    display: flex !important;
    align-items: center !important;
}


.pdfBtn a {
    display: flex !important;
    align-items: center !important;
}

.pdfBtn, .pdfBtn a {
    color: white !important;
}

.menu li span, .menu li a {
    display: flex !important;
    align-items: center !important;
    font-size: 13px !important;
    gap: .3rem !important;
    font-family: Poppins-Regular !important;
}

.menu li .loading {
    color: gray !important;
    display: flex;
    align-items: center;
    gap: .3rem;
}

@media (max-width: 620px) {
    .contentOptions {
        flex-wrap: wrap;
    }
}