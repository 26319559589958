.optionBtn {
    /*background-color: #0C4CFC !important;*/
    border-color: #0C4CFC !important;
    color: #0C4CFC !important;
}

.optionBtn:hover, .optionBtn:active, .selectedOption {
    color: white !important;
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
}


/*.input {*/
/*    width: 300px !important;*/
/*}*/

.input :global(.ant-input-number-group-addon) {
    border-color: #0C4CFC !important;
    color: #0C4CFC !important;
}

.input :global(.ant-input-number) {
    border-color: #0C4CFC !important;
}

.redeem button {
    background-color: #0532ac !important;
    color: white !important;
    border-color: #0532ac !important;
}

.redeem button:hover, .redeem button:focus, .redeem button:active {
    background-color: white !important;
    color: #0532ac !important;
    border-color: #0532ac !important;
}

.paymentBtn {
}

@media (min-width: 480px) {
    .btns {
        gap: 1rem !important;
    }

    .optionBtn {
        font-size: 14px !important;
        width: 120px !important;
    }

    /*.input {*/
    /*    width: 400px !important;*/
    /*}*/
}

@media (max-width: 330px) {
    .input {
        width: 100% !important;
    }
}