.container, .messageBox {
    margin-top: 2rem;
}

.navigator {
    background-color: #0C4CFC;
    border-radius: 1rem;
    padding: .2rem 1rem;
    border: 1px solid #0C4CFC;
    color: white;
    cursor: pointer;
    margin: 0 auto;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    transition: .3s all ease-in-out;
}

.navigator:hover {
    background-color: white;
    color: #0C4CFC;
}

.container {
    /*display: grid;*/
    /*grid-template-columns: 2fr 3fr 3fr;*/
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
    border-radius: .5rem;
    background-color: #c5dcfa;
    padding: .8rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 14px;
}

.container > li {
    /*border: 1px solid #0C4CFC;*/
    /*border-radius: .5rem;*/
    /*background-color: #c5dcfa;*/
    /*padding: .8rem 1rem;*/
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
    /*font-size: 14px;*/
}

.coinBox {
    display: flex;
    /*justify-content: space-between;*/
    align-items: baseline;
    /*width: 170px;*/
}

.coinName {
    transform: translateY(-25%);
}

.images {
    position: relative;
    margin-right: 1.5rem;
    transform: translateX(20%);
}


.coin {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    position: relative;
    z-index: 1;
}

.tether {
    position: absolute;
    z-index: 0;
    width: 23px;
    height: 23px;
    top: 30%;
    left: 65%;
    opacity: .8;
}

.usdt {
    color: #666;
    font-size: 12px;
    transform: translateY(10%);
    display: inline-block;
    margin-left: .1rem;
}

.featureName {
    font-size: 14px;
    border-bottom: 1px solid #0C4CFC;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
}

.featureInfo {
    padding: .5rem;
}

.btn {
    border-radius: .3rem;
    padding: .2rem .8rem;
    cursor: pointer;
    font-size: 13px;
}

.buy {
    background-color: green;
    color: white;
}

.sell {
    background-color: red;
    color: white;
}

.hold {
    background-color: yellow;
    color: white;
}

.detailsContainer {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.labels {
    color: #34495e;
}

.tooltip {
    cursor: pointer;
    color: #0C4CFC;
}

.name {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.btn {
    cursor: pointer;
    transition: .3s all ease-in-out;
    border: 1px solid transparent;
    border-radius: .8rem;
    width: 60px;
    text-align: center;
}

.sell {
    background-color: #EF4B4B;
    border-color: #EF4B4B;
    color: white;
}

.sell:hover {
    background-color: white;
    color: #EF4B4B;
}

.buy {
    background-color: #70AF85;
    border-color: #70AF85;
    color: white;
}

.buy:hover {
    background-color: white;
    color: #70AF85;
}

.hold {
    background-color: #82A0D8;
    border-color: #82A0D8;
    color: white;
}

.hold:hover {
    background-color: white;
    color: #82A0D8;
}

.hideContent {
    border: 1px solid #bdc3c7;
    color: white;
    background-color: #bdc3c7;
    width: 50px;
}

.hideContent svg {
    height: 15px;
}