.tabLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: rgba(0, 0, 0, .85);
    font-size: 16px;
}

.tabLabel:hover {
    color: #2781d8;
}

.activeTab {
    color: #2781d8 !important;
}

@media (max-width: 600px) {
    .tabLabel {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .tabLabel svg {
        display: none;
    }
}

@media (min-width: 395px) {
    :global(.ant-tabs-nav-operations) {
        display: none !important;
    }
}