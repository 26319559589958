.backBtn {
    color: #636e72;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.backBtn:hover, .backBtn:active, .backBtn:focus {
    color: #2d3436;
}

.tabs :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: white !important;
}

.tabs :global(.ant-tabs-tab-active) {
    border-color: #0C4CFC !important;
    background-color: #0C4CFC !important;
}

.tabs :global(.ant-tabs-tab):hover {
    background-color: #0C4CFC !important;
    color: white !important;
}

.tabTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
}
