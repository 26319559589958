.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: 200px;
    padding: 1rem;
}

.handlersBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.rightBtn {
    align-self: flex-end;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 20px;
}

.integrationLink {
    color: #2b5fec;
    text-decoration: underline;
}

.title {
    font-weight: 550;
    width: fit-content;
    background-color: #EEF5FF;
    padding: 0.3rem 1.5rem;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    align-items: center;
}

.stepTitle {
    display: flex;
    align-items: center;
    gap: 5px;
}

.nextBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    width: 80px;
    color: white !important;
    transition: .3s all;
}

.nextBtn:hover {
    background-color: white !important;
    color: #0C4CFC !important;
}

.backBtn {
    width: 80px;
}

/*STEP 2*/
.options {
    /*display: grid;*/
    /*grid-template-columns: repeat(6, 1fr);*/
    /*margin: 1rem auto;*/
    /*width: 100%;*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.optionItem {
    width: 110px;
    padding: .5rem 1rem;
    border: 2px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    cursor: pointer;
    transition: .3s all;
}

.exchange {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}

.exchange img {
    width: 20px;
    height: 20px;
}

.virtual {
    position: relative;
}

.virtual::after {
    position: absolute;
    top: -2px;
    right: -2px;
    content: "Virtual";
    background-color: #add8e6;
    border-top-right-radius: .3rem;
    font-size: 10px;
    padding: 0 .3rem;
}

.selectedOption {
    border-color: #0C4CFC;
}

.stepContent {
    padding: 1rem;
}

.stepContent h4 {
    margin-bottom: 1rem;
}

.submitBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    width: 100px !important;
    transition: .3s all;
}

.submitBtn:hover {
    background-color: white !important;
    color: #0C4CFC !important;
}

.skipBtn {
    color: #0C4CFC;
    border-bottom: 1px solid #0C4CFC;
    padding: .2rem .3rem;
    cursor: pointer;
}

.cancelBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    background-color: #DDE6ED;
    color: #27374D;
    font-weight: 500;
    width: fit-content;
    padding: .3rem .7rem;
    border-radius: .3rem;
    align-self: flex-end;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .3s all;
}

.cancelBtn:hover {
    background-color: #27374D;
    color: white;
}


.botDataList {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin: 1rem 0;
}

.botDataList li {
    padding: 0 .5rem;
    color: #666666;
    display: flex;
    gap: 3px;
    align-items: center;
}

.botDataList li span {
    color: #2f3640;
}

.listStyle {
    color: #0C4CFC !important;
    font-weight: 600;
}

.riskOptions li:first-child {
    color: #ff0000 !important;
}

.riskOptions li:nth-child(2) {
    color: #ffa500 !important;
}

.riskOptions li:nth-child(3) {
    color: #02a82b !important;
}

.riskOptions li:nth-child(4) {
    color: #00bfff !important;
}

.riskOptions li:nth-child(5) {
    color: #022da1 !important;
}

/*STEP 2*/

@media (max-width: 570px) {
    .steps {
        height: 75vh;
        width: fit-content !important;
    }

    .container {
        width: 90%;
    }
}