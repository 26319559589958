.withdrawBtn {
    background-color: #0C4CFC;
    color: white;
    padding: .3rem .5rem;
    border-radius: .8rem;
    cursor: pointer;
    border: 1px solid #0C4CFC;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdrawBtn:hover {
    background-color: white;
    color: #0C4CFC;
}

.disabled {
    opacity: .8 !important;
    cursor: not-allowed !important;
}

.disabled:hover {
    background-color: #0C4CFC !important;
    color: white !important;
}

.submitBtnBox {
    display: flex;
    justify-content: flex-end;
}