.btn {
    background-color: #F46060;
    color: white;
    padding: .3rem 1rem;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .3rem;
    transition: .3s all;
    font-size: 14px !important;
}

.btn:hover {
    background-color: #EF4B4B;
}

.portfolioList {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    gap: 2rem;
}

.portfolioItem {
    width: 80px;
    border: 2px solid lightgray;
    border-radius: .3rem;
    cursor: pointer;
    transition: .3s all;
    padding: .3rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolioItem:hover {
    border-color: #0C4CFC;
}

.badge > sup {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    top: -9px !important;
    background-color: #011e53 !important;
    padding: 0.3rem 0.5rem !important;
    height: auto !important;
    font-size: 15px !important;
    line-height: 13px !important;
}

.disable {
    cursor: not-allowed !important;
    opacity: .5;
}

.disable:hover {
    border-color: unset !important;
}

.selectedPortfolio {
    border-color: #0C4CFC;
}

.submitBtn {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 2rem auto 0 !important;
    padding: .3rem 1.5rem !important;
    background-color: #0C4CFC !important;
    color: white !important;
    border: none !important;
}