.container {
    display: flex;
    align-items: center;
    gap: .3rem;
    padding: .4rem;
    border-radius: 1.5rem;
    border: 2px solid #D2E0FB;
    width: fit-content;
    height: 45px;
    background-color: #F4F6FF;
}

.btn {
    background-color: #D2E0FB;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    cursor: pointer;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn:hover, .active {
    background-color: #0C4CFC;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.title {
    font-size: 14px;
    margin-right: .3rem;
}

@media (max-width: 530px) {
    .title {
        display: none;
    }
}
