.optionsBtn {
    padding: 0;
    margin: 1.5rem auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.blueBtn {
    background-color: rgba(187, 221, 244, 0.68);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    cursor: pointer;
    transition: .3s all ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blueBtn:hover {
    color: white;
    background-color: #0C4CFC;
}

.selectedBtn {
    background-color: #0C4CFC;
    color: white;
}

/*------ RISK APPETITE -------*/

.riskBtn {
    background-color: rgba(187, 221, 244, 0.68);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    width: 180px;
    cursor: pointer;
    transition: .3s all;
    padding: 1rem .5rem;
    border-radius: .4rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.riskBtn:hover {
    background-color: #0C4CFC;
    color: white !important;
}

.riskBtn:first-child {
    color: #ff0000;
}

.riskBtn:nth-child(2) {
    color: #ffa500;
}

.riskBtn:nth-child(3) {
    color: #02a82b;
}

.riskBtn:nth-child(4) {
    color: #00bfff;
}

.riskBtn:nth-child(5) {
    color: #022da1;
}


.activeBtn {
    color: white !important;
    background-color: #0C4CFC !important;
}

/*------ RISK APPETITE -------*/


/*------ ALERT FREQUENCY -------*/


/*------ ALERT FREQUENCY -------*/


/*------ NOTIFICATION TIME -------*/
.customTimeInput {
    display: flex;
    align-items: center;
}

.customTimeInput > span {
    min-width: fit-content;
    display: inline-flex;
    align-items: center;
    /*flex: 1;*/
    background-color: rgba(187, 221, 244, 0.68);
    padding: .55rem;
    font-family: Poppins-Medium;
    font-weight: 400;
}

.customTimeInput > div {
    width: 100%;
    /*flex: 1.5;*/
    padding: .5rem 11px !important;
}

.timePickerBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1.5rem auto;
}

/*------ NOTIFICATION TIME -------*/

/*------ PORTFOLIO -------*/

.question {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    font-size: 0.875rem;
}

.balanceThreshBox {
    margin: 2rem auto;
}

.customInput {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.blueButton {
    min-width: fit-content;
    display: inline-flex;
    align-items: center;
    /*flex: 1;*/
    background-color: rgba(187, 221, 244, 0.68);
    padding: .55rem;
    font-family: Poppins-Medium;
    font-weight: 400;
}

.customInput > div {
    margin: 0 !important;
}

.customInput input {
    padding: .5rem !important;
    height: fit-content !important;
}

/*------ PORTFOLIO -------*/

@media (max-width: 680px) {
    .optionsBtn {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width: 620px) {
    .customTimeInput {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .customTimeInput > span {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .riskBtn {
        max-width: 140px;
    }

    .optionsBtn li {
        font-size: 12px !important;
        padding: .5rem !important;
        text-align: center;
    }
}