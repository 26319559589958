.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    min-height: 400px;
}

.logoContainer {
    margin: 4rem auto 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer img {
    width: 150px;
}

.errorContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    height: 300px;
    margin: auto;
}
