.card {
    width: max-content;
    margin-top: 1rem !important;
    border-radius: 12px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cardTitle {
    background-color: #c5dcfa;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin: 0 auto 1rem;
    border-radius: 0.3rem;
}

.cardTitleIcon {
    margin-right: 0.3rem;
    font-size: 18px;
}

.text {
    display: flex;
    flex-wrap: wrap;
}

.subscriptionInfo {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: 100%;
}

.actionBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: auto;
}

.text strong {
    color: #0d0d0d;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
}

.textIcon {
    margin-right: 0.3rem;
}

.spinner {
    display: flex !important;
    width: 300px !important;
    height: 300px !important;
    margin: auto !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 15px !important;
}

@media (max-width: 530px) {
    .text {
        flex-direction: column;
    }

    .card {
        width: 100%;
    }
}
