.header {
  padding: 2rem;
  background-color: #f6f7f9;
  text-align: center;
  border-bottom: 2px solid #e7e8ea;
}
.header h3 {
  font-size: 18px !important;
  width: 60%;
  margin: 0 auto;
}

.body {
  padding: 2rem 3rem;
  min-height: 392px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option {
  padding: 0.8rem 1.8rem;
  position: relative;
  background-color: #f6f7f9;
  font-size: 16px !important;
  cursor: pointer;
  transition: 0.3s all;
}

.option:hover {
  background-color: #dae3f7;
}

.option::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: #2d4df8;
}

.submitBtn {
  background-color: #2d4df8;
  padding: 0.8rem 2rem;
  display: block;
  color: white;
  font-weight: 600;
  text-align: center;
  margin-top: 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s all;
}

.submitBtn:hover,
.closeBtn:hover {
  background-color: #1032f7;
}

.disabledSubmitBtn {
  opacity: 0.5;
  cursor: progress;
}

.footer {
  padding: 2rem;
  background-color: #f6f7f9;
  text-align: center;
  border-top: 2px solid #e7e8ea;
}

.timeline {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.timelineDot {
  flex: 1;
  height: 6px;
  background-color: #e9e9e9;
  transition: 0.3s all;
}

.timelineDot:first-child {
  border-radius: 5px 0 0 5px;
}

.timelineDot:last-child {
  border-radius: 0 5px 5px 0;
}

.passed {
  background-color: #314aff;
}

.discountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.congratContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}

.discountContainer p {
  color: #0d0d0d;
  font-size: 16px;
}

.closeBtn {
  cursor: pointer;
  background-color: #2d4df8;
  border-radius: 0.4rem;
  margin-top: 2rem;
  padding: 0.5rem 1.5rem;
  color: white;
  transition: 0.3s all;
  font-weight: 600;
}

.footer p {
  color: #868a8d;
  cursor: pointer;
  padding: 0.2rem;
  transition: 0.3s all;
}

.footer p:hover {
  color: #2d4df8;
}

@media (max-width: 468px) {
  .header h3 {
    width: 90%;
  }
  .body {
    padding: 2rem;
  }
}
