.container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.container svg {
    color: #666666;
    transition: .3s all ease-in-out;
}

.container:hover svg {
    color: black;
}