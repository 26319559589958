.container {
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
}

.description {
    margin-left: .3rem;
}

:global(.ant-tooltip) {
    max-width: 320px;
}

@media (max-width: 768px) {
    .description {
        display: none;
    }
}

@media (min-height: 570px) {
    .container {
        max-height: 300px;
    }
}