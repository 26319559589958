.title {
    font-size: 1.2rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    margin-bottom: 16px;
}

.btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.paperTradingBtn {
    background-color: #FFC107 !important;
    border-radius: .3rem !important;
    border-color: #FFC107 !important;
    color: white !important;
}

.paperTradingBtn:hover, .paperTradingBtn:active {
    background-color: white !important;
    color: #FFC107 !important;
}

.options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /*justify-content: flex-end;*/
    justify-content: space-between;
    align-self: flex-end;
    margin: 0;
    gap: 1rem;
    width: 100%;
}

.tableMode {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.tableModeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 32px;
    border-radius: .3rem;
    cursor: pointer;
    transition: .3s all ease-in-out;
    background-color: rgba(150, 171, 234, 0.25);
}

.positionBtn {
    background-color: #90EE90;
}

.paperTradingBtn:hover, .positionBtn.selectedMode {
    background-color: #006400;
    color: white;
}

.orderBtn {
    background-color: #ADD8E6;
}

.orderBtn:hover, .orderBtn.selectedMode {
    background-color: #00008B;
    color: white;
}

.refreshBtn {
    padding: 0.3rem 0.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.2rem !important;
    border-radius: 0.3rem !important;
    font-weight: 500 !important;
    transition: .3s all !important;
    cursor: pointer !important;
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
    color: white !important;
}

.refreshBtn:hover, .refreshBtn:active {
    background-color: white !important;
    color: #0C4CFC !important;
}

