.container {
    display: flex;
    /*margin-bottom: 3rem;*/
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.title {
    margin-bottom: 1rem;
}

.link1,
.link2 a {
    cursor: pointer;
    color: rgb(12, 76, 252);
}

.links {
    margin-top: auto;

    display: flex;
    flex-wrap: wrap;
}

.showLink {
    align-self: flex-start;
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    font-size: 15px;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.86);
    margin-bottom: 0.5rem;
}

.showLink:not(:last-child) {
    margin-right: 1rem;
}

.showLink:hover {
    color: rgba(13, 100, 161, 0.85);
}

@media (max-width: 500px) {
    .links {
        flex-direction: column;
    }

    .showLink:not(:last-child) {
        margin-bottom: 1rem;
    }
}
