@import "../../variables"
@import "../../mixins"
.infoCards
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 12px

//> div
//  width: 100%
//  max-height: 68px
//  min-height: 68px
//  display: flex
//  align-items: center
//  justify-content: center
//  gap: 12px
//  background-color: #c5dcfa
//  padding: 0 16px
//  border-radius: 8px
//  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
//
//
//  > span
//    width: 100%
//    height: 100%
//    max-width: 50px
//    max-height: 50px
//    display: flex
//    justify-content: center
//    align-items: center
//    background-color: #E1E6FF
//    border-radius: 16px
//
//    .icon
//
//      font-size: 1.5rem
//      color: #1B4AEF
//
//      path
//        stroke: #1B4AEF
//
//  > div
//    width: 100%
//    height: 100%
//    padding: 0 8px
//    display: flex
//    align-items: center
//    justify-content: flex-start
//    gap: 14px
//    border-radius: 16px
//
//    > span
//      display: flex
//      justify-content: center
//      align-items: center
//
//      &:nth-of-type(1)
//        @include fontRegular
//        font-size: 0.875rem
//        font-weight: 600
//
//      color: #080859
//      position: relative
//
//
//      &:nth-of-type(2)
//        color: #0b125f
//        font-weight: 700
//
//    > div
//      display: flex
//      flex-direction: column

.main
  width: 100%
  display: flex
  padding-top: 36px
  gap: 24px


  .chartContainer
    width: 100%
    max-width: 90%

    > div
      &:nth-of-type(1)
        padding: 24px 8px 8px 8px
        border-radius: 8px
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)

      &:nth-of-type(2)
        padding: 24px 8px 8px 8px
        display: flex
        gap: 24px
        border-radius: 8px
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)


  .articleContainer
    display: flex
    flex-direction: column
    gap: 24px

    .article
      width: 100%
      max-width: 400px
      min-width: 350px
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
      border-radius: 12px
      padding: 30px 28px 28px 28px
      display: flex
      //min-height: 960px
      max-height: 840px
      flex-direction: column
      gap: 16px
      overflow-y: auto

      > div
        width: 100%
        display: flex
        gap: 8px
        flex-direction: column
        padding: 16px
        border-radius: 8px
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05)

        > div
          display: flex
          gap: 8px
          align-items: center

          > span
            @include fontNormal
            color: $Mortar

        > img
          width: 60%
          margin: 0 auto
          //max-height: 170px
          object-fit: cover
        //border-radius: 50%


        > span
          &:nth-of-type(1)
            @include fontNormal
            font-size: .875rem


          &:nth-of-type(2)
            @include fontRegular
            color: $Mortar

        > a
          display: flex
          align-items: center
          margin-top: 8px

          > svg
            font-size: 18px


//skeloton styles

.infoSKeloton
  width: 100%
  display: flex
  gap: 16px

  div
    width: 100%

    > span
      width: 100% !important
      min-height: 58px
      border-radius: 8px
      min-width: 100% !important

.NegativeAreaChartSkeleton
  div
    width: 100%

    > span
      width: 100% !important
      min-height: 380px
      border-radius: 8px

.lineChartSkeleton
  width: 100%
  display: flex
  gap: 16px
  margin-top: 24px

  div
    width: 100%

    > span
      width: 100% !important
      min-height: 350px
      border-radius: 8px

.articleSkeleton
  min-width: 350px

  div
    width: 100%

    > span
      min-width: 350px
      width: 100% !important
      min-height: 440px
      border-radius: 8px

.quickAccessContainer
  display: flex
  gap: 24px

.quickAccessSkeleton
  width: 100%

  div
    width: 100%

    > span
      width: 100% !important
      min-height: 440px
      border-radius: 8px


.topContent
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 30px
  width: 100%

.infoCard
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  min-height: 68px
  background-color: #c5dcfa
  padding: .3rem .5rem
  border-radius: 8px
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
  flex-wrap: wrap
  gap: 1rem

.infoBox
  display: flex
  align-items: center
  gap: .5rem
  font-family: Poppins-Medium
  flex-wrap: wrap

.infoTitle
  display: flex
  align-items: center
  gap: .2rem
  font-weight: 600

.infoIcon
  font-size: 18px
  width: 30px
  height: 25px
  background-color: #E1E6FF
  border-radius: 16px
  padding: .3rem
  color: #1B4AEF
  cursor: pointer
  transition: transform 0.1s ease-in-out

  &:hover
    animation: shake .5s ease-in-out

@keyframes shake
  0%, 100%
    transform: rotate(0deg)
  20%, 60%
    transform: rotate(-5deg)
  40%, 80%
    transform: rotate(5deg)


@media (min-width: 1000px) and (max-width: 1200px)
  .infoCards
    > div
      gap: 0px

      > div
        justify-content: space-between

//> span
//  > span
//    display: none

@media (min-width: 500px) and (max-width: 1000px)
  .infoCards
    grid-template-columns: 1fr 1fr

    > div
      > div
        justify-content: space-between

@media (min-width: 700px) and (max-width: 1200px)
  .topContent
    grid-template-columns: 1fr 1fr

@media (max-width: 700px)
  .topContent
    grid-template-columns: 1fr !important
  .desktopView
    display: none !important

@media (min-width: 700px)
  .mobileView
    display: none !important

//@media (min-width: 500px) and (max-width: 650px)
//  .infoCards
//    > div
//      gap: 0
//      grid-gap: 0
//
//      > span
//        background-color: transparent
//        width: auto


@media (max-width: 500px)
  .main
    padding-top: 25px
  .infoCards


    > div
      flex-direction: column
      min-height: 120px
      justify-content: unset
      //max-height: 120px
      gap: .5rem
      padding: 10px

      > span
        background-color: transparent
        height: 50%

        .icon
          font-size: 1.3rem

          &:nth-of-type(2)
            font-size: 1.2rem

      > div
        flex-direction: column
        padding: 0px
        gap: 0px
        justify-content: space-between

//> span
//  > span
//    display: none