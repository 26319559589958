.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 1.5rem auto;
}

.container h3, .container p {
    margin-bottom: 0;
}