.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    padding: .5rem 1rem;
}

.btns {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin: 2rem auto 1rem;
}

.btns span {
    cursor: pointer;
    transition: .3s all;
    padding: .3rem .8rem;
    border-radius: .4rem;
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    color: white;
}

.portfolioBtn {
    background-color: #1abc9c;
    border: 1px solid #1abc9c;
}

.portfolioBtn:hover {
    background-color: white;
    color: #1abc9c;
}

.settingBtn {
    background-color: #0C4CFC;
    border: 1px solid #0C4CFC;
}

.settingBtn:hover {
    background-color: white;
    color: #0C4CFC;
}

@media (max-width: 430px) {
    .btns {
        flex-direction: column;
        gap: .8rem;
    }
}

