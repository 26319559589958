.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.platformContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.infoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .3rem;
    background-color: #c5dcfa;
    border: 1px solid #1b4aef;
    padding: .5rem;
    border-radius: .4rem;
    flex-wrap: wrap;
}

.row {
    grid-column: span 2;
}

.infoBoxTitle {
    display: flex;
    align-items: center;
    gap: .2rem;
    font-size: 15px;
    font-family: Poppins-Medium;
    font-weight: 500;
}

.active, .notActive {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.active {
    color: #009432;
    font-weight: 500;
}

.notActive {
    color: #e84118;
    font-weight: 500;
}

.loading {
    color: #0C359E;
    font-weight: 500;
}

.settingsIcon {
    font-size: 20px;
    cursor: pointer;
    color: #0c3fce;
    transition: .3s all ease-in-out;
    animation: shake 0.8s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateY(-3px);
    }

    35% {
        transform: translateY(-3px) rotate(17deg);
    }

    55% {
        transform: translateY(-3px) rotate(-17deg);
    }

    65% {
        transform: translateY(-3px) rotate(17deg);
    }

    75% {
        transform: translateY(-3px) rotate(-17deg);
    }

    100% {
        transform: translateY(0) rotate(0);
    }
}

.settingsIcon:hover {
    color: #05257d;
}

@media (max-width: 370px) {
    .container {
        grid-template-columns: 1fr;
    }

    .platformContainer {
        flex-direction: column;
    }
}
