.loadingBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
}

.loadingBox h3 {
    text-align: center;
}

.errorBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    /*height: 150px;*/
}

.actionBtns {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
}

.actionBtns button {
    border-radius: .4rem !important;
}

.sellBtn {
    background-color: #FE0000 !important;
    color: white !important;
    border-color: #FE0000 !important;
}

.sellBtn:hover {
    background-color: white !important;
    color: #FE0000 !important;
    border-color: #FE0000 !important;
}

.sellBtn:active {
    color: #FE0000 !important;
    border-color: #FE0000 !important;
}

.integrationBtn {
    background-color: #1abc9c !important;
    color: white !important;
    border-color: #1abc9c !important;
}

.integrationBtn:hover, .integrationBtn:active {
    background-color: white !important;
    color: #1abc9c !important;
}

.activateBtn {
    background-color: #0C4CFC !important;
    color: white !important;
    border-color: #0C4CFC !important;
}

.activateBtn:hover, .activateBtn:active {
    background-color: white !important;
    color: #0C4CFC !important;
}

.statusBox {
    margin: 1rem 0;
}

.statusBox h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.detailsBox {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 350px;
    margin: 1rem auto 0;
}

.detailsBox h4 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: .2rem;
    justify-content: center;
}

.expandBtn {
    color: #0C4CFC;
    text-decoration: underline;
    /*border-bottom: 1px solid #0C4CFC;*/
    font-size: 13px;
    cursor: pointer;
    align-self: center;
    transition: .3s all;
    display: flex;
    align-items: center;
    width: fit-content;
}

.expandBtn:hover {
    color: #0b35ab;
}

.noteBox {
    background-color: rgba(210, 224, 251, 0.4);
    border: 1px solid #4686ff;
}

.errBox {
    background-color: rgba(255, 155, 155, 0.3);
    border: 1px solid #fd2727;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.actionButtons {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 1rem;
    /*margin-left: auto;*/
}

.suggestionBtn {
    background-color: #FC6736 !important;
    border-color: #FC6736 !important;
    color: white !important;
}

.suggestionBtn:hover, .suggestionBtn:active {
    background-color: white !important;
    color: #FC6736 !important;
}

.checkFixIssue {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: .3rem;
}

.suggestionBox p {
    text-align: justify;
}

/*.suggestionBox {*/
/*    margin-top: 1.5rem;*/
/*}*/


@media (max-width: 480px) {
    .errorBox, .noteBox {
        padding: 1rem;
    }

    .actionButtons {
        gap: .5rem;
    }

    .btns button {
        font-size: 13px !important;
        padding: .3rem .8rem;
    }
}
