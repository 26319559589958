.wrapper {
    width: 100%;
    display: flex;
}

.main {
    /*width: 80%*/
    width: 100%;
}

.topExchanges {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.topExchangeTitle {
    margin-bottom: 4rem;
}

.topExchangeTitle h3 {
    font-size: 1.2rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    margin-bottom: 16px;
}

.sidebar {
    width: 15%;
    position: fixed;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.05);
    right: 0;
    height: calc(100vh - 60px);
    padding-top: 24px;
    top: 60px;
    overflow-y: scroll;
    gap: 25px;
    display: flex;
    flex-direction: column;
}

/*@media (min-width: 1200px) and (max-width: 1700px) {*/
/*    .main {*/
/*        width: 85%;*/
/*    }*/
/*}*/

@media (max-width: 1200px) {
    .tradeAssistantWrapper {
        flex-direction: column;
    }

    .main {
        width: 100%;
    }

    .sidebar {
        display: none;
    }
}

@media (max-width: 480px) {
    .topExchangeTitle {
        margin-bottom: 1rem;
    }
}