.container {
    padding: 0;
    margin: 0;
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr 1fr 1fr;*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    /*flex-direction: column;*/
    gap: 1rem;
}

.infoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*gap: .5rem;*/
    padding: .8rem .5rem;
    text-align: center;
    background-color: #c5dcfa;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .05), 0 -5px 5px rgba(0, 0, 0, .05), 5px 0 5px rgba(0, 0, 0, .05), -5px 0 5px rgba(0, 0, 0, .05);
    border-radius: .5rem;
}

.infoBox span:first-child {
    font-family: Poppins-Medium;
    font-weight: 400;
    /*line-height: 1.5rem;*/
    color: #2b303a;
    font-size: 16px;
}

.infoBox span:nth-child(2) {
    color: rgba(42, 42, 42, .56);
    font-weight: 500;
    font-size: 15px;
}

@media (max-width: 480px) {
    .infoBox span:first-child {
        font-size: 14px;
    }

    .infoBox span:nth-child(2) {
        font-size: 12px;
    }
}


/*@media (max-width: 580px) {*/
/*    .container {*/
/*        grid-template-columns: repeat(2, 1fr);*/
/*    }*/
/*}*/