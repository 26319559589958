.title {
    margin-bottom: 0;
    text-align: center;
    margin-top: .5rem;
}

.exchangeList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 1.5rem auto;
}

.exchangeList li {
    border: 2px solid lightgray;
    border-radius: .3rem;
    padding: .3rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s all;
}

.exchangeList li img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.exchangeList li:hover, .exchangeList li.selected {
    border-color: #0C4CFC;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto 1.5rem;
    flex-wrap: wrap;
}

.saveBtn {
    background-color: #0C4CFC !important;
    border-color: #0C4CFC !important;
}

.saveBtn:hover, .saveBtn:active {
    background-color: transparent !important;
    color: #0C4CFC !important;
}

.emptyLink {
    color: #2b5fec;
    text-decoration: underline;
}

.noExchange {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    gap: .2rem;
}

@media (max-width: 470px) {
    .exchangeList {
        grid-template-columns: 1fr 1fr;
    }
}