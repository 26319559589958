.errorBubble {
    width: 100%;
    background-color: #FFEEAD;
    padding: .3rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    color: #826F66;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .5s all ease-in-out;
}

.errorBubble ul {
    list-style-type: disc !important;
    padding-left: 2rem !important;
}

.errorBubble code {
    background-color: aliceblue;
    padding: 0 3px;
}

.errorMessage {
    margin-bottom: 0
}

.closeIcon {
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.closeIcon:hover {
    color: #3A3845
}

.hide {
    transform: translateY(-100px);
    opacity: 0;
    height: 0;
    overflow: hidden;
}