/*sell  #EF4B4B */

/*buy  #70AF85 */

/*hold   #82A0D8  */

.container {
    display: flex;
}

.featureName {
    display: flex;
    align-items: center;
    gap: .2rem;
    border-right: 2px solid #0C4CFC;
    padding-right: .5rem;
    margin-right: .5rem;
}

.tooltip {
    cursor: pointer;
    color: #0C4CFC;
}

.signalInfo {
    display: flex;
    align-items: baseline;
    gap: .5rem;
}

.signalInfo > li {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    align-items: center;
    gap: .5rem;
}

.label {
    /*color: #7f8c8d;*/
    color: #34495e;
    font-size: 12px;
}

.signalInfo > li > span:nth-child(2) {
    padding: .1rem .5rem;
}

.btn {
    cursor: pointer;
    transition: .3s all ease-in-out;
    border: 1px solid transparent;
    border-radius: .8rem;
}

.sell {
    background-color: #EF4B4B;
    border-color: #EF4B4B;
    color: white;
}

.sell:hover {
    background-color: white;
    color: #EF4B4B;
}

.buy {
    background-color: #70AF85;
    border-color: #70AF85;
    color: white;
}

.buy:hover {
    background-color: white;
    color: #70AF85;
}

.hold {
    background-color: #82A0D8;
    border-color: #82A0D8;
    color: white;
}

.hold:hover {
    background-color: white;
    color: #82A0D8;
}