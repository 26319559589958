.container {
    height: 400px;
    width: 100%;
    /*background-color: lightgrey;*/
    position: relative;
}

.preview {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow-y: hidden;
}