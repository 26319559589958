.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.icon {
  margin: 0 auto;
}

.title {
  font-size: 20px;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.btn {
  /* padding: 0.5rem 1.2rem; */
  border-radius: 0.3rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.3s all;
  width: 135px;
  text-align: center;
}

.yesBtn {
  background-color: #2d4df8;
  color: white;
  font-weight: bold;
}

.yesBtn:hover {
  background-color: #1032f7;
}

.noBtn {
  border: 2px solid lightgray;
}

.noBtn:hover {
  border-color: rgb(180, 179, 179);
}

.inputBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  transition: 0.3s all;
  width: 180px;
  font-size: 16px;
  text-align: center;
}

.input:focus {
  border: 1px solid rgb(155, 153, 153) !important;
}

.invalidMessage {
  color: red;
  font-size: 0.8rem;
  transition: 0.3s all;
  margin-bottom: 0;
}
.invalidInput,
.invalidInput:focus {
  border: 1px solid red !important;
}
