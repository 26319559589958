.coin {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: Poppins-Regular, serif;
    font-size: 12px;
}

.usdtLabel {
    color: #7f7f7f;
}

.coinImage {
    position: relative;
    margin-right: .5rem;
}

.symbolImage {
    width: 30px !important; /* Adjust size as needed */
    height: 30px !important;
    position: relative;
    z-index: 1;
    border-radius: 100%;
}

.tetherImage {
    width: 25px !important; /* Adjust size as needed */
    height: 25px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 0.8; /* Optional: Adjust transparency if needed */
}

@media (max-width: 600px) {
    .coin {
        font-size: 10px !important;
    }

    .coinImage {
        margin-right: .3rem;
    }

    .symbolImage {
        width: 25px !important;
        height: 25px !important;
    }

    .tetherImage {
        width: 20px !important;
        height: 20px !important;
    }
}