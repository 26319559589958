.container {
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
}

.form :global(.ant-form-item):last-child {
    align-self: flex-end;
}

.form :global(.ant-btn) {
    background-color: #0C4CFC;
    color: white;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
}

.form :global(.ant-btn):focus, .form :global(.ant-btn):hover, .form :global(.ant-btn):active {
    background-color: white !important;
    color: #0C4CFC !important;
    border-color: #0C4CFC !important;
}

.container :global(.ant-select-single), .container :global(.ant-select-selector) {
    height: 32px !important;
    /*width: 50%;*/
}

/*.tagContainer :global(.ant-select-in-form-item) {*/
/*    width: 50%;*/
/*}*/

.tagContainer :global(.ant-form-item-control-input-content) {
    display: flex;
    gap: .5rem;
    flex-direction: column;
}

.form :global(.ant-select-dropdown .ant-select-item-group) {
    font-family: Poppins-Medium;
    font-size: 14px;
}

@media (max-width: 480px) {
    .container :global(.ant-select-single) {
        width: 100%;
    }
}