:global(.ant-modal-title) {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: .5rem;
}

.modal :global(.ant-modal-content) {
    min-height: 200px;
}


.modal :global(.ant-modal-body) {
    padding: 1.5rem 0;
}

.errorMessage {
    font-size: 12px;
    color: red;
    height: 21px;
}

.form :global(.ant-btn) {
    background-color: #0C4CFC;
    color: white;
    border-color: #0C4CFC;

    width: 100%;
}

.form :global(.ant-btn):hover, .form :global(.ant-btn):focus, .form :global(.ant-btn):active {
    background-color: white;
    color: #0C4CFC;
}

.inputContainer {
    position: relative;
}

.refreshBtn {
    position: absolute;
    top: 35%;
    right: 2%;
    color: grey;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.refreshBtn:hover {
    color: #191717;
}