.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tabs {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid #0C4CFC;
    gap: .2rem;
    font-size: 15px;
}

.tab {
    padding: .5rem 1rem;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    border: 1px solid lightgray;
    border-bottom: none;
    cursor: pointer;
    transition: .3s all ease-in-out;
    border-radius: .3rem .3rem 0 0;
}

.tab:hover {
    border-color: #0C4CFC;
    color: #0C4CFC;
}

.active {
    background-color: #0C4CFC;
    color: white;
    border-color: #0C4CFC;
}

.active:hover {
    color: white !important;
}