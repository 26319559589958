.options {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
}

.options label:hover .title {
    color: #0C4CFC;
}

.radioContainer {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.title {
    font-family: Poppins-Medium;
    display: flex;
    align-items: center;
    gap: .2rem;
    transition: .3s all ease-in-out;
}

.description {
    font-size: 13px;
    margin-bottom: 0;
}
