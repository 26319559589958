@import "../../variables"
@import "../../mixins"
.wrapper
  width: 100%
  display: flex
  gap: 36px
  padding: 16px
  flex-direction: column

  >div
    width: 100%
    padding: 16px
    position: relative
    box-shadow: 0 5px 5px rgba(0,0,0,0.05),5px 0 5px rgba(0,0,0,0.05),-5px 0 5px rgba(0,0,0,0.05),0 -5px 5px rgba(0,0,0,0.05)

    >img
      width: 100%
      min-height: 250px
      object-fit: contain
    >span
      position: absolute
      top: 0
      left: 0
      background-color: rgba(0,0,0,0.4)
      width: 100%
      height: 100%
      @include fullFlex
      font-size: 18px
      flex-direction: column
      align-items: center
      justify-content: center
      transition: all .2s linear
      text-align: center
      >span
        color: white
        font-weight: 700
        opacity: 1
        transform: translateY(-30px)
      >svg
        cursor: pointer
        font-size: 60px
        color: white
        transition: all .2s linear

      &:hover
        background-color: transparent
        >span
          opacity: 0
        >svg
          transform: scale(1.05)


.videoContainer
  display: flex
  gap: 36px

  >div
    width: 100%
    padding: 16px
    position: relative
    box-shadow: 0 5px 5px rgba(0,0,0,0.05),5px 0 5px rgba(0,0,0,0.05),-5px 0 5px rgba(0,0,0,0.05),0 -5px 5px rgba(0,0,0,0.05)

    >img
      width: 100%
      min-height: 250px
      object-fit: contain
    >span
      position: absolute
      top: 0
      left: 0
      background-color: rgba(0,0,0,0.4)
      width: 100%
      height: 100%
      @include fullFlex
      font-size: 18px
      flex-direction: column
      align-items: center
      justify-content: center
      transition: all .2s linear
      text-align: center
      >span
        color: white
        font-weight: 700
        opacity: 1
        transform: translateY(-30px)
      >svg
        cursor: pointer
        font-size: 60px
        color: white
        transition: all .2s linear

      &:hover
        background-color: transparent
        >span
          opacity: 0
        >svg
          transform: scale(1.05)

