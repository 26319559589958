.container {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray !important;
  min-height: 450px;
  padding: 1rem;
  background-color: #f7f8f8;
  gap: 25px;
}

.messageContainer {
  align-self: flex-end;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.message {
  border: 1px solid #f0f0f0;
  padding: 0.5rem 1rem;
  max-width: 90%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.message p {
  margin-bottom: 0 !important;
}
.systemMessage {
  align-self: flex-start;
  transform: translateX(-120%);
  transition: 0.3s all;
  background-color: #e1f1ff;
  opacity: 0;
}

.responseMessage {
  background-color: #e1f1ff;
}

.userMessage {
  opacity: 0;
  background-color: white;
  opacity: 0;
  align-self: flex-end;
}

.sent {
  opacity: 1;
}

.show {
  transform: translateX(0);
  opacity: 1;
}

.msgTime {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 5px;
  font-style: italic;
  color: #666666;
}

.inputBox {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transform: translateY(140%);
  transition: 0.3s all;
}

.openInput {
  transform: translateY(0);
}

.sendBtn {
  align-self: flex-end;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0940d6;
  padding: 0.3rem 1rem;
  border-radius: 0.3rem;
  font-family: Poppins-Medium;
  cursor: pointer;
  transition: 0.3s all;
}
.sendBtn:hover,
.closeBtn:hover {
  background-color: #0537c2;
}

.closeBtn {
  margin-top: auto;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0940d6;
  padding: 0.3rem 1rem;
  border-radius: 0.3rem;
  font-family: Poppins-Medium;
  cursor: pointer;
  transition: 0.3s all;
}
