.container {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.5rem auto;
}

.riskBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    width: 180px;
    border: 2px solid lightgray;
    cursor: pointer;
    transition: .3s all;
    padding: 1rem .5rem;
    border-radius: .4rem;
    /*background-color: rgba(187, 221, 244, 0.68);*/
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.riskBtn:hover {
    border-color: #0C4CFC;
}

.riskBtn:first-child {
    color: #ff0000;
}

.riskBtn:nth-child(2) {
    color: #ffa500;
}

.riskBtn:nth-child(3) {
    color: #02a82b;
}

.riskBtn:nth-child(4) {
    color: #00bfff;
}

.riskBtn:nth-child(5) {
    color: #022da1;
}

.active {
    border-color: #0C4CFC !important;
}

@media (max-width: 580px) {
    .riskBtn {
        padding: .5rem;
        width: 150px;
    }
}