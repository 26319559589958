.container {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: white;
    padding: 0.8rem 1rem;
    box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
}

.navList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
}

.navItem {
    flex: 1;
}

.navLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: relative;
}

.navMore {
    cursor: pointer;
    position: relative;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown {
    position: absolute;
    /*display: none;*/
    display: flex;
    /*height: 0;*/
    /*overflow: hidden;*/
    flex-direction: column;
    z-index: 1000;
    background-color: white;
    right: 0;
    /*bottom: 220%;*/
    width: 210px;
    /*bottom: 140%;*/
    bottom: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: translateX(150%);

    transition: 0.3s all;
}

.beta::after {
    position: absolute;
    top: 7%;
    right: 20%;
    content: "Beta";
    color: white;
    font-size: 10px;
    padding: 0 .5rem;
    border-radius: 1rem;
    background-color: #4b82e6;
}

.navMore:hover .dropdown {
    /*display: flex;*/
    /*height: 277px;*/
    transform: translateX(0);
}

.dropdownItem {
    padding: 0.7rem 0.5rem;
    position: relative;
}

.navMore:hover svg,
.navLink:hover {
    color: #0c4cfc !important;
}

.navLink {
    color: #2B303A;
}

.navItem:not(:last-child) {
    margin-right: 1rem;
    /* flex: 1; */
}

/* .navLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
} */

.navIcon {
    width: 25px;
    height: 20px;
    transition: 0.4s all;
}

.navItem:hover .navIcon {
    animation: shake 0.2s;
    animation-iteration-count: 1;
}

.navTitle {
    margin-left: 0.3rem;
    text-align: center;
    font-size: 14px;
}

/*.activeLink {*/
/*    background-color: #0C4CFC;*/
/*    color: white;*/
/*}*/

.activeLink {
    color: #0c4cfc !important;
    position: relative;
    font-weight: bold;
}

.activeLink svg {
    stroke: #0C4CFC !important;
}

.activeLink::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #0c4cfc;
    position: absolute;
    /*bottom: -12px;*/
    bottom: -7px;
    left: 0;
}

.navItem svg {
    font-size: 20px !important;
}

@keyframes shake {
    0% {
        transform: rotate(0);
    }

    30% {
        transform: rotate(-20deg);
    }

    60% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(20deg);
    }
}

@media (min-width: 1200px) {
    .container {
        display: none;
    }
}

/*@media (min-width: 1100px) {*/
/*    .navMore {*/
/*        display: none;*/
/*    }*/
/*}*/

@media (max-width: 1100px) {
    .navList .navItem:nth-child(7),
    .navList .navItem:nth-child(8) {
        display: none;
    }

    /*.dropdown li:not(:last-child):not(:nth-child(6)) {*/
    /*    display: none;*/
    /*}*/
}

@media (max-width: 850px) {
    .navList .navItem:nth-child(6) {
        display: none;
    }

    /*.dropdown li:nth-child(5) {*/
    /*    display: inline-block !important;*/
    /*}*/
}

@media (max-width: 730px) {
    /*.dropdown li:nth-child(4) {*/
    /*    display: inline-block !important;*/
    /*}*/
    /*.navList .navItem:nth-child(5) {*/
    /*    display: none;*/
    /*}*/
}

/*@media (min-width: 601px) {*/
/*    .dropdownItem:first-child {*/
/*        display: none;*/
/*    }*/

/*}*/

/*@media (max-width: 600px) {*/
/*    !*.dropdown li:nth-child(3) {*!*/
/*    !*    display: inline-block !important;*!*/
/*    !*}*!*/
/*    .navList .navItem:nth-child(4) {*/
/*        display: none;*/
/*    }*/
/*}*/

@media (max-width: 480px) {
    /*.dropdown li:nth-child(2) {*/
    /*    display: inline-block !important;*/
    /*}*/
    /*.navList .navItem:nth-child(3) {*/
    /*    display: none;*/
    /*}*/
}

@media (max-width: 480px) {
    .navItem a, .navItem {
        font-size: 12px;
    }

    .navList {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: .5rem;
    }

    .navItem {
        margin: 0 !important;
    }
}
